export default {
    computed: {
        countriesObject() {
            return this.$t('public.home.countries.ca_list')
        },
        countriesArr() {

            return Object.values(this.countriesObject)
        },

    },
    methods: {
        patientCountry(patient) {
            return this.countriesArr.find(country => country.id == patient.country_id)
        },
        getCountryLang(country) {
            var countryList = this.countriesObject
            // convert to an array
            countryList = Object.keys(countryList).map(key => {
                return {
                    id: countryList[key].id,
                    name: countryList[key].name
                }
            })

            return countryList.find(c => c.id === country.id)
        }
    }
}