import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  computed: {
    ...mapState('resources/questionnaires', ['routeChanging', 'questionnaireLoaded', 'questionnaireSaving']),
    ...mapGetters('resources/questionnaires', ['getQuestionnaireByName', 'getQuestionnaireDataitems', 'getQuestionnaireDefaultTemplate', 'getQuestionnaireRecords', 'namedQuestionnaireIsLoaded']),
    questionnaire() {
      if (this.questionnaireIsLoaded)
        return this.getQuestionnaireByName(this.name)
    },
    dataitems() {
      if (this.questionnaireIsLoaded)
        return this.getQuestionnaireDataitems(this.name)
    },
    defaultTemplate() {
      if (this.questionnaireIsLoaded)
        return this.getQuestionnaireDefaultTemplate(this.name)
    },
    records() {
      if (this.questionnaireIsLoaded)
        return this.getQuestionnaireRecords(this.name)
    },
    questionnaireIsLoaded() {
      return this.namedQuestionnaireIsLoaded(this.name)
    }
  }
}
