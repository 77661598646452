<template>
  <div class="public-page">
    <div class="text-center">
      <img :src="'/images/VASUP-Logo.svg'" alt="" style="max-width:600px" class="vasup-logo my-2">

      <h1 class="homepage-title display-5 mb-4" v-t="'public.home.intro1'"></h1>
      <i18n class="homepage-title fs-4 mb-4" tag="div" path="public.home.intro2">
        <strong class="fw-bolder">{{ $t('public.home.term1') }}</strong>
        <strong class="fw-bolder">{{ $t('public.home.term2') }}</strong>
      </i18n>
      <!-- <p v-t="'public.home.p2'"></p>
      <p v-t="'public.home.p3'"></p> -->
    </div>
    <row class="g-1 mb-4" v-if="!inAvailableCountry">
      <column :sizes="{ md: 6 }">
        <alert type="primary" class="mt-xl-5">
          <row class="g-3 align-items-center">
            <column :sizes="[3]">
              <img :src="'https://flagcdn.com/' + location.countryCode.toLowerCase() + '.svg'"
                class="flag-img card-img rounded-left img-fluid" alt="...">
            </column>
            <column :sizes="[9]">
              <p>{{ $t('public.home.countries.not_available_in_location', { country: location.countryName }) }}</p>
            </column>
          </row>

        </alert>
      </column>
      <column :sizes="{ md: 6 }" class="homepage-title">
        <p v-t="'public.home.countries.currently_available'" class="mx-4"></p>

        <row class="g-1 mb-4">
          <column v-for="country in availableInCountries" :sizes="[12, { md: 12, lg: 12 }]" :key="country.countryCode">
            <card :body="false" class="country-card">
              <row class="g-0 align-items-center">
                <column :sizes="[3, { xs: 3, sm: 3, md: 3 }]">
                  <img :src="'https://flagcdn.com/' + country.countryCode.toLowerCase() + '.svg'"
                    class="flag-img card-img rounded-left img-fluid" :alt="country.countryCode.toLowerCase()">
                </column>
                <column :sizes="[9, { xs: 9, sm: 9, md: 9 }]">
                  <div class="card-body d-flex  align-items-center py-2">
                    {{ country.name }}
                  </div>
                </column>
              </row>
            </card>

          </column>
        </row>
      </column>
    </row>
    <!-- <row class="g-1 mb-4" v-else>
      <column :sizes="{ md: 6 }">
        <card :body="false" class="country-card my-4">
          <row class="g-0 align-items-center">
            <column :sizes="[3, { xs: 4, sm: 4, md: 3 }]">
              <img :src="'https://flagcdn.com/' + location.countryCode.toLowerCase() + '.svg'"
                class="flag-img card-img rounded-left img-fluid" :alt="location.countryCode.toLowerCase()">
            </column>
            <column :sizes="[9, { xs: 8, sm: 8, md: 9 }]">
              <div class="card-body d-flex  align-items-center py-2">
                {{ $t('public.home.countries.is_available_in_location', { country: location.countryName }) }}
              </div>
            </column>
          </row>
        </card>
      </column>
    </row> -->
    <div :class="['mb-4', 'container-xxl']">
      <row class="g-2">
        <column :sizes="{ lg: 12 }" class="card-group">
          <card :body="false" class="vasup-home-card">
            <div class="card-body d-flex flex-column h-100 p-2">
              <row class="g-0 text-center text-lg-start">
                <column :sizes="[4, { sm: 2, lg: 2 }]" :offset="{ sm: 5, lg: 0 }" class="offset-4 text-center">
                  <img :src="'/images/homepage/whatis.svg'" :alt="$t('public.home.what_is_vasup.image_alt')"
                    class="img-fluid homepage-icon" style="max-height: 144px;">
                </column>
                <column :sizes="[12, { sm: 12, lg: 10 }]">
                  <h2 v-t="'public.home.what_is_vasup.title'" class="card-title display-6 lead"></h2>
                  <p class="card-text lead text-justify mb-4" v-t="'public.home.what_is_vasup.para_1'"></p>
                  <p class="card-text lead text-justify mb-4" v-t="'public.home.what_is_vasup.para_2'"></p>
                </column>
              </row>

            </div>
          </card>
        </column>
        <column :sizes="{ md: 6, lg: 6 }" class="card-group">
          <card :body="false" class="vasup-home-card">
            <div class="card-body d-flex flex-column h-100 p-2">
              <row class="g-0 text-center text-lg-start">
                <column :sizes="[4, { sm: 2, md: 4, lg: 3 }]" :offset="{ sm: 5, md: 4, lg: 0 }"
                  class="offset-4 text-center p-2">
                  <img :src="'/images/homepage/clinicians.svg'" class="img-fluid homepage-icon"
                    :alt="$t('public.home.clinicians.image_alt')">
                </column>
                <column :sizes="[12, { sm: 12, lg: 9 }]" class="d-flex align-items-center">
                  <h2 v-t="'public.home.clinicians.title'" class="card-title display-6 lead m-auto"></h2>
                </column>
              </row>
              <div class="card-body">
                <i18n path="public.home.clinicians.para_1" class="card-text lead text-justify mb-4">
                  <template #pis><a class="text-decoration-none fw-bold" target="_blank" :href="pisPath">{{
                    $t('public.home.clinicians.pis') }}</a>
                  </template>
                  <template #mail><a class="text-decoration-none fw-bold" href="mailto:vasup@ndorms.ox.ac.uk">{{
                    $t('public.home.clinicians.mail') }}</a>
                  </template>
                </i18n>
              </div>

              <btn is-router-link :to="{ name: 'clinician.register' }" outline block
                v-t="'public.home.clinicians.register'" class=" mt-auto fs-4 " name="find_out_more">
              </btn>
            </div>
          </card>
        </column>
        <column :sizes="{ md: 6, lg: 6 }" class="card-group">
          <card :body="false" class="vasup-home-card">

            <div class="card-body d-flex flex-column h-100 p-2" inert>
              <row class="g-0 text-center text-lg-start">
                <column :sizes="[4, { sm: 2, md: 4, lg: 3 }]" :offset="{ sm: 5, md: 4, lg: 0 }"
                  class="offset-4 text-center p-2">
                  <img :src="'/images/homepage/patients.svg'" class="img-fluid homepage-icon"
                    :alt="$t('public.home.patients.image_alt')">
                </column>
                <column :sizes="[12, { sm: 12, lg: 9 }]" class="d-flex align-items-center">
                  <h2 v-t="'public.home.patients.title'" class="card-title display-6 lead  m-auto"></h2>

                </column>
              </row>
              <div class="card-body">
                <i18n path="public.home.patients.para_1" tag="div" class="card-text lead text-justify mb-4">
                  <template #pis><a class="text-decoration-none fw-bold" target="_blank"
                      href="/assets/documents/UK/VAS-UP1b Information sheet - Patients V1.pdf">{{
                        $t('public.home.clinicians.pis') }}</a>
                  </template>
                  <template #mail><a class="text-decoration-none fw-bold" href="mailto:vasup@ndorms.ox.ac.uk">{{
                    $t('public.home.clinicians.mail') }}</a>
                  </template>
                </i18n>
                <!-- <i18n class="card-text" path="public.home.patients.small">
                  <template #fom><a class="text-decoration-none fw-bold" target="_blank"
                      :href="$t('public.home.patients.url')">
                      {{ $t('public.home.patients.fom') }}
                    </a>
                  </template>
                </i18n> -->
              </div>
              <btn disabled is-router-link :to="{ name: 'patient.register' }" outline block
                v-t="'public.home.patients.recruitment_ended'" class="mt-auto fs-4"></btn>
            </div>
          </card>
        </column>
        <column :sizes="{ md: 6, lg: 6 }" class="card-group">
          <card class="vasup-home-card">
            <div class="">
              <!-- <img :src="'/images/homepage/search.png'" :alt="$t('public.home.what_will_it_involve.image_alt')"
                style="max-width: 256px;" class="circle-image mb-4 mx-auto"> -->
              <h2 v-t="'public.home.what_will_it_involve.title'" class="card-title display-6 lead"></h2>
              <p class="card-text text-justify lead flex-grow-1 mb-4" v-t="'public.home.what_will_it_involve.para_1'">
              </p>
              <p class="card-text text-justify lead flex-grow-1 mb-4" v-t="'public.home.what_will_it_involve.para_2'">
              </p>
              <!-- <btn name="find_out_more" is-router-link :to="{ name: 'public.more-info', hash: '#what_will_i_have_to_do' }"
                block v-t="'public.home.find_out_more'" class=" mt-auto"></btn> -->
            </div>
          </card>
        </column>
        <column :sizes="{ md: 6, lg: 6 }" class="card-group">
          <card class="vasup-home-card">
            <div class="d-flex flex-column h-100">
              <!-- <img :src="'/images/homepage/doctor.png'" style="max-width: 256px;"
                :alt="$t('public.home.who_is_running_this_research.image_alt')" class="circle-image mb-4 mx-auto"> -->
              <h2 v-t="'public.home.who_is_running_this_research.title'" class="card-title display-6 lead"></h2>
              <i18n path="public.home.who_is_running_this_research.para_1" class="card-text text-justify lead mb-4">
                <template #prof1><strong class="fw-bold">
                    {{ $t('public.home.who_is_running_this_research.prof1') }}
                  </strong>
                </template>
                <template #prof2><strong class="fw-bold">
                    {{ $t('public.home.who_is_running_this_research.prof2') }}
                  </strong>
                </template>
              </i18n>
              <row class="my-auto">
                <column :sizes="{ sm: 6 }" class="d-flex mb-2">
                  <img :src="'/images/ndorms.png'" alt="NDORMS" class="m-auto img-fluid" style="max-height: 120px;">
                </column>
                <column :sizes="{ sm: 6 }" class="d-flex">
                  <img :src="'/images/rhul.jpg'" alt="" class="m-auto img-fluid" style="max-height: 120px;">
                </column>
              </row>


              <!-- <btn name="find_out_more" is-router-link :to="{ name: 'public.more-info', hash: '#who_are_we' }" block
                v-t="'public.home.find_out_more'" class=" mt-auto"></btn> -->
            </div>
          </card>
        </column>
        <column :sizes="{ md: 6, lg: 4 }" class="card-group">
          <card class="vasup-home-card">
            <div class="d-flex flex-column h-100">
              <!-- <img :src="'/images/homepage/money2.png'" style="max-width: 256px;"
                alt="$t('public.home.who_is_funding_this_research.image_alt')" class="circle-image mb-4 mx-auto"> -->
              <h2 v-t="'public.home.who_is_funding_this_research.title'" class="card-title display-6 lead"></h2>
              <div class="card-text text-justify lead flex-grow-1 mb-4"
                v-html="$t('public.home.who_is_funding_this_research.para_1')" />
              <img :src="'/images/vifor.png'" alt="VIFOR" class="m-auto img-fluid" style="max-height: 100px;">

              <!-- <btn name="find_out_more" is-router-link :to="{ name: 'public.more-info', hash: '#who_funds_us' }" block
                v-t="'public.home.find_out_more'" class=" mt-auto"></btn> -->
            </div>

          </card>
        </column>
        <column :sizes="{ md: 6, lg: 8 }" class="card-group">
          <card class="vasup-home-card">
            <div class="d-flex flex-column h-100">
              <!-- <img :src="'/images/homepage/click.png'" style="max-width: 256px;"
                :alt="$t('public.home.future_plans.image_alt')" class="circle-image mb-4 mx-auto"> -->
              <h2 v-t="'public.home.future_plans.title'" class="card-title display-6 lead"></h2>
              <div class="card-text text-justify lead flex-grow-1 mb-4"
                v-html="$t('public.home.future_plans.para_1')" />
              <div class="card-text text-justify lead flex-grow-1 mb-4"
                v-html="$t('public.home.future_plans.para_2')" />
              <div class="card-text text-justify lead flex-grow-1 mb-4"
                v-html="$t('public.home.future_plans.para_3')" />
              <!-- <btn name="find_out_more" is-router-link :to="{ name: 'public.more-info', hash: '#more_info' }" block
                v-t="'public.home.find_out_more'" class=" mt-auto"></btn> -->
            </div>
          </card>
        </column>
      </row>
    </div>
    <!-- 
    <card class="">
      <h2 v-t="'public.home.partners.title'"></h2>
      <template v-for="p in $t('public.home.partners.list')"><a :href="p.url" class="m-2"><img :src="p.img" :alt="p.title"
            class="m-2" style="max-height:100px;"></a></template>
  </card>
  <card class=" mt-2">
    <h2 v-t="'public.home.special_thanks.title'"></h2>
    <template v-for="p in $t('public.home.special_thanks.list')"><a :href="p.url" target="_blank" class="m-2"><img
            :src="p.img" :alt="p.title" class="m-2" :width="p.width"></a></template>
  </card> -->
    <!-- <footer class="footer " style="padding:1em;">
      <router-link :to="{ name: 'public.accessibility' }" class="text-decoration-none">
        <span v-t="'public.home.accessibility'"></span>
      </router-link>
      <span class="mx-2">|</span>
      <router-link :to="{ name: 'public.cookie-policy' }" class="text-decoration-none">
        <span v-t="'public.home.cookie_policy'"></span>
      </router-link>
    </footer> -->
  </div>
</template>
<script>
import axios from 'axios'

import { mapGetters, mapActions, mapState } from 'vuex'
export default {
  name: 'welcome-page',
  metaInfo() {
    return { title: this.$t('public.home.title') }
  },

  data() {
    return {
      title: window.config.appName,
      countries: [],
      loadedCountries: false,
      metaData: {
        title: this.$t('public.home.title'),
        description: this.$t('public.home.what_is_vasup.para_1'),
        url: window.location.href,
        image: window.location.href + '/images/VASUP-Logo.svg',
      },
    }
  },
  mounted() {
    axios.get('countries')
      .then(((resp, request) => {
        this.countries = resp.data;
        this.loadedCountries = true;
      }).bind(this))
  },
  computed: {
    ...mapState('resources/patient', ['public']),

    ...mapGetters({
      authenticated: 'auth/check'
    }),
    base_url() {
      return window.config.base_url
    },
    location() {
      return window.config.country
    },
    availableInCountries() {
      return this.$t('public.home.countries.ca_list')
    },
    inAvailableCountry() {
      return Object.values(this.availableInCountries).findIndex((c) => c.countryCode == this.location.countryCode) > -1
    },
    visitorsCountryData() {
      var country = this.countries.find(c => c.code == this.location.countryCode)
      if (!country) {
        return false
      }
      return country
    },
    pisPath() {
      if (this.visitorsCountryData && this.visitorsCountryData.json) {
        return this.visitorsCountryData.json.clinicians_pis_path
      }
      return '#'
    },
  },
  methods: {

  }
}

</script>
