import Vue from 'vue'
import store from '~/store'
import router from '~/router'
import i18n from '~/plugins/i18n'
import App from '~/components/App.vue'
import '../sass/app.scss'
import '~/plugins'
import '~/components'
// import './websockets.js'

// Vue.config.productionTip = false

/* eslint-disable no-new */
const mainApp = new Vue({
  i18n,
  store,
  router,


  ...App,
})

// console.log(mainApp)
if (import.meta.hot) {
  // import.meta.hot.send('my:from-client', { msg: 'Hey!' })
  // import.meta.hot.on('my:greetings', (data) => {
  //   console.log(data.msg) // hello
  // })
}

// import.meta.hot.accept((newModule) => {
//   console.log('accept', newModule)
//     if (newModule) {
//       // newModule is undefined when SyntaxError happened
//       console.log('newModule', newModule)
//     }
//   })