<template>
  <div>
    <card head v-if="questionnaire" :title="$t('admin.questionnaire.title', { title: this.questionnaire.title })"
      class="mb-4">
      <template #header-left>
        <btn size="sm" class="me-2" is-router-link :to="{ name: 'admin.questionnaires' }">
          <fa icon="chevron-left" />
        </btn>
      </template>
      <h3><small>Title: </small><span v-text="questionnaire.title" /></h3>
      <h3><small>Name: </small>
        <badge v-text="questionnaire.name" />
      </h3>
      <card head :title="$t('admin.questionnaire.dataitems')" :body="false">
        <data-table v-if="questionnaire.dataitems" :data="questionnaire.dataitems" :columns="dataitemColumns" paginate
          :paginate-rows="15" :class="['dataitems-datatable', 'cols-' + dataitemColumns.length]">
          <template #cell-label="{ rowData }">
            <template v-if="rowData.label">
              <div class="p-2" v-html="rowData.label" />
            </template>
          </template>
          <template #cell-lookup="{ rowData }">
            <template v-if="rowData.lookup_id">
              <div class="p-2">
                <label class="cell-label">Lookup:</label>
                <badge v-for="item in getLookup(rowData.lookup_id).items"
                  :key="'lu' + rowData.lookup_id + '_' + item.value" class="me-1" v-text="item.label"></badge>
              </div>
            </template>
          </template>
          <template #cell-json="{ rowData }">
            <template v-if="rowData.json">
              <label class="cell-label">JSON:</label>
              <div class="p-2">{{ rowData.json }}</div>
            </template>
          </template>
        </data-table>
      </card>
    </card>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import paginationMixin from '~/mixins/pagination'
export default {
  name: "questionnaire",
  metaInfo() {
    if (this.questionnaire)
      return { title: this.$t('admin.questionnaire.title', { title: this.questionnaire.title }) }
  },
  middleware: ['auth', 'team'],
  mixins: [paginationMixin],
  apiMethod: 'getQuestionnaire',
  data() {
    return {
      name: this.$route.params.name,
      params: {
        append: 'lookups,validation',
        include: 'dataitems',
      }
    }
  },
  mounted() {
    var name = this.name
    var params = this.params
    this.getQuestionnaire({ name, params });
  },
  methods: {
    ...mapActions("resources/questionnaires", ["getQuestionnaire"]),
    getLookup(lookup_id) {
      return this.questionnaire.lookups.find((lu) => lu.id == lookup_id)
    }
  },
  computed: {
    ...mapState("resources/questionnaires", ["questionnaire"]),
    dataitemColumns() {
      return [
        this.dataitemsHaveSections ? { name: 'section', label: 'Section', Type: String, sortable: true, width: '1fr' } : false,
        { name: 'name', label: 'Name', Type: String, sortable: true, width: '1fr' },
        { name: 'label', label: 'Label', Type: String, sortable: true, width: '1fr' },
        { name: 'lookup', label: 'Lookup', Type: String, sortable: true, width: '1fr' },
        { name: 'type', label: 'Type', Type: String, sortable: true, width: '1fr' },
        { name: 'json', label: 'JSON', Type: JSON, sortable: true, width: '1fr' },
      ].filter(Boolean)
    },
    dataitemsHaveSections() {
      if (this.questionnaire && this.questionnaire.dataitems)
        return this.questionnaire.dataitems.filter((r) => r.section != null).length > 0
      return false
    }
  }
}

</script>
<style lang="scss">
.dataitems-datatable .datatable {

  // grid-template-areas:
  //       "index name label type"
  //       "index lookup lookup json";
}

//   .datatable {
//     overflow-x: auto;

//     .cell-label {
//       position: relative;
//       display: inline;
//       top: -5px;
//       padding: 2px;
//       border-radius: 2px;
//       margin-bottom: 2px;
//       font-size: 12px;
//       color: var(--bs-gray-100);
//       background: var(--bs-secondary);
//     }

//     div.dt-row.header {
//       display: none;
//     }


//     div.dt-row {
//       border: thin solid var(--bs-gray-100);
//       grid-template-areas:
//         "index name label type"
//         "index lookup lookup json";
//       // grid-template-columns:
//       // [index] 80px [name] minmax(240px, 2fr) [label] minmax(600px, 4fr) [lookup] minmax(400px, 1fr) [type] minmax(280px, 1fr) [json] minmax(280px, 1fr);

//       grid-template-columns:
//         [index] 80px [name] minmax(140px, 1fr) [label] minmax(240px, 3fr) [type] minmax(140px, 1fr);

//       .dt-row-number {
//         grid-area: index;
//       }

//       // .dt-column-name{ grid-area: name; }
//       // .dt-column-label{ grid-area: label; }
//       // .dt-column-type{ grid-area: type; }
//       .dt-column-lookup {
//         grid-area: lookup;
//         grid-column: minmax(140px, 1fr);
//       }

//       .dt-column-json {
//         grid-area: json;
//         grid-column: minmax(140px, 1fr);
//       }

//       .dt-column {
//         display: inline;

//         position: relative;

//         //   grid-template-columns: minmax(150px, 1.33fr) minmax(150px, 2.33fr);
//         //   overflow: hidden;
//       }

//     }
//   }
//   &.cols-6 {
//     div.dt-row {
//       grid-template-areas:
//         "index section name label type"
//         "index lookup lookup json json";
//       grid-template-columns:
//         [index] 80px [section] minmax(140px, 1fr) [name] minmax(140px, 1fr) [label] minmax(240px, 3fr) [type] minmax(140px, 1fr);
//     }
//   }
// }
</style>
