import Vue from 'vue'
import { resolveImport } from '../helpers/module-importer.js'
var components = [
  'alert',
  'badge',
  'btn',
  'btn-group',
  'card',
  'checkbox',
  'collapse',
  'column',
  'data-table',
  'datepicker',
  'dropdown',
  'feedback',
  'form-group',
  'input-group',
  'list-group',
  'list-group-item',
  // 'media',
  'modal',
  'nav-bar',
  'nav-item',
  'number',
  'offcanvas',
  'popper',
  'popover',
  // 'modal-guide',
  // 'progress-bar',
  'radio',
  'row',
  'search',
  // 'slider',
  'tab-content',
  'tab-pane',
  'textbox',
  // 'tooltip',
  'typeahead'
]

// Load components dynamically.
const componentsImport = import.meta.glob('@showjoe/ffui/src/components/*.vue')
const componentsResolved = resolveImport(componentsImport, '/node_modules/@showjoe/ffui/src/components/', /(^.\/)|(\.vue$)/g)
const ffcomponent = function (component_name) {
  Vue.component(component_name, componentsResolved[component_name]);
}
components.forEach((component_name) => ffcomponent(component_name))

import mask from '@showjoe/ffui/src/directives/mask.js'
import clear from '@showjoe/ffui/src/directives/clear.js'
import popover from '@showjoe/ffui/src/directives/popover.js'
import tooltip from '@showjoe/ffui/src/directives/tooltip.js'
Vue.directive('mask', mask);
Vue.directive('clear', clear);
Vue.directive('popover', popover);
Vue.directive('tooltip', tooltip);

