<template>
  <questionnaire v-if="questionnaireLoaded" :title="$t('questionnaires.vasculitis_related.title')" type="multi-page"
    :page-info="pageInfo">
    <template #page1>
      <h5 v-t="lp + '.PPM'" class="mb-4"></h5>
      <row class="mb-5">
        <template v-for="di in groupedDataitems('PPM')">
          <column :sizes="{ sm: 6, md: 3 }">
            <form-group :di="di" :error="errors[di.name]" class="my-3" v-slot="{ di }"
              :cols="[{ sm: 6, md: 12 }, { sm: 6, md: 12 }]">
              <number v-model="records[di.name]"></number>
            </form-group>
          </column>
        </template>
      </row>
      <template v-for="diName in ['PresPM_Glucocorticoid', 'PresPM_MedHighGluco']">
        <form-group :di="dataitems[diName]" :error="errors[diName]" class="mb-4" v-slot="{ di }" cols="9|3">
          <number v-model="records[diName]"></number>
        </form-group>
      </template>
      <form-group :di="dataitems.SteroidToxicityToolUsed" :error="errors.SteroidToxicityToolUsed" cols="9|3" class="mb-5"
        v-slot="{ di }">
        <btn-group outline justified :di="di" v-model="records.SteroidToxicityToolUsed"></btn-group>
      </form-group>
      <form-group v-if="records.SteroidToxicityToolUsed == 1" :di="dataitems.SteroidToxicityToolName"
        :error="errors.SteroidToxicityToolName" cols="3|9" class="my-3" v-slot="{ di }">
        <textbox v-model="records.SteroidToxicityToolName"></textbox>
      </form-group>
    </template>
    <template #page2>
      <h5 v-html="$t(lp + '.HighGluco')" class="my-5"></h5>
      <h6 v-t="lp + '.SteroidToxicityTools'" class="mb-4"></h6>

      <form-group class="mb-5  ms-5">
        <list-group outline vertical justified>
          <template v-for="di in groupedDataitems('STTools')">
            <list-group-item is-link v-model="records[di.name]" v-html="di.label"></list-group-item>
            <form-group v-if="di.name == 'STT_Other' && records[di.name]" :di="dataitems[di.name + 'Specify']"
              :error="errors[di.name + 'Specify']" cols="3|9" class="my-3" v-slot="{ di }" @click.stop.prevent>
              <textbox v-model="records[di.name + 'Specify']"></textbox>
            </form-group>
          </template>
        </list-group>
      </form-group>
      <h5 v-html="$t(lp + '.SteroidToxicityMethods')"></h5>
      <ul class="mb-5 ms-5">
        <li v-t="lp + '.STMethods_1'"></li>
        <li v-t="lp + '.STMethods_2'"></li>
        <li v-t="lp + '.STMethods_3'"></li>
      </ul>
      <h5 v-html="$t(lp + '.STLabTests')" class="mt-5 mb-3"></h5>
      <template v-for="di in groupedDataitems('STLT')">
        <form-group :di="di" :error="errors[di.name]" class="mb-4  ms-5" v-slot="{ di }">
          <btn-group outline justified :di="di" v-model="records[di.name]"></btn-group>
        </form-group>
      </template>
      <h5 v-html="$t(lp + '.STVitalSigns')" class="mt-5 mb-3"></h5>
      <template v-for="di in groupedDataitems('STVS')">
        <form-group :di="di" :error="errors[di.name]" class="mb-4  ms-5" v-slot="{ di }">
          <btn-group outline justified :di="di" v-model="records[di.name]"></btn-group>
        </form-group>
      </template>
      <h5 v-html="$t(lp + '.STPhysicalFindings')" class="mt-5 mb-4"></h5>
      <template v-for="di in groupedDataitems('STPF')">
        <form-group :di="di" :error="errors[di.name]" class="mb-4 ms-5" v-slot="{ di }">
          <btn-group outline justified :di="di" v-model="records[di.name]"></btn-group>
        </form-group>
      </template>
      <h5 v-html="$t(lp + '.STBurdenOfToxicity')" class="mt-5 mb-4"></h5>
      <template v-for="di in groupedDataitems('STBT')">
        <form-group :di="di" :error="errors[di.name]" class="mb-4 ms-5" v-slot="{ di }">
          <btn-group outline justified :di="di" v-model="records[di.name]"></btn-group>
        </form-group>
      </template>
    </template>
    <template #page3>

      <h5 v-html="$t(lp + '.ImportanceScale')" class="mt-5 mb-4"></h5>
      <template v-for="di in groupedDataitems('STMP')">
        <form-group :di="di" :error="errors[di.name]" v-slot="{ di }" label-class="fs-6" class="ms-5">
          <slider v-model="records[di.name]" :labels="{ min: $t(lp + '.Slider1_min'), max: $t(lp + '.Slider1_max') }">
          </slider>
        </form-group>
        <hr>
      </template>

    </template>
    <template #page4>
      <form-group :di="dataitems.STA_Importance" :error="errors.STA_Importance" class="mb-5" v-slot="{ di }">
        <row class="draggable-list-container">
          <column>
            <draggable group="people" @start="drag = true" @end="drag = false" v-model="unsorted" tag="ol"
              class="draggable-list list-group h-100">
              <list-group-item v-for="(val, index) in unsorted" :key="val" class="d-flex">{{ getItem(val,
                dataitems.STA_Importance.lookup.items).label
              }}
              </list-group-item>
            </draggable>
          </column>
          <column>
            <div>
              <div class="fs-5">{{ $t(lp + '.Slider1_max') }}</div>
              <draggable v-model="records.STA_Importance" group="people" tag="ol" v-bind="dragOptions"
                class="draggable-list list-group h-100">
                <list-group-item v-for="(val, index) in records.STA_Importance" :key="val" class="d-flex">
                  <badge class="me-3">{{ index + 1 }}</badge>{{ getItem(val,
                    dataitems.STA_Importance.lookup.items).label
                  }}
                </list-group-item>
              </draggable>
              <div class="fs-5">{{ $t(lp + '.Slider1_min') }}</div>
            </div>

          </column>
        </row>

      </form-group>
    </template>
    <template #page5>
      <h5 v-html="$t(lp + '.WillingnessScale')" class="mt-5 mb-4"></h5>
      <template v-for="di in groupedDataitems('STA')">
        <form-group :di="di" :error="errors[di.name]" v-slot="{ di }" label-class="fs-6" class="ms-5">
          <slider v-model="records[di.name]" :labels="{ min: $t(lp + '.Slider2_min'), max: $t(lp + '.Slider2_max') }">
          </slider>
        </form-group>
        <hr>
      </template>
    </template>

  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
export default {
  name: 'vasculitis_related',
  mixins: [questionnaireMixin],
  components: {
    draggable
  },
  computed: {
    ...mapGetters('auth', ['isParticipant']),
    dragOptions() {
      return {
        animation: 0,
        group: "people",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    pageInfo() {
      var tabs =
        [{ title: "Page 1", key: "page1" }, { title: "Page 2", key: "page2" }, { title: "Page 3", key: "page3" }, { title: "Page 4", key: "page4" }, { title: "Page 5", key: "page5" }];
      return tabs;
    },
  },
  mounted() {
    if (this.dataitems) {
      var clone = [...this.dataitems.STA_Importance.lookup.items]
      var existing = this.records.STA_Importance
      this.unsorted = clone.filter((i) => {
        return !existing.includes(i.value)
      }).map((i) => i.value)
    }
  },
  data() {
    return {
      drag: false,
      unsorted: []
    }
  },
  methods: {
    groupedDataitems(groupname) {
      if (this.dataitems) {
        var result = {}
        var keys = Object.keys(this.dataitems).forEach((k) => {
          var di = this.dataitems[k]
          if (di.json && di.json.group && di.json.group == groupname) result[di.name] = di
        })
        return result
      }
    },
    getItem(val, items) {
      var found = items.find((i) => i.value == val)
      return items.find((i) => {
        return i.value == val.toString()
      })
    }
  }
}

</script>
<style lang="scss">
.draggable-list-container {

  .draggable-list.list-group {
    border: 2px solid;
    min-height: 300px;
  }
}

.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>