import { factory } from "./package/questionnaire/ClassLoader"
import constructionFunctions from "./package/utils/construction"
// import {QuestionnaireClassFactory} from "./package/classes/QuestionnaireClassFactory";
// import store from "~/store/"
import store from '~/store';
import Vue from 'vue';
// import i18ns from '~/plugins/i18n';
import { upperFirst } from 'lodash-es';

factory.setClass("Questionnaire",
  class extends factory.classes.Questionnaire {
    constructor(attributes) {
      super(attributes)


      if (this.isUnreleased === undefined) {
        this.isUnreleased = false;
      }
    }
    get link() {
      var link = "";
      if (store.getters["auth/isTeam"]) {
        link = "/";
      }
      link += '/questionnaire/' + this.name;
      return link;
    }
    recordLink(record) {
      var link = "";
      if (this.model_type == "Dynamic") {
        link += this.link + "/" + record.record_no;
      } else {
        link += this.link;
      }

      if (store.getters["auth/isTeam"]) {
        link += "/" + record.patient_id;
      }
      return link;
    }
    get timelineLink() {
      return "/timeline/" + this.name;
    }
    navigateTo(router, record) {
      var onAbort = function () { }
      if (record !== undefined) {
        router.push(this.recordLink(record), null, onAbort)
      } else {
        router.push(this.link, null, onAbort);
      }
    }
    get lang() {
      if (!this.langProxy) {
        this.langProxy = new Proxy(this, new langProxyHandler());
      }
      return this.langProxy;
    }
    transPath(name) {
      return "questionnaires." + this.name + "." + name;
    }
    fallbackTransPath(name) {
      return "questionnaires.questionnaire." + name;
    }
    trans(name) {
      if (!this.hasMainTrans(name) && this.hasFallbackTrans(name)) {
        var location = this.fallbackTransPath(name)
      } else {
        var location = this.transPath(name)
      }
      return window.i18n.t(location)
    }
    hasMainTrans(name) {
      var location = this.transPath(name)
      return window.i18n.te(location)
    }
    hasFallbackTrans(name) {
      var location = this.fallbackTransPath(name)
      return window.i18n.te(location)
    }
    hasTrans(name) {
      return this.hasMainTrans(name) || this.hasFallbackTrans(name)
    }
  })
// factory.setClass("OneshotRecord", 
// class extends factory.classes.OneshotRecord {

// })
// factory.setClass("ScheduledRecord", 
// class extends factory.classes.ScheduledRecord {

// })
// factory.setClass("DynamicRecord", 
// class extends factory.classes.DynamicRecord {

// })
// factory.setClass("ConsentFormRecord", 
// class extends factory.classes.OneshotRecord {
//   showBreadcrumbs() {
//     return false;
//   }
// })
// factory.setClass("DiagnosisRecord",
// class extends factory.classes.OneshotRecord {
//   showBreadcrumbs() {
//     return false;
//   }
// })
let langProxyHandler = class {
  constructor(path) {
    this.path = path;
  }
  get(target, prop, receiver) {
    if (typeof prop != 'string') return null;
    var fullPath = (this.path ? this.path + "." : "") + prop;
    var result = target.trans(fullPath)
    if (typeof result == 'object') {
      return new Proxy(target, new langProxyHandler(fullPath));
    }
    return result;
  }
  has(target, prop, receiver) {
    var fullPath = (this.path ? this.path + "." : "") + prop;
    return target.hasTrans(fullPath)
  }
}
factory.setClass("ValidationRule",
  class extends factory.classes.ValidationRule {
    get message() {
      if (this.hasTrans("message")) {
        return this.trans("message");
      } else {
        return null;
      }
    }
    set message(message) {
      this.serverMessage = message
    }
    get lang() {
      if (!this.langProxy) {
        this.langProxy = new Proxy(this, new langProxyHandler());
      }
      return this.langProxy;
    }
    transPath(name) {
      return "questionnaires." + this.dataitem.questionnaire.name + ".validationrules." + this.dataitem.name + "." + this.ruleName + "." + name;
    }
    trans(name) {
      var location = this.transPath(name)
      return window.i18n.t(location)
    }
    hasTrans(name) {
      var location = this.transPath(name)
      return window.i18n.te(location)
    }
  })
factory.setClass("Dataitem",
  class extends factory.classes.Dataitem {
    get label() {
      if (this.hasTrans("label")) {
        return this.trans("label");
      } else {
        return null;
      }
    }
    set label(value) {
      this.serverLabel = value;
    }
    get lang() {
      if (!this.langProxy) {
        this.langProxy = new Proxy(this, new langProxyHandler());
      }
      return this.langProxy;
    }
    transPath(name) {
      return "questionnaires." + this.questionnaire.name + ".dataitems." + this.name + "." + name;
    }
    trans(name) {
      var location = this.transPath(name)
      return window.i18n.t(location)
    }
    hasTrans(name) {
      var location = this.transPath(name)
      return window.i18n.te(location)
    }
  })
factory.setClass("LookupItem",
  class extends factory.classes.LookupItem {
    get label() {
      if (this.lookup.dataitem.lookup_type != "TableLookup") {
        return this.trans("label");
      } else {
        return this.serverLabel;
      }
    }
    set label(value) {
      this.serverLabel = value;
    }
    get lang() {
      if (!this.langProxy) {
        this.langProxy = new Proxy(this, new langProxyHandler());
      }
      return this.langProxy;
    }
    transPath(name) {
      return "questionnaires." + this.lookup.dataitem.questionnaire.name + ".lookupitems." + this.lookup.name + "." + this.value + "." + name;
    }
    trans(name) {
      var location = this.transPath(name);
      return window.i18n.t(location)
    }
    hasTrans(name) {
      var location = this.transPath(name);
      console.log(location, window.i18n.te(location))
      return window.i18n.te(location)
    }
  })
// factory.setClass("Role" , class {
//   constructor(roleName) {
//     this.roleName = roleName;
//   }
//   get isTeam() {
//     return this.roleName=="team"
//   }
//   get isParticipant() {
//     return this.roleName=="participant"
//   }
// })
factory.setClass("DataSource", class {
  constructor(attributes) {
    var dataSource
    if (attributes.type == "dynamic") {
      attributes.model_type = _.upperFirst(attributes.type);
      dataSource = new factory.classes.Questionnaire(attributes)
      dataSource.isQuestionnaire = true;
    } else {
      dataSource = {};
      dataSource.name = attributes.name
      dataSource.title = attributes.title
      dataSource.isQuestionnaire = false;
    }
    dataSource.type = attributes.type
    dataSource.isSubmitted = attributes.isSubmitted;
    dataSource.mostRecentDateLoaded = null;
    dataSource.active = true;
    return dataSource
  }
})


export default {
  ...constructionFunctions,
  ...factory.classes
}