export default {
  methods: {
    patientIssueFromID(patient_issue_id, patient) {
      if (patient.issues)
        return patient.issues.find((issue) => issue.id == patient_issue_id)
    },
    patientQuestionnaires(patient_issue) {
      if (patient_issue && patient_issue.patient_questionnaire) {
        return [...patient_issue.patient_questionnaire].sort((a, b) => (a.questionnaire_id > b.questionnaire_id) ? 1 : -1);
      }
      if (patient_issue && patient_issue.patient_questionnaire_summary) {
        return [...patient_issue.patient_questionnaire_summary].sort((a, b) => (a.questionnaire_id > b.questionnaire_id) ? 1 : -1);
      }
    },
    valid_questionnaires(pQs) {
      if (pQs)
        return pQs.filter((pq) => { return pq.valid == 1; });
    },
    invalid_questionnaires(pQs) {
      if (pQs)
        return pQs.filter((pq) => { return pq.valid == 0; });
    },
    percentage_complete(patient_issue) {
      var pc = 0;
      var pQs = this.patientQuestionnaires(patient_issue)
      if (patient_issue && pQs && pQs.length) {
        pc = this.valid_questionnaires(pQs).length / pQs.length * 100;
      }
      return Math.round(pc)
    },
    chartData(patient_issue) {
      var pQs = this.patientQuestionnaires(patient_issue)
      var validQs = this.valid_questionnaires(pQs)
      var invalidQs = this.invalid_questionnaires(pQs)
      return [
        { val: validQs.length },
        { val: invalidQs.length },
        { val: pQs.length - validQs.length - invalidQs.length }
      ]
    },
  }

}
