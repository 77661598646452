<script>
import { select, arc, scaleOrdinal, pie, interpolate, interpolateRound } from 'd3';
var tau = 2 * Math.PI;
export default {
  name: 'donut-progress',
  props: {
    width: {
      type: Number,
      default: 50
    },
    height: {
      type: Number,
      default: 50
    },
    innerRadius: {
      type: Number,
      default: 14
    },
    outerRadius: {
      type: Number,
      default: 17
    },
    label: {
      type: Number,
      default: 0
    },
    id: {
      type: String,
      default: 'test'
    },
    chartData: {
      type: Array,
      default: () => ([
        { val: 0 }, /* valid*/
        { val: 0 }, /* invalid */
        { val: 0 } /* unstarted */
      ])
    },
    colours: {
      type: Array,
      default: () => (["var(--bs-success)", "var(--bs-danger)", "rgba(120,120,120,0.1)"])
    },
    backgroundColour: {
      type: String,
      default: 'rgba(120,120,120,0.1)'
    }
  },
  mounted() {
    this.svg = select(this.$el)
    this.$nextTick(function () {
      this.draw();
    });
  },
  render(h) {
    return h('svg', {
      attrs: {
        id: this.id,
        width: this.width,
        height: this.height,
        viewBox: "0 0 " + this.width + " " + this.height
      }
    }, []);
  },
  watch: {
    chartData: {
      handler(oldVal, val) {
        var hasChanged = oldVal[0].val !== val[0].val || oldVal[1].val !== val[1].val || oldVal[2].val !== val[2].val
        if (hasChanged)
          this.$nextTick(function () {
            this.redraw(oldVal, val);
          });
      }, deep: true
    }
  },
  computed: {
    innerRadiusComputed() {
      return this.innerRadius || this.outerRadius - 10
    },
    outerRadiusComputed() {
      return this.outerRadius || this.width / 2
    }
  },
  methods: {
    draw() {
      var self = this;
      this.g = this.svg.append("g").attr("transform", "translate(" + this.width / 2 + ',' + this.height / 2 + ")");
      this.arc = arc().innerRadius(this.innerRadiusComputed).outerRadius(this.outerRadiusComputed);
      var color = scaleOrdinal(this.colours)
      this.pie = pie()
        .sort(null)
        .value(function (d) { return d.val; });

      this.background = this.g.append("path")
        .datum({ startAngle: 0, endAngle: tau })
        .attr("class", "background")
        .style("fill", this.backgroundColour)

        .attr("d", this.arc);

      this.chart_label = this.svg.append("text")
        .text(Math.round(this.label) + '%')
        .attr("font-size", "10px")
        .attr("transform", "translate(" + this.width / 2 + ',' + (this.height / 2 + 4) + ")")
        .attr("fill", 'var(--bs-body-color)')

        .style("text-anchor", "middle");
      this.chart = this.g.datum(this.chartData).selectAll("path.arc")
        .data(this.pie).enter()
        .append("path")
        .attr("class", "arc")
        .attr("fill", function (d, i) { return color(i); })
        .attr("d", this.arc)
      // store the initial angles
    },
    redraw() {

      var self = this;
      this.chart = this.chart.data(this.pie(this.chartData)); // compute the new angles
      // this.chart_label = this.chart_label.data(this.pie(this.chartData)); // compute the new angles

      this.chart_label
        .text(this.label)
        .transition()
        .duration(750)
        .tween('text', tweenText(this.label));

      this.chart
        .transition()
        .duration(750)
        .attrTween("d", arcTween); // redraw the arcs

      function arcTween(a) {
        var i = interpolate(self._current, a);
        self._current = i(0);

        return function (t) {
          return self.arc(i(t));
        };
      }

      function tweenText(newValue) {
        return function () {
          var currentValue = +self.chart_label.text().replace('%', '');
          var i = interpolateRound(currentValue, newValue);
          return function (t) {
            self.chart_label.text(i(t) + '%');
          };
        }
      }
    },

  }

}

</script>
