import { makeQuestionnaireRoutes } from './questionnaires.js'
const pageImport = import.meta.globEager('../pages/**/*.vue')
const pages = {}
for (const path in pageImport) {
    var mod = pageImport[path]
    var newPath = path.replace('../pages/', '')
    pages[newPath] = mod.default
}
function page(path) {
    return pages[path]
}
const adminClinicianQuestionnaires = makeQuestionnaireRoutes({ name_prefix: 'admin.clinician.', exclude: { group: ["Patient"] }, middleware: ['auth', 'role:isTeam'] })
const adminPatientQuestionnaires = makeQuestionnaireRoutes({ name_prefix: 'admin.patient.', exclude: { group: ["Clinician"] }, middleware: ['auth', 'role:isTeam'] })
const participantPatientQuestionnaires = makeQuestionnaireRoutes({ middleware: 'role:Participant' })
const participantClinicianQuestionnaires = makeQuestionnaireRoutes({ middleware: 'role:Clinician', exclude: { group: ["Patient"] } })
const namedCollectionRoutes = { adminClinicianQuestionnaires, adminPatientQuestionnaires, participantPatientQuestionnaires, participantClinicianQuestionnaires }
// Group routes from json
const routeJson = import.meta.globEager('./routes/*.json')
const routes = []
var allRoutes = []
const routeGroups = {}

for (const path in routeJson) {
    var routeGroup = routeJson[path]
    var newPath = path.replace('./routes/', '').replace(/(^.\/)|(\.json$)/g, '')
    allRoutes = allRoutes.concat(routeGroup.default)
    routeGroups[newPath] = routeGroup.default
}
var rootRoutes = allRoutes.filter((r) => r.root && r.root == true)
rootRoutes.forEach((r) => routes.push(findRoute(r.name)))




routes.push(findRoute('admin.dashboard'))
routes.push(findRoute('admin.countries'))
routes.push(findRoute('admin.participants'))
// routes.push(findRoute('admin.participant_overview'))
// routes.push(findRoute('admin.participant_overview.questionnaire'))

routes.push(findRoute('admin.questionnaires'))
routes.push(findRoute('admin.questionnaire'))

routeGroups.wrappers.forEach((wrapper) => {
    // console.log('wrapper', wrapper)
    if (wrapper.name) wrapper = { ...wrapper, ...findRoute(wrapper.name) }
    else wrapper.component = page(wrapper.component_path)
    if (wrapper.children) {
        if (typeof wrapper.children == 'object') wrapper.children = findRoutesFromArray(wrapper.children)
        if (typeof wrapper.children == 'string') wrapper.children = findRoutesFromNamedCollection(wrapper.children)
    }
    routes.push(wrapper)
})
// routes.push(findRoute('404'))

export function findRoute(name) {
    if (name) {
        var rootConfig = allRoutes.find((r) => r.name == name)
        // console.log(name, rootConfig)
        rootConfig.component = page(rootConfig.component_path)
        rootConfig.props = props
        return rootConfig
    }
}
export function findRoutesFromArray(arr) {

    return arr.map((a) => {
        return findRoute(a)
    })
}
export function findRoutesFromNamedCollection(name) {
    return namedCollectionRoutes[name]
}
function props(route) {
    var name = route.meta && route.meta.name ? route.meta.name : route.name
    return { name, ...route.params }
}
export default routes