<template>
  <questionnaire v-if="questionnaire" :title="$t(lp + '.title')" :can-save="isParticipant && canSave">
    <form-group :di="dataitems.TakingSteroids" :error="errors.TakingSteroids" cols="7|5" class="mb-3" v-slot="{ di }">
      <btn-group outline justified :di="di" v-model="records.TakingSteroids"></btn-group>
    </form-group>
    <form-group :di="dataitems.MaxSteroidDose" :error="errors.MaxSteroidDose" class="mb-3" v-slot="{ di }">
      <btn-group outline justified :di="di" v-model="records.MaxSteroidDose"></btn-group>
    </form-group>
    <form-group :di="dataitems.CurrentSteroidDose" :error="errors.CurrentSteroidDose" class="mb-3" v-slot="{ di }">
      <btn-group outline justified :di="di" v-model="records.CurrentSteroidDose"></btn-group>
    </form-group>
    <hr>
    <form-group>
      <template #label>
        {{ $t(lp + '.CurrentMedication') }}
      </template>
      <btn-group justified vertical>
        <template v-for="di in medGroup">
          <btn :key="di.name" outline v-model="records[di.name]">
            <span v-html="di.label"></span>
          </btn>
        </template>
      </btn-group>
    </form-group>
    <collapse :show="records.OtherImmunoTherapy == 1" class="mt-2">
      <form-group v-if="records.OtherImmunoTherapy == 1" :di="dataitems.OtherImmunoTherapyTxt"
        :error="errors.OtherImmunoTherapyTxt" class="mb-3" v-slot="{ di }">
        <textbox v-model="records.OtherImmunoTherapyTxt"></textbox>
      </form-group>
    </collapse>
    <hr>

    <form-group :di="dataitems.OtherConditionMeds" :error="errors.OtherConditionMeds" cols="7|5" class="mb-3"
      v-slot="{ di }">
      <btn-group outline justified :di="di" v-model="records.OtherConditionMeds"></btn-group>
    </form-group>
    <form-group :di="dataitems.FlareInLast6Months" :error="errors.FlareInLast6Months" cols="7|5" class="mb-3"
      v-slot="{ di }">
      <btn-group outline justified :di="di" v-model="records.FlareInLast6Months"></btn-group>
    </form-group>
    <form-group :di="dataitems.HospitalVisitInLast6Months" :error="errors.HospitalVisitInLast6Months" cols="7|5"
      class="mb-3" v-slot="{ di }">
      <btn-group outline justified :di="di" v-model="records.HospitalVisitInLast6Months"></btn-group>
    </form-group>

    <template #footer-start>
      <slot name="footer-start"></slot>
    </template>

  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
export default {
  name: 'clinical_info',
  mixins: [questionnaireMixin],

  computed: {
    medGroup() {
      if (this.dataitems) {
        var meds = {}
        Object.keys(this.dataitems).forEach((diKey) => {
          var di = this.dataitems[diKey];
          if (di.json && di.json.group && di.json.group == 'meds') meds[di.name] = di
        })
        return meds
      }
      return []
    }
  }
}

</script>
