import questionnaireRouteConfigs from './routes/questionnaires.json'
import { resolveImport } from '../helpers/module-importer.js'
const pageImport = import.meta.globEager('../pages/**/*.vue')
const pages = resolveImport(pageImport, '../pages/')
function page(path) {
  return pages[path]
}
export function questionnaireTransform(route, params = {}) {
  if (!route.meta) route.meta = {}
  if (params.name_prefix) {
    route.meta.name = route.name.split('admin.')[0]
    route.name = params.name_prefix + route.name
  }
  if (route.component_path && !route.component) route.component = page(route.component_path)
  if (params.middleware) {
    if (!route.meta) route.meta = {};
    route.meta.middleware = params.middleware
  }
  route.props = props

  if (route.type == "dynamic") {
    route.path += "/:record_no?"
  }
  if (route.type) {
    route.meta.type = route.type
  }
  return route
}
export function makeQuestionnaireRoutes(params) {
  var collection = []
  var excludeCheck = params.exclude ? params.exclude : false
  for (var index in questionnaireRouteConfigs) {
    var conf = questionnaireRouteConfigs[index]
    var shouldExclude = false
    if (excludeCheck) {
      var excludeKeys = Object.keys(excludeCheck);
      for (let index = 0; index < excludeKeys.length; index++) {
        const excludeKey = excludeKeys[index], excludeVal = excludeCheck[excludeKey]
        if (conf[excludeKey] == excludeVal) shouldExclude = true;
      }

    }
    if (!shouldExclude) {
      var clone = Object.assign({}, conf)
      var formatted = questionnaireTransform(clone, params)
      collection.push(formatted)
    }

  }
  return collection
}

function props(route) {
  var name = route.meta && route.meta.name ? route.meta.name : route.name
  return { name, ...route.params }
}