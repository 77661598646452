<template>
  <div>
    <card head :title="$t('admin.questionnaires.title')" class="mb-4" :body="false" foot>
      <data-table :data="questionnairesSchema.questionnaires" :columns="questionnaireColumns" hide-index
        class="questionnaires-datatable">
        <template #header-column="{ col }">
          <div v-if="col.sortable" @click="setSorting(col.name)"
            :class="['dt-column dt-column-header dt-column-' + col.name, sortingClass(col)]">
            <div class="p-2" v-text="col.label" />
          </div>
          <div v-else :class="['dt-column dt-column-header dt-column-' + col.name + '']">
            <div class="p-2" v-text="col.label" />
          </div>
        </template>
        <template #cell-view="{ rowData }">
          <btn size="sm" block is-router-link :to="{ name: 'admin.questionnaire', params: { name: rowData.name } }">
            <fa icon="play" />
          </btn>
        </template>

      </data-table>
      <template #footer-inner>
        <div class="d-flex">
          <ul class="pagination m-auto">
            <li v-for="link in pagination.links" :class="['page-item', { active: link.active, disabled: !link.url }]">
              <a @click="updatePagination(link)" class="page-link" v-html="link.label" />
            </li>
          </ul>
          <dropdown v-model="pagination.per_page" :items="paginationPerPageList" class="m-auto" btn-outline>
            <template #btn-inner="{ chosenLabel }">{{ chosenLabel }} Per Page</template>
          </dropdown>
        </div>
      </template>
    </card>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import paginationMixin from '~/mixins/pagination'
export default {
  name: "questionnaires",
  metaInfo() {
    return { title: this.$t('admin.questionnaires.title') }
  },
  middleware: ['auth', 'team'],
  mixins: [paginationMixin],
  apiMethod: 'getQuestionnairesSchema',
  data() {
    return {
      params: {
        append: 'dataitem_count',
        sort: 'name',
      }
    }
  },
  mounted() {
    var params = this.params
    this.getQuestionnairesSchema({ params });
  },
  methods: {
    ...mapActions("resources/questionnaires", ["getQuestionnairesSchema"]),
  },
  computed: {
    ...mapState("resources/questionnaires", ["questionnairesSchema", "pagination"]),
    questionnaireColumns() {
      return [
        { name: 'id', label: 'ID', Type: Number, sortable: true },
        // { name: 'user_id', label: 'User ID', Type: Number, sortable: true },
        { name: 'name', label: 'Name', Type: String, sortable: true },
        { name: 'title', label: 'Title', Type: String, sortable: true },
        { name: 'model_type', label: 'Model Type', Type: String, sortable: true },
        { name: 'dataitem_count', label: 'Dataitem Count', Type: String },
        // { name: 'email', label: 'Email', Type: String },
        // { name: 'status', label: 'Status', Type: Number, sortable: true  },
        { name: 'edit', label: 'Edit', Type: null },
        { name: 'view', label: 'View', Type: null },
      ]
    }
  }
}

</script>
<style lang="scss">
.questionnaires-datatable .datatable {
  overflow-x: auto;

  div.dt-row {
    grid-template-columns: [id] 80px [name] minmax(140px, 1fr) [title] minmax(240px, 1.5fr) [model_type] minmax(140px, 1fr) [dataitem_count] minmax(140px, 1fr) [edit] 60px [view] 60px;

    .dt-column {
      overflow: hidden;
    }

    .dt-column-header {
      border-top-width: 2px;
      border-bottom-width: 2px;

      &.sorting {
        border-bottom-color: var(--bs-primary);
      }

      &.sorting-desc {
        border-top-color: var(--bs-primary);
      }
    }
  }
}
</style>
