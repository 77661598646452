<template>
    <modal :show="show" size="lg" :title="title" @close="$emit('close')">
        <row form>
            <form-group :error="errors.first_name" :di="dataitems.first_name" :sizes="{ md: 6 }" class="mb-4">
                <textbox :di="dataitems.first_name" v-model="records.first_name" />
            </form-group>
            <form-group :error="errors.last_name" :di="dataitems.last_name" :sizes="{ md: 6 }" class="mb-4">
                <textbox :di="dataitems.last_name" v-model="records.last_name" />
            </form-group>
            <form-group :error="errors.email || serverErrors.email || serverErrors.email_hash" :di="dataitems.email"
                :sizes="{ md: 6 }" class="mb-4">
                <textbox :di="dataitems.email" v-model="records.email" />
            </form-group>
            <form-group :sizes="{ md: 6 }" class="mb-4">
                <div class="form-label">{{ $t('admin.countries.country_overview.team_member.roles') }}</div>
                <badge v-for="role in user.roles" class="me-2" :key="role.name">{{ role.name }}</badge>
            </form-group>
            <form-group :sizes="{ md: 6 }" class="mb-4">
                <div class="form-label">{{ $t('admin.countries.country_overview.team_member.countries') }}</div>
                <badge v-for="country in user.countries" type="secondary" class="me-2" :key="country.code.toLowerCase()">
                    <img :src="'https://flagcdn.com/' + country.code.toLowerCase() + '.svg'"
                        class="flag-img rounded me-2 ms-n1" :alt="country.code.toLowerCase()"
                        style="height:1rem; width:2rem;">
                    {{ country.name }}
                </badge>
            </form-group>
        </row>
        <template #footer-inner="{ close }">
            <form method="dialog">
                <btn @click.native="close" btn-class="secondary" class="me-2">Close</btn>
                <btn @click.native="saveTeamMember">Save changes</btn>
            </form>
        </template>
    </modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
export default {
    name: "TeamMember",
    props: {
        user: {
            type: Object,
            required: true
        },
        show: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            dataitems: {
                first_name: {
                    label: this.$t('admin.countries.country_overview.team_member.first_name'),
                },
                last_name: {
                    label: this.$t('admin.countries.country_overview.team_member.last_name'),
                },
                email: {
                    label: this.$t('admin.countries.country_overview.team_member.email'),
                },
            },
            records: {
                first_name: this.user.first_name,
                last_name: this.user.last_name,
                email: this.user.email,
            },
            errors: {
                first_name: null,
                last_name: null,
                email: null,
            },
            serverErrors: {
                first_name: null,
                last_name: null,
                email: null,
            }
        }
    },
    computed: {
    },
    methods: {
        ...mapActions('resources/admin', ['createTeamMember', 'updateTeamMember']),
        saveTeamMember() {
            // console.log('saveTeamMember', this.user)
            if (!this.user.id)
                this.createTeamMember(this.user)
            else this.updateTeamMember(this.user)
        }
    },
    mounted() {

    }
}
</script>
