<template>
  <questionnaire v-if="questionnaireLoaded" :title="$t(lp+'.title')" >
    <form-group :di="dataitems.DoB" :error="errors.DoB" cols="4|8" class="mb-3">
      <datepicker v-model="records.DoB"></datepicker>
    </form-group>
    <form-group :di="dataitems.Gender" :error="errors.Gender" cols="4|8" class="mb-3" v-slot="{di}">
      <btn-group outline justified :di="di" v-model="records.Gender"></btn-group>
    </form-group>
    <form-group :di="dataitems.EducationLevel" :error="errors.EducationLevel" cols="4|8" class="mb-3" v-slot="{di}">
      <btn-group outline justified vertical :di="di" v-model="records.EducationLevel"></btn-group>
    </form-group>
    <form-group :di="dataitems.FamilyStatus" :error="errors.FamilyStatus" cols="4|8" class="mb-3" v-slot="{di}">
      <btn-group outline justified vertical :di="di" v-model="records.FamilyStatus"></btn-group>
    </form-group>
    <form-group :di="dataitems.EmploymentStatus" :error="errors.EmploymentStatus" cols="4|8" class="mb-3" v-slot="{di}">
      <btn-group outline justified vertical :di="di" v-model="records.EmploymentStatus"></btn-group>
    </form-group>
    <hr>
    <h5 v-t="lp+'.ethnicity'" class="mb-3"></h5>
    <list-group flush :class="['form-group',hasError('Ethnicity') ?'is-invalid':'is-valid']">
      <list-group-item :key="group" v-for="(items,group) in groupedEthnicities" >
        <row>
        	<column :sizes="{md:4}"><h6>{{group}}</h6></column>
        <column :sizes="{md:8}">
        	<btn-group>
        		<btn outline :key="item.label" v-model="records.Ethnicity" v-for="item in items" :true-value="item.value" :false-value="null">{{item.label}}</btn>
        	</btn-group>
        </column>
        </row>
      </list-group-item>
    </list-group>
  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
export default {
  name: 'demographics',
  mixins: [questionnaireMixin],
  computed: {
    groupedEthnicities() {
      var groupedEthnicities = {}
      if(this.dataitems&&this.dataitems.Ethnicity)
      this.dataitems.Ethnicity.lookup.items.map((lui) => {
        if (!groupedEthnicities[lui.json.group]) groupedEthnicities[lui.json.group] = []
        groupedEthnicities[lui.json.group].push(lui)
      })
      return groupedEthnicities
    }
  }
}

</script>
