import Vue from 'vue'
import axios from 'axios'
import construction from "~/helpers/construction"
import store from '~/store'

export const state = {
  questionnairesSchema: {
    questionnaires: [],
    request: {}
  },
  questionnaireData: {},
  questionnairePreviews: {},
  // lookupitems: [],
  questionnaires: null,
  profileQuestionnaires: null,
  todoQuestionnaires: null,
  mwQuestionnaires: {},
  questionnaire: false,
  defaultTemplate: {},
  pagination: {},
  errors: {},
  serverErrors: {},
  records: {},

  routeChanging: false,
  questionnairesLoaded: false,
  questionnairesLoading: false,
  questionnaireLoading: false,
  questionnaireLoaded: false,
  questionnaireSaving: false,
  questionnaireSubmitting: false,
  questionnaireRecordsChanged: false,
}

// getters
export const getters = {
  getQuestionnaireByName: (state) => (name) => {
    if (state.questionnaireData[name]) return state.questionnaireData[name]
  },
  getQuestionnairesByName: (state) => (name, collection = 'questionnaires') => {
    if (state[collection] && state[collection].length) {
      var found = state[collection].find((q) => name == q.name)
      if (found) return found
    }
    return {}
  },
  getQuestionnaireDataitems: (state) => (qname) => {
    if (state.questionnaireData[qname]) {
      return state.questionnaireData[qname].dataitems;
    }
  },
  getMwQuestionnaires: (state) => (mw) => {
    if (state.mwQuestionnaires[mw]) return state.questionnaireData[mw]
  },
  getQuestionnaireValidation: (state) => (qname) => {
    if (state.questionnaireData[qname]) return state.questionnaireData[qname].validation
  },
  getQuestionnaireRecords: (state) => (qname) => {

    if (state.questionnaireData[qname]) return state.questionnaireData[qname].records
  },
  getQuestionnaireDefaultTemplate: (state) => (qname) => {
    if (state.questionnaireData[qname]) return state.questionnaireData[qname].defaultTemplate
  },
  getQuestionnaireErrors: (state) => (qname) => {
    if (state.questionnaireData[qname]) return state.questionnaireData[qname].errors
  },
  getQuestionnaireExtraData: (state) => (qname) => {
    if (state.questionnaireData[qname]) return state.questionnaireData[qname].extraData
  },
  namedQuestionnaireIsLoaded: (state) => (qname) => {
    if (state.questionnaireData[qname]) return state.questionnaireData[qname].loaded
    else return false
  }
}
function constructQuestionnaire(data) {
  var qArr = construction.constructObjects({
    '*.lookups.*.items.*': construction.LookupItem,
    '*.lookups.*': construction.Lookup,
    '*.lookups': construction.LookupCollection,
    '*.dataitems.*.validation_rules.*': construction.ValidationRule,
    '*.dataitems.*.validation_rules': construction.ValidationRuleCollection,
    '*.dataitems.*': construction.Dataitem,
    '*.dataitems': construction.DataitemCollection,
    '*': construction.Questionnaire,
  }, data, false);

  return qArr
}
// mutations
export const mutations = {
  GET_QUESTIONNAIRES(state, data) {
    // state.questionnaires = data.data
    var questionnaires = constructQuestionnaire(data.data)
    for (var questionnaire of questionnaires) {
      state.questionnaireData[questionnaire.name] = questionnaire
    }
    state.questionnairesLoading = false
    state.questionnairesLoaded = true
  },
  GET_QUESTIONNAIRES_SCHEMA(state, resp) {
    state.questionnairesSchema.questionnaires = resp.data.data
    state.questionnairesSchema.request = resp.request
  },
  GET_QUESTIONNAIRE(state, data) {
    /* For admin datatable */
    state.questionnaire = data.questionnaire
    state.lookupitems = data.questionnaire.lookupitems
    state.validation = data.questionnaire.validation
  },
  GET_QUESTIONNAIRE_DATA(state, data) {
    var questionnaire = construction.constructObjects({
      'questionnaire.lookups.*.items.*': construction.LookupItem,
      'questionnaire.lookups.*': construction.Lookup,
      'questionnaire.lookups': construction.LookupCollection,
      'questionnaire.dataitems.*.validation_rules.*': construction.ValidationRule,
      'questionnaire.dataitems.*.validation_rules': construction.ValidationRuleCollection,
      'questionnaire.dataitems.*': construction.Dataitem,
      'questionnaire.dataitems': construction.DataitemCollection,
      // 'questionnaire.records': construction.Record,
      'questionnaire': construction.Questionnaire,
    }, data, false).questionnaire;

    const { dataitems, records, lookups, defaultTemplate, extraData } = questionnaire
    questionnaire.validation = {}
    if (questionnaire.validationRules) {
      questionnaire.validation.rules = questionnaire.validationRules.getRules()
      questionnaire.validation.messages = questionnaire.validationRules.getMessages()
    }
    questionnaire.recordsChanged = false
    questionnaire.loaded = true
    questionnaire.originalRecords = JSON.stringify(records)
    var errors = {}
    questionnaire.errors = errors;
    questionnaire.wasValidated = false;
    Vue.set(state.questionnaireData, questionnaire.name, questionnaire)
    state.questionnaireData[questionnaire.name] = questionnaire;
    state.records = questionnaire.records
    state.questionnaireLoading = false
    state.questionnaireRecordsChanged = false
    state.questionnaireLoaded = true

    state.routeChanging = false
    state.errors = {};
  },
  GET_QUESTIONNAIRE_PREVIEW(state, data) {
    var questionnaire = construction.constructObjects({
      'questionnaire.dataitems.*': construction.Dataitem,
      'questionnaire.dataitems': construction.DataitemCollection,
      'questionnaire.records': construction.Record,
      'questionnaire': construction.Questionnaire,
    }, data, false).questionnaire;
    state.questionnairePreviews[questionnaire.name] = questionnaire
  },
  SAVE_QUESTIONNAIRE_RECORDS(state, resp) {
    if (resp.data.saved) {
      state.questionnaireData[resp.name].setRecords(resp.data.records)
      state.records = state.questionnaireData[resp.name].records;
    }
    var data = resp.data
    state.questionnaireData = { ...state.questionnaireData };
    state.questionnaireData[resp.name].extraData = { ...state.questionnaireData[resp.name].extraData, ...data.extraData }

    state.serverErrors = state.questionnaireData[resp.name].validationRules.serverErrors(data.errors)
    state.errors = { ...state.questionnaireData[resp.name].errors, ...state.serverErrors }
    state.questionnaireData[resp.name].errors = state.errors
  },
  SUBMIT_QUESTIONNAIRE_RECORDS(state, resp) {
    var data = construction.constructObjects({
      'questionnaire.records': construction.Record,
    }, resp.data, false)
    state.questionnaireSaving = false
    Vue.set(state.questionnaireData[resp.name], 'records', data.records)
    if (data.extraData) state.questionnaireData[resp.name].extraData = data.extraData
    state.records = data.records
  },
  SET_ERRORS(state, data) {
    if (data.wasValidated) state.questionnaireData[data.name].wasValidated = true
    var existing = state.questionnaireData[data.name].errors
    var errors = data.merge ? { ...existing, ...data.errors } : data.errors

    state.questionnaireData[data.name].errors = errors
    Vue.set(state.questionnaireData[data.name], 'errors', errors)
    state.errors = errors
  },
  UPDATE(state, data) {
    for (var key in data) {
      state[key] = data[key]
    }
  },
  UPDATE_VALIDATION(state, data) {
  }
}

// actions
export const actions = {
  /* Admin / datatable version */
  getAllQuestionnaires({ commit, state }, request) {
    state.questionnairesLoading = true
    state.questionnairesLoaded = false
    var prefix = request.prefix ? request.prefix : ''
    return axios.get(prefix + 'questionnaire', request)
      .then((resp) => {
        commit('GET_QUESTIONNAIRES', { data: resp.data.data, request })
      })
  },
  getQuestionnairesSchema({ commit }, request) {
    var prefix = request.prefix ? request.prefix : ''
    return axios.get(prefix + 'questionnaire', request)
      .then((resp) => {
        commit('GET_QUESTIONNAIRES_SCHEMA', { data: resp.data, request })
      })
  },
  getQuestionnaire({ commit }, request) {
    request.participant_questionnaire = true;
    return axios.get('questionnaire/' + request.name, request)
      .then((resp) => {
        commit('GET_QUESTIONNAIRE', resp.data)
      })
  },
  /* Participant version */
  getQuestionnaires({ commit }, request) {
    state.questionnairesLoading = true
    state.questionnairesLoaded = false
    var isTeam = store.getters['auth/isTeam']
    if (isTeam && request.participant_id) {
      var url = "participant/" + request.participant_id + "/questionnaires"
    } else {
      var url = "questionnaires"
    }
    return axios.get(url, request)
      .then((resp) => {
        commit('GET_QUESTIONNAIRES', { data: resp.data.data, request })
      })

  },
  getQuestionnaireData({ commit }, request) {
    state.questionnaireLoading = true
    state.questionnaireLoaded = false
    var prefix = request.prefix ? request.prefix : ''
    var path = request.developPreview ? 'questionnaire' : 'questionnaires'
    return axios.get(prefix + path + '/' + request.name, request)
      .then((resp) => {
        commit('GET_QUESTIONNAIRE_DATA', resp.data)
      })
  },
  getQuestionnairePreview({ commit }, request) {
    var prefix = request.prefix ? request.prefix : ''
    return axios.get(prefix + 'questionnaires/' + request.name, request)
      .then((resp) => {
        commit('GET_QUESTIONNAIRE_PREVIEW', resp.data)
      })
  },
  setErrors({ commit }, request) {
    commit('SET_ERRORS', request)
  },
  update({ commit }, request) {
    commit('UPDATE', request)
  },
  updateValidation({ commit }, request) {
    commit('UPDATE_VALIDATION', request)
  },
  saveQuestionnaireRecords({ commit }, request) {
    state.questionnaireSaving = true
    var isTeam = store.getters['auth/isTeam'];
    var url = "";
    if (isTeam) {
      if (request.participant_id) {
        url = 'participant/' + request.participant_id + '/questionnaires/' + request.name;
      } else {
        url = 'questionnaires/' + request.name;
      }
    } else {
      url = 'questionnaires/' + request.name
    }
    return axios.put(url, request)
      .then((resp) => {
        state.questionnaireSaving = false
        commit('SAVE_QUESTIONNAIRE_RECORDS', { name: request.name, data: resp.data })
        commit('resources/participant/GET_PARTICIPANT_STAGES', resp.data, { root: true })
      }).catch((resp) => {
        state.questionnaireSaving = false
      })
  },
  submitQuestionnaireRecords({ commit }, request) {
    state.questionnaireSaving = true
    var isTeam = store.getters['auth/isTeam'];
    var url = "";
    if (isTeam) {
      url = 'participant/' + request.participant_id + '/questionnaires/submit' + request.name, request;
    } else {
      url = 'questionnaires/submit' + request.name, request
    }
    return axios.put(url, request)
      .then((resp) => {
        commit('SUBMIT_QUESTIONNAIRE_RECORDS', { name: request.name, data: resp.data })
        commit('resources/participant/GET_PARTICIPANT_STAGES', resp.data, { root: true })
      })
  },
}
export const namespaced = true;
