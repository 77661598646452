<template>
  <div class="dashboard grid-container">
    <span>Admin Dashboard</span>
    
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
export default {
  middleware: ['auth', 'team'],
  name: "dashboard",
  metaInfo() {
    return { title: this.$t('admin.dashboard.title') }
  },
}

</script>
<style lang="scss">
.dashboard.grid-container {
  display: grid;
  margin-top: 20px;
  ;
  grid-template-columns: 240px 1fr;
  grid-template-rows: 2rem 2fr 2rem;
  grid-template-areas:
    "sidenav header"
    "sidenav main"
    "sidenav footer";
  // min-height: 100vh;


  /* Give every child element its grid name */
  .header {
    grid-area: header;
    background-color: #648ca6;
  }

  .sidenav {
    grid-area: sidenav;
    background-color: #394263;
  }

  .main {
    * {
      border: thin solid;

    }

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 0.5fr 0.5fr;
    grid-template-areas: "main-chart main-chart main-chart"
    "col-1-left col-1-center col-1-right"
    "col-2-left col-2-center col-2-right";
    background-color: #8fd4d9;
  }

  .main-chart {
    grid-area: main-chart;
  }

  .column-1 {
    grid-area: col-1-left;
  }

  .column-2 {
    grid-area: col-1-center;
  }

  .column-3 {
    grid-area: col-1-right;
  }

  .row-1 {
    grid-column-start: col-2-left;
    grid-column-end: col-2-right;
  }

  .footer {
    grid-area: footer;
    background-color: #648ca6;
  }
}

</style>
