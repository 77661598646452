<template>
  <questionnaire v-if="questionnaireLoaded" :title="$t('questionnaires.treatment_goals.title')" :can-save="isParticipant">
    <form-group :di="dataitems.PD_TreatmentGoalsImportance" :error="errors.PD_TreatmentGoalsImportance" class="mb-5"
      v-slot="{ di }">
      <row class="draggable-list-container">
        <column>
          <draggable group="people" @start="drag = true" @end="drag = false" v-model="unsorted" tag="ol"
            class="draggable-list list-group h-100">
            <list-group-item v-for="(val) in unsorted" :key="val" class="d-flex">{{ getItem(val,
              di.lookup.items).label
            }}
            </list-group-item>
          </draggable>
        </column>
        <column>
          <div>
            <div class="fs-5">{{ $t(lp + '.most_important') }}</div>
            <draggable v-model="records.PD_TreatmentGoalsImportance" group="people" tag="ol" v-bind="dragOptions"
              class="draggable-list list-group h-100">
              <list-group-item v-for="(val, index) in records.PD_TreatmentGoalsImportance" :key="val" class="d-flex">
                <badge class="me-3">{{ index + 1 }}</badge>{{ getItem(val,
                  di.lookup.items).label
                }}
              </list-group-item>
            </draggable>
            <div class="fs-5">{{ $t(lp + '.least_important') }}</div>
          </div>

        </column>
      </row>

    </form-group>

  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'

export default {
  name: 'treatment_goals',
  mixins: [questionnaireMixin],
  components: {
    draggable
  },
  computed: {
    ...mapGetters('auth', ['isParticipant']),

  },
  mounted() {
    if (this.dataitems) {
      this.setupSortable()
    }
  },
  data() {
    return {
      drag: false,
      unsorted: []
    }
  },
  methods: {
    setupSortable() {
      console.log('setupSortable', this.dataitems.PD_TreatmentGoalsImportance)
      if (this.dataitems && this.dataitems.PD_TreatmentGoalsImportance) {
        var clone = [...this.dataitems.PD_TreatmentGoalsImportance.lookup.items]
        var existing = this.records.PD_TreatmentGoalsImportance
        this.unsorted = clone.filter((i) => {
          return !existing.includes(i.value)
        }).map((i) => i.value)
      }
    },
    groupedDataitems(groupname) {
      if (this.dataitems) {
        var result = {}
        var keys = Object.keys(this.dataitems).forEach((k) => {
          var di = this.dataitems[k]
          if (di.json && di.json.group && di.json.group == groupname) result[di.name] = di
        })
        return result
      }
    },
    getItem(val, items) {
      var found = items.find((i) => i.value == val)
      return items.find((i) => {
        return i.value == val.toString()
      })
    }
  },
  watch: {
    dataitems(val) {

      this.setupSortable()
    }
  }
}

</script>
