<template>
  <questionnaire v-if="questionnaireIsLoaded" :title="$t(lp + '.title')" :body="false" foot :collapsed="collapsed"
    :can-save="canSave">
    <template #header-right>
      <slot name="header-right"></slot>
    </template>
    <div class="card-body">
      <alert type="info" v-text="$t(lp + '.instructions')" />

      <form-group :di="dataitems.Diagnosis" :error="errors.Diagnosis" cols="4|8" class="mb-4">
        <template #label="{ di }">
          <div>{{ di.label }}</div>
          <small>
            <i18n tag="div" :path="lp + '.DiagnosisHelptext'" class="mt-4">
              <template #url>
                <a :href="$t(lp + '.url')">{{ $t(lp + '.url') }}</a>
              </template>
            </i18n>
          </small>
        </template>
        <template #default="{ di }">
          <btn-group v-model="records[di.name]" vertical justified outline :di="di"></btn-group>
        </template>
      </form-group>
      <form-group :di="dataitems.DiagnosisYears" :error="errors.DiagnosisYears" v-slot="{ di }" cols="4|8" class="mb-4">
        <btn-group v-model="records[di.name]" justified outline :di="di"></btn-group>
      </form-group>
      <form-group :di="dataitems.Steroids" :error="errors.Steroids" v-slot="{ di }" cols="4|8" class="mb-4">
        <btn-group v-model="records[di.name]" justified outline :di="di"></btn-group>
      </form-group>
      <hr>
      <form-group :di="dataitems.YoB" :error="errors.YoB" v-slot="{ di }" cols="4|8" class="mb-4">
        <textbox :di="dataitems.YoB" v-model="records.YoB" v-mask="{
          alias: 'year',
          placeholder: 'yyyy',
          yearrange: { minyear: 1900, maxyear: (new Date()).getFullYear() }
        }">
        </textbox>
      </form-group>
      <form-group :di="dataitems.Sex" :error="errors.Sex" v-slot="{ di }" cols="4|8" class="mb-4">
        <btn-group v-model="records[di.name]" justified outline :di="di"></btn-group>
      </form-group>
      <form-group :di="dataitems.EduLevel" :error="errors.EduLevel" class="mb-4">
        <template #label="{ di }">
          <div>{{ di.label }}</div>
          <small>{{ di.lang.helptext }}</small>

        </template>
        <template #default="{ di }">
          <btn :theme="EduHelptextShown ? 'primary' : 'secondary'" v-model="EduHelptextShown" :true-value="true"
            :false-value="false" class="rounded-5 p-1 mx-4 mb-2">
            <fa icon="info-circle" size="2x" />
          </btn>
          <list-group :class="['form-group mb-4']">
            <list-group-item :key="lui.name" v-for="( lui ) in di.lookup.items">
              <checkbox v-model="records[di.name]" :di="di" :true-value="lui.value" :label="lui.label" />
              <alert type="secondary" v-if="EduHelptextShown && lui.hasTrans('helptext')" class="m-1 p-3">
                <span v-text="lui.trans('helptext')"></span>
              </alert>
              <!-- <btn-group v-model="records[di.name]" vertical justified outline :di="di">
          <template v-if="EduHelptextShown" #btn-append="{ btn, di, updateValue, btnProps }">
           
          </template>
    </btn-group> -->
            </list-group-item>
          </list-group>
        </template>
      </form-group>
      <form-group :di="dataitems.Cohabit" :error="errors.Cohabit" v-slot="{ di }" cols="4|8" class="mb-4">
        <btn-group v-model="records[di.name]" justified outline :di="di"></btn-group>
      </form-group>
      <form-group :di="dataitems.Employ" :error="errors.Employ" v-slot="{ di }" cols="4|8" class="mb-4">
        <btn-group v-model="records[di.name]" vertical justified outline :di="di"></btn-group>
      </form-group>
      <form-group :di="dataitems.Ethnicity" :error="errors.Ethnicity" class="my-4">
        <template #label="{ di }">
          <div>{{ di.label }}</div>
          <small>{{ di.trans('helptext') }}</small>
        </template>
        <template #default="{ di }">
          <list-group :class="['form-group mb-4', hasError('Ethnicity') ? 'is-invalid' : 'is-valid']">
            <list-group-item :key="group" v-for="( items, group ) in groupedEthnicities ">
              <row class="mb-2">
                <column :sizes="{ md: 12 }">
                  <h6>{{ group }}</h6>
                </column>
                <column :sizes="{ md: 12 }">
                  <btn-group :vertical="['xs', 'sm'].includes($mq)" justified>
                    <btn outline :key="item.label" v-model="records.Ethnicity" v-for=" item in items "
                      :true-value="item.value" :false-value="null">{{ item.label }}</btn>
                  </btn-group>
                </column>
              </row>
              <collapse :show="ethnicityTxtRequiredInGroup(items)">
                <form-group :di="dataitems.EthnicityTxt" :error="errors.EthnicityTxt" v-slot="{ di }" cols="4|8"
                  class="mb-4 ms-4">
                  <textbox v-model="records[di.name]" :di="di"></textbox>
                </form-group>
              </collapse>
            </list-group-item>
          </list-group>
        </template>
      </form-group>
      <form-group :di="dataitems.OtherStudy" :error="errors.OtherStudy" v-slot="{ di }" cols="4|8" class="mb-4">
        <btn-group v-model="records[di.name]" justified outline :di="di"></btn-group>
      </form-group>
      <collapse :show="records.OtherStudy == 1">
        <form-group :di="dataitems.OtherStudyTxt" :error="errors.OtherStudyTxt" v-slot="{ di }" cols="4|8" class="mb-4">
          <textbox v-model="records[di.name]" :di="di"></textbox>
        </form-group>
      </collapse>
    </div>

  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
import { mapGetters } from 'vuex'
export default {
  name: 'screening1_b',
  mixins: [questionnaireMixin],

  data() {
    return {
      EduHelptextShown: false
    }
  },
  computed: {
    groupedEthnicities() {
      var groupedEthnicities = {}
      if (this.dataitems && this.dataitems.Ethnicity)
        this.dataitems.Ethnicity.lookup.items.map((lui) => {
          if (!groupedEthnicities[lui.json.group]) groupedEthnicities[lui.json.group] = []
          groupedEthnicities[lui.json.group].push(lui)
        })
      return groupedEthnicities
    }
  },
  methods: {
    ethnicityTxtRequiredInGroup(group) {
      if (this.records.Ethnicity) {
        var value = Number(this.records.Ethnicity)
        var di = this.dataitems.Ethnicity
        var luitems = group.filter((lui) => {
          return di.json.additional_text.includes(Number(lui.value))
        })
          .map((lui) => Number(lui.value))
        return luitems.includes(value)
      }
      return false
    }
  }
}

</script>
