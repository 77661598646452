<template>
  <div>
    <card head v-if="mustVerifyEmail" :title="$t('login.register')">
      <div class="alert alert-success" role="alert">
        {{ $t('verify_email_address') }}
      </div>
    </card>
    <form method="POST" v-else-if="questionnairesLoaded && form" @submit.prevent="register"
      @keydown="form.onKeydown($event)">
      <!-- <i18n v-if="isClinicianRoute" tag="div" class="alert alert-warning"
        path="questionnaires.register.clinician_patient_warning">
        <template #link>
          <router-link :to="{ name: 'patient.register' }" class="icon-link alert-link" tag="a">
            {{ $t('questionnaires.register.patient.register') }}
          </router-link>
        </template>
</i18n>
<i18n v-if="isPatientRoute" tag="div" class="alert alert-warning"
  path="questionnaires.register.patient_clinician_warning">
  <template #link>
          <router-link :to="{ name: 'clinician.register' }" class="icon-link alert-link" tag="a">
            {{ $t('questionnaires.register.clinician.register') }}
          </router-link>
        </template>
</i18n> -->
      <!-- Eligibility -->
      <card head foot :title="$t('questionnaires.eligibility.title')"
        :class="['mb-4', { 'was-validated': eligibilityValidated }]">
        <p class="lead" v-t="'questionnaires.eligibility.clinician_intro'"></p>
        <template v-for="item in eligibilityDataitems">
          <form-group :di="item" :key="item.name" v-slot="{ di }" hide-label :error="getError(eligibilityErrors, item)"
            class="mb-3">
            <row>
              <column :sizes="{ md: 9 }" class="align-self-center">
                <label class="control-label" v-html="di.label"></label>
                <i18n :path="'questionnaires.eligibility.dataitems.' + di.name + '.info'" tag="div"
                  class="mt-1 form-text text-muted">
                  <template #mail>
                    <a :href="'mailto:' + $t('participant.home.vasup_email')">{{
                      $t('participant.home.vasup_email') }}</a>
                  </template>
                </i18n>
              </column>
              <column :sizes="{ md: 3 }">
                <btn-group outline justified v-model="form[di.name]" :di="di" :class="['btn-group-toggle']"
                  :name="di.name" class="mb-3">
                  <!-- <template #btn-prepend>
                        <fa icon="check" class="me-2" />
                      </template> -->
                </btn-group>
              </column>
            </row>

          </form-group>
        </template>
        <template #footer-inner>
          <fa icon="thumbs-up" class="me-2 text-success" v-if="eligibilityCompleted && isEligible" />
          <fa icon="times-circle" class="me-2 text-danger" v-else-if="eligibilityCompleted && isIneligible" />
          <fa icon="question-circle" class="me-2 text-primary" v-else />
        </template>

      </card>
      <card head foot :body="false" v-if="consentQ" :title="consentQ.title"
        :class="['mb-4', { 'was-validated': eligibilityValidated }]">
        <collapse :show="eligibilityCompleted && isEligible">
          <alert class="m-2" v-if="location.countryCode == 'PT'">
            <i18n :path="'questionnaires.consent.subtitle_pt'" tag="label" for="tos" :class="['text-justify']"
              style="text-align: justify; text-justify: inter-word;">
              <template #link>
                <a :href="country.pis_path" target="_blank" v-t="'questionnaires.consent.subtitle_pt_link_text'" />
              </template>

            </i18n>
          </alert>
          <list-group flush>
            <template v-for="item in consentDataitems">
              <list-group-item :key="item.name">
                <form-group :di="item" v-slot="{ di }" hide-label :error="getError(consentErrors, item)">
                  <row>
                    <column :sizes="{ md: di.lookup.name == 'AUTNAOAUT' ? 12 : 10 }" class="align-self-center">
                      <i18n :path="'questionnaires.consent.dataitems.' + di.name + '.label'" tag="label" for="tos"
                        :class="['text-justify', { 'mx-md-4': consentMode == 'first_consent' }]"
                        style="text-align: justify; text-justify: inter-word;">
                        <template #information_sheet>
                          <a :href="country.pis_path" target="_blank"
                            v-t="'questionnaires.consent.dataitems.' + di.name + '.linkLabel'" />
                        </template>

                      </i18n>
                    </column>
                    <column :sizes="{ md: 2 }" v-if="location.countryCode == 'GB' && di.lookup.name == 'AgreeDisagree'">
                      <row class="d-flex align-items-center">
                        <column>
                          <div class="d-flex align-items-center">
                            <div class="m-auto">
                              <button type="button" @click="toggleTickCross(di.name, 1, 'consentForms', 0)"
                                class="btn p-1">
                                <fa icon="check" size="2x"
                                  :class="[{ 'text-success': form.consentForms[0][di.name] == 1 }]"></fa>
                              </button>
                              <button type="button" @click="toggleTickCross(di.name, 2, 'consentForms', 0)"
                                class="btn p-1">
                                <fa icon="times" size="2x"
                                  :class="['m-2', { 'text-danger': form.consentForms[0][di.name] == 2 }]">
                                </fa>
                              </button>
                            </div>
                          </div>
                        </column>
                      </row>
                    </column>
                    <column v-else-if="di.lookup.name == 'QuoteYN'" :sizes="{ md: 12 }">
                      <btn-group outline v-model="form.consentForms[0][di.name]" :di="di"
                        :class="['btn-group-toggle ms-4']" :name="di.name" class="my-3">
                        <template #btn-prepend>
                          <fa icon="check" class="me-2" />
                        </template>
                      </btn-group>
                    </column>
                    <column v-else-if="location.countryCode == 'PT' && di.lookup.name == 'AUTNAOAUT'"
                      :sizes="{ md: 12 }">

                      <btn-group justified outline v-model="form.consentFormPts[0][di.name]" :di="di"
                        :class="['btn-group-toggle ms-4']" :name="di.name" class="my-3">
                        <template #btn-prepend>
                          <fa icon="check" class="me-2" />
                        </template>
                      </btn-group>
                    </column>
                  </row>
                </form-group>
              </list-group-item>
            </template>
          </list-group>
        </collapse>
        <template #footer-inner>
          <fa icon="thumbs-up" class="me-2 text-success" v-if="consentCompleted && isValidConsent" />
          <fa icon="times-circle" class="me-2 text-danger" v-else-if="consentCompleted && !isValidConsent" />
          <fa icon="question-circle" class="me-2 text-primary" v-else />
        </template>
      </card>
      <card head foot :body="false" :title="$t('login.register')">
        <collapse :show="isEligible && isValidConsent">
          <div class="card-body">
            <row class="gx-3">
              <!-- First Name -->
              <column :sizes="{ lg: 6 }">
                <form-group class="mb-3" floating-label :label="$t('login.first_name')"
                  :error="[form.errors.get('first_name')]">
                  <textbox v-model="form.first_name" :class="{ 'is-invalid': form.errors.has('first_name') }"
                    name="first_name" :placeholder="$t('login.first_name')" />
                </form-group>
              </column>
              <!-- Last Name -->
              <column :sizes="{ lg: 6 }">
                <form-group class="mb-3" floating-label :label="$t('login.last_name')"
                  :error="[form.errors.get('last_name')]">
                  <textbox v-model="form.last_name" :class="{ 'is-invalid': form.errors.has('last_name') }"
                    name="last_name" :placeholder="$t('login.last_name')" />
                </form-group>
              </column>
            </row>
            <!-- Email -->
            <form-group class="mb-3" floating-label :label="$t('login.email')"
              :error="[form.errors.get('email') || form.errors.get('email_hash')]">
              <textbox v-model="form.email" :class="{ 'is-invalid': form.errors.has('email_hash') }" type="email"
                name="email" :placeholder="$t('login.email')" />
            </form-group>
            <row class="gx-3">
              <!-- Password -->
              <column :sizes="{ lg: 6 }">
                <form-group floating-label :label="$t('login.password')" :error="[form.errors.get('password')]"
                  :class="$mq | mq({ xs: 'mb-3', lg: '' })">
                  <textbox type="password" v-model="form.password"
                    :class="{ 'is-invalid': form.errors.has('password') }" name="password"
                    :placeholder="$t('login.password')" />
                </form-group>
              </column>
              <!-- Password Confirm -->
              <column :sizes="{ lg: 6 }">
                <form-group floating-label :label="$t('login.confirm_password')"
                  :error="[form.errors.get('password_confirmation')]">
                  <textbox type="password" v-model="form.password_confirmation"
                    :class="{ 'is-invalid': form.errors.has('password_confirmation') }" name="password_confirmation"
                    :placeholder="$t('login.confirm_password')" />
                </form-group>
              </column>
            </row>
          </div>
        </collapse>
        <div class="row" slot="footer-inner" v-if="isEligible && isValidConsent">
          <div class="col-md-6 offset-md-6 d-flex">
            <!-- Submit Button -->
            <button type="submit" name="register" :class="['btn btn-primary btn-block', { 'btn-loading': form.busy }]">
              {{ $t('login.register') }}
            </button>
          </div>
        </div>
      </card>
    </form>

  </div>
</template>
<script>
import Form from 'vform'
import { mapActions, mapState } from 'vuex'
export default {
  middleware: 'guest',
  metaInfo() {
    return { title: this.$t('login.register') }
  },

  data() {
    return {
      form: null,
      mustVerifyEmail: false,
      madeRecords: false,
      eligibilityValidated: false,
      consentValidated: false,
      params: {

        'filter[name]': 'eligibility,consent,register',
        include: 'dataitems',
        append: 'lookups,validation',
        records: true

      },

    }
  },
  mounted() {
    this.getAllQuestionnaires({ prefix: 'public/', params: this.params })
      .then(() => {
        this.makeRecords()
        this.form.participant_type = this.isClinicianRoute ? 1 : 2
        this.form.country_id = this.location.countryCode == 'GB' ? 1 : 2
        this.eligibilityRecords.participant_type = this.isClinicianRoute ? 1 : 2

      })
  },
  computed: {
    ...mapState('resources/questionnaires', ['questionnaireData', 'questionnairesLoaded']),
    country() {
      var pis_path = this.location.countryCode == 'GB' ? '/assets/documents/UK/VAS-UP1a Information sheet - Clinicians V1.pdf' : '/assets/documents/PT/VAS-UP1a - ConsentimentoInformadoClinician_VAS-UP JoanaMartinho.pdf'
      return { pis_path }
    },
    location() {
      return window.config.country
    },
    consentMode() {
      return 'first_consent';
    },
    isPatientRoute() {
      return this.$route.name == 'patient.register'
    },
    isClinicianRoute() {
      return this.$route.name == 'clinician.register'
    },
    eligibilityQ() {
      if (this.questionnairesLoaded && this.questionnaireData)
        return this.questionnaireData.eligibility
      return null
    },
    eligibilityDataitems() {
      if (this.eligibilityQ) {
        var group = this.isClinicianRoute ? 'clinician' : 'patient'
        var allDis = this.eligibilityQ.dataitems
        var dis = {}
        for (var key in allDis) {
          if (allDis[key].json && allDis[key].json.group == group) {
            dis[key] = allDis[key]
          }
        }
        return dis
      }
      return null
    },
    eligibilityRecords() {
      if (this.eligibilityQ) {
        return this.eligibilityQ.records
      }
      return null
    },
    eligibilityErrors() {
      if (this.eligibilityRecords && this.eligibilityRecords.errors) {
        return this.eligibilityRecords.errors
      }
      return null
    },
    eligibilityCompleted() {
      if (this.eligibilityQ && this.form) {
        var dis = this.eligibilityDataitems
        var completed = true
        for (var key in dis) {
          if (!this.form[key]) {
            completed = false
          }
        }
        return completed
      }
      return null
    },
    isEligible() {
      if (this.eligibilityQ && this.form) {
        var dis = this.eligibilityDataitems
        var eligible = true

        for (var key in dis) {
          if (key == 'P1B_VisionHearing' && this.form[key] == 2) continue;
          if (this.form[key] != 1 || (key == 'P1B_VisionHearing' && this.form[key] == 1)) {
            eligible = false
          }
        }
        return eligible
      }
      return null
    },
    isIneligible() {
      if (this.eligibilityQ && this.form) {
        var dis = this.eligibilityDataitems
        var ineligible = false
        for (var key in dis) {
          if (key == 'P1B_VisionHearing' && this.form[key] == 2) continue;
          if (this.form[key] == 2 || (key == 'P1B_VisionHearing' && this.form[key] == 1)) {
            ineligible = true
          }
        }
        return ineligible
      }
      return null
    },
    consentQ() {
      if (this.questionnairesLoaded && this.questionnaireData)
        return this.questionnaireData.consent
      return null
    },
    consentSectionName() {
      return this.location.countryCode == 'GB' ? 'consentForms' : 'consentFormPts'
    },
    consentSectionDataitem() {

      return this.consentQ.dataitems[this.consentSectionName]
    },
    consentDataitems() {
      if (this.consentQ) {
        return this.consentSectionDataitem.children
      }
      return null
    },

    consentCompleted() {
      if (this.consentQ && this.madeRecords) {
        var dis = this.consentDataitems
        console.log('consentCompleted', this.consentSectionName, this.form[this.consentSectionName][0], dis)
        var completed = true
        for (var key in dis) {
          if (!this.form[this.consentSectionName][0][key]) {
            completed = false
          }
        }
        return completed
      }
      return null

    },
    consentRecords() {
      if (this.consentQ && this.form && this.form[this.consentSectionName][0]) {
        return this.form[this.consentSectionName][0]
      }
      return null
    },
    isValidConsent() {
      if (this.consentQ && this.madeRecords && this.consentCompleted) {
        var dis = this.consentDataitems

        var valid = false
        // ensure all mustagree are ticked (=1)
        for (var key in dis) {
          if (dis[key] && dis[key].json && dis[key].json.type && dis[key].json.type == 'mustagree') {
            if (this.form[this.consentSectionName][0][key] == 1) {
              valid = true
            } else {
              valid = false
              break
            }
          }
        }

        return valid
      }
      return false
    },
    consentErrors() {
      if (this.consentQ && this.form && this.form[this.consentSectionName][0] && this.form[this.consentSectionName][0].errors) {
        return this.form[this.consentSectionName][0].errors
      }
      return null
    },
    registerQ() {
      if (this.questionnairesLoaded && this.questionnaireData)
        return this.questionnaireData.register
      return null
    },

  },
  methods: {
    ...mapActions('resources/questionnaires', ['getAllQuestionnaires']),
    hasError(errors, di) {
      if (errors && di) {
        if (errors[di.name] && errors[di.name].length > 0) {
          return true
        }
      }
      return false
    },
    getError(errors, di) {
      if (errors && di) {
        if (errors[di.name] && errors[di.name].length > 0) {
          // console.log('getError', errors[di.name][0])
          return errors[di.name][0]
        }
      }
      return null
    },
    async register() {
      // Register the user.
      const { data } = await this.form.post('register')

      // Must verify email fist.
      if (data.status) {
        this.mustVerifyEmail = true
      } else {
        // Log in the user.
        const { data: { token } } = await this.form.post('login')

        // Save the token.
        this.$store.dispatch('auth/saveToken', { token })

        // Update the user.
        await this.$store.dispatch('auth/updateUser', { user: data })

        // Redirect home.
        this.$router.push({ name: 'home' })
      }
    },
    toggleTickCross(name, value, section = null, sectionIndex = null) {
      // console.log('toggleTickCross', name, value, section, sectionIndex, this.form)
      if (section) {
        if (this.form[section][sectionIndex][name] == value) {
          this.form[section][sectionIndex][name] = null
        } else {
          this.form[section][sectionIndex][name] = value
        }
      } else
        if (this.form[name] == value) {
          this.form[name] = null
        } else {
          this.form[name] = value
        }
    },
    makeRecords() {
      if (this.questionnairesLoaded && this.questionnaireData) {
        // console.log('makeRecords', this.questionnaireData.eligibility.records, this.questionnaireData.consent.records, this.questionnaireData.register.records)
        var { errors, ...Elrecords } = this.questionnaireData.eligibility.records

        var sectionRecord = this.consentSectionDataitem.createSection(this.questionnaireData.consent.records)
        this.questionnaireData.consent.records[this.consentSectionName].push(sectionRecord)
        console.log('sectionRecord', sectionRecord)
        var { errors, ...Conrecords } = this.questionnaireData.consent.records
        var { errors, ...Regrecords } = this.questionnaireData.register.records
        Conrecords.country = this.location.countryCode == 'GB' ? 1 : 2
        var records = {
          ...Elrecords,
          ...Conrecords,
          ...Regrecords
        }
        this.form = new Form(records)
        this.madeRecords = true
      }
    },
    validateRecords() {
      if (this.madeRecords) {

      }
    },
    validateEligibility() {
      // console.log('validate eligibility')
      for (var key in this.eligibilityRecords) {
        if (key != 'errors') this.eligibilityRecords[key] = this.form[key]
      }
      this.eligibilityRecords.validate()
      this.eligibilityValidated = true
    },
    validateConsent() {
      // console.log('validate consent')
      for (var key in this.form[this.consentSectionName][0]) {
        if (key != 'errors') this.consentRecords[key] = this.form[this.consentSectionName][0][key]
      }
      this.form[this.consentSectionName][0].validate()
      this.consentValidated = true
      console.log('consentValidated', this.isValidConsent, this.consentValidated, this.form[this.consentSectionName][0].errors, this.form[this.consentSectionName][0].isValid)

    },
    clearRecords() {

      for (var item in this.form) {
        this.form[item] = null
      }
    }

  },
  watch: {
    form: {
      handler(val) {
        if (this.eligibilityCompleted)
          this.validateEligibility()
        if (this.consentCompleted && !this.consentValidated)
          this.validateConsent()
      },
      deep: true
    },
    isValidConsent(val) {
      if (val) {
        Object.keys(this.form[this.consentSectionName][0].errors).forEach((key) => {
          this.form[this.consentSectionName][0].errors[key] = []
        })
      }
    },
    // eligibilityCompleted(val) {
    //   if (val) {
    //     this.validateEligibility()
    //   }
    // },
    // isEligible(val) {
    //   if (this.eligibilityCompleted) {
    //     this.validateEligibility()
    //   }
    // },
    // isIneligible(val) {
    //   if (this.eligibilityCompleted) {
    //     this.validateEligibility()
    //   }
    // },
    consentCompleted(val) {
      if (val) {
        console.log('consentCompleted', val)

        this.validateConsent()
      }
    },

    'route.name'() {
      this.clearRecords()
    }
  }
}
</script>