<template>
  <questionnaire v-if="questionnaireLoaded" :title="$t(lp + '.title')">
    <form-group :di="dataitems.PD_DiscAssessing" :error="errors.PD_DiscAssessing" v-slot="{ di }" label-class="fs-6" class="ms-5">
      <slider v-model="records.PD_DiscAssessing" :labels="{ min: $t(lp + '.Slider3_min'), max: $t(lp + '.Slider3_max') }">
      </slider>
    </form-group>
    <hr>
    <form-group :di="dataitems.PD_DiscDealing" :error="errors.PD_DiscDealing" v-slot="{ di }" label-class="fs-6" class="ms-5">
      <slider v-model="records.PD_DiscDealing" :labels="{ min: $t(lp + '.Slider3_min'), max: $t(lp + '.Slider3_max') }">
      </slider>
    </form-group>
    <hr>
    <form-group :di="dataitems.PD_PatientSupport" :error="errors.PD_PatientSupport" v-slot="{ di }" label-class="fs-6" class="ms-5">
      <slider v-model="records.PD_PatientSupport" :labels="{ min: $t(lp + '.Slider1_min'), max: $t(lp + '.Slider1_max') }">
      </slider>
    </form-group>
    <hr>
    <h6 v-html="$t(lp + '.RelevantServices')"  class="mb-4"></h6>
      <form-group class="mb-5  ms-5">
        <list-group outline vertical justified>
          <template v-for="di in groupedDataitems('PD_Relevance')">
            <list-group-item is-link v-model="records[di.name]" v-html="di.label"></list-group-item>
            <form-group v-if="di.name == 'PD_SelfHelpOther' && records[di.name]" :di="dataitems[di.name + 'Specify']"
              :error="errors[di.name + 'Specify']" cols="3|9" class="my-3" v-slot="{ di }" @click.stop.prevent>
              <textbox v-model="records[di.name + 'Specify']"></textbox>
            </form-group>
          </template>
        </list-group>
      </form-group>
      <form-group :di="dataitems.PD_TreatmentGoalsImportance" :error="errors.PD_TreatmentGoalsImportance" class="mb-5" v-slot="{ di }">
        <row class="draggable-list-container">
          <column>
            <draggable group="people" @start="drag = true" @end="drag = false" v-model="unsorted" tag="ol"
              class="draggable-list list-group h-100">
              <list-group-item v-for="(val, index) in unsorted" :key="val" class="d-flex">{{ getItem(val,
                  dataitems.PD_TreatmentGoalsImportance.lookup.items).label
              }}
              </list-group-item>
            </draggable>
          </column>
          <column>
            <div>
              <div class="fs-5">{{ $t(lp + '.Slider1_max') }}</div>
              <draggable v-model="records.PD_TreatmentGoalsImportance" group="people" tag="ol" v-bind="dragOptions"
                class="draggable-list list-group h-100">
                <list-group-item v-for="(val, index) in records.PD_TreatmentGoalsImportance" :key="val" class="d-flex">
                  <badge class="me-3">{{ index + 1 }}</badge>{{ getItem(val,
                      dataitems.PD_TreatmentGoalsImportance.lookup.items).label
                  }}
                </list-group-item>
              </draggable>
              <div class="fs-5">{{ $t(lp + '.Slider1_min') }}</div>
            </div>

          </column>
        </row>

      </form-group>
  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
import draggable from 'vuedraggable'
export default {
  name: 'psychological_support',
  mixins: [questionnaireMixin],
  components: {
    draggable
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "people",
        disabled: false,
        ghostClass: "ghost"
      };
    },
  },
  mounted() {
    if (this.dataitems) {
      var clone = [...this.dataitems.PD_TreatmentGoalsImportance.lookup.items]
      var existing = this.records.PD_TreatmentGoalsImportance
      this.unsorted = clone.filter((i) => {
        return !existing.includes(i.value)
      }).map((i) => i.value)
    }
  },
  data() {
    return {
      drag: false,
      unsorted: []
    }
  },
  methods:{
    groupedDataitems(groupname) {
      if (this.dataitems) {
        var result = {}
        var keys = Object.keys(this.dataitems).forEach((k) => {
          var di = this.dataitems[k]
          if (di.json && di.json.group && di.json.group == groupname) result[di.name] = di
        })
        return result
      }
    },
    getItem(val, items) {
      var found = items.find((i) => i.value == val)
      return items.find((i) => {
        return i.value == val.toString()
      })
    },
  }
}

</script>
