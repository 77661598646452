<template>
  <div>
    <card head foot :title="$t('admin.dashboard.country', { country: getCountryLang(country).name, code: country.code })"
      class="mb-4" :body="false">
      <template #header-left>
        <btn outline size="sm" class="me-2 ms-n2" is-router-link :to="{ name: 'admin.countries' }">
          <fa icon="chevron-left" />
        </btn>
      </template>

      <row class="g-0 my-2" v-if="country && country.code">
        <column :sizes="[2, { sm: 2 }]" class="d-flex align-items-middle">
          <div class="m-auto ms-2">
            <img :src="'https://flagcdn.com/' + country.code.toLowerCase() + '.svg'"
              class="flag-img card-img rounded-left" :alt="country.code.toLowerCase()">
          </div>
        </column>
        <column :sizes="[10, { sm: 10 }]">
          <div class="card-body">
            <i18n path="admin.dashboard.country" tag="div">
              <template #country>
                <span class="fs-5">{{ getCountryLang(country).name }}</span>
              </template>
              <template #code>
                {{ country.code }}
              </template>
            </i18n>
            <div>
              {{ $t('admin.dashboard.country_recruitment', {
                recruited: country.recruited,
                target: country.recruitment_target_count
              })
              }}
            </div>
            <div>
              {{ $t('admin.dashboard.country_recruitment_start', {
                recruited_from: $d(new Date(country.study_start_date), 'numeric', 'en-GB'),
              })
              }}
            </div>
          </div>
        </column>
      </row>
      <card head foot :body="false" :title="$t('admin.countries.country_overview.team')" class="m-1">
        <data-table :data="country.team" :columns="countryTeamColumns" hide-index class="countries-datatable">
          <template #cell-name="{ rowData }">

            <div class="d-flex p-1">
              <img :src="rowData.photo_url" :alt="rowData.first_name + ' ' + rowData.last_name"
                class="rounded-circle profile-photo me-1">
              <div class="flex-grow-1 ms-2 p-1 " v-text="rowData.first_name + ' ' + rowData.last_name"></div>
            </div>
          </template>
          <template #cell-roles="{ rowData }">
            <div class="p-2">
              <template v-for="role in rowData.roles">
                <badge v-text="role.name" class="me-2" :key="role.name"></badge>
              </template>
            </div>
          </template>
          <template #cell-countries="{ rowData }">
            <div class="p-2">
              <router-link v-for="country in rowData.countries"
                :to="{ name: 'admin.country', params: { country_id: country.id } }" class="me-2"
                :key="country.code.toLowerCase()"> <img
                  :src="'https://flagcdn.com/' + country.code.toLowerCase() + '.svg'" class="flag-img"
                  :alt="country.code.toLowerCase()" style="height:1rem; width:2rem;">
              </router-link>
            </div>
          </template>
          <template #cell-edit="{ rowData }">
            <div class="p-1">
              <btn outline block size="sm" @click.native="showEditTeamMember(rowData)"
                :disabled="ifTeamMemberHasRole(rowData, 'Admin')">
                <fa icon="edit" />
              </btn>
            </div>
          </template>
        </data-table>
        <template #footer-right>
          <btn outline size="sm" @click.native="showNewTeamMember(newTeamMember)">
            <fa icon="plus" />
          </btn>
        </template>
      </card>
    </card>
    <team-member v-if="showEditTeamMemberModal" :title="modalTitle" :user="editingTeamMember"
      :show="showEditTeamMemberModal" @close="showEditTeamMemberModal = false"></team-member>
    <team-member v-if="showNewTeamMemberModal" :title="modalTitle" :user="newTeamMember" :show="showNewTeamMemberModal"
      @close="showNewTeamMemberModal = false"></team-member>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import TeamMember from './TeamMember.vue'
import countryMixin from '~/mixins/country';
export default {
  name: "country",
  mixins: [countryMixin],
  components: { TeamMember },
  metaInfo() {
    return { title: this.$t('admin.countries.title') }
  },
  middleware: ['auth', 'role:Admin'],
  apiMethod: 'getCountry',
  data() {

    return {
      params: {
        sort: 'id',
        include: 'team.roles,team.countries'
      },
      editingTeamMember: null,
      newTeamMember: null,
      showNewTeamMemberModal: false,
      showEditTeamMemberModal: false,
    }
  },
  mounted() {
    var name = this.name
    var params = this.params
    var country_id = this.$route.params.country_id
    this.getCountry({ country_id, params });
  },
  beforeRouteUpdate(to, from, next) {
    var name = this.name
    var params = this.params
    var country_id = to.params.country_id
    this.getCountry({ country_id, params });
    next()
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['isCountryTeam', 'isAdmin',]),
    ...mapState("resources/countries", ["country"]),
    countryTeamColumns() {
      return [
        { name: 'id', label: 'ID', Type: Number, sortable: true, width: 'max-content' },
        { name: 'name', label: this.$t('admin.countries.country_overview.columns.name'), Type: String, sortable: true, width: 'max-content' },
        { name: 'email', label: this.$t('admin.countries.country_overview.columns.email'), Type: String, sortable: true, width: 'max-content' },
        { name: 'roles', label: this.$t('admin.countries.country_overview.columns.roles'), Type: String, width: 'minmax(140px, 3fr)' },
        { name: 'countries', label: this.$t('admin.countries.country_overview.team_member.countries'), Type: String, width: 'max-content' },

        { name: 'edit', label: this.$t('admin.countries.country_overview.columns.edit'), Type: null, width: '60px' },
      ]
    },
    modalTitle() {
      if (this.newTeamMember)
        return this.$t('admin.countries.country_overview.team_member.new')
      else
        if (this.editingTeamMember)
          return this.$t('admin.countries.country_overview.team_member.edit', {
            name: this.editingTeamMember ? this.editingTeamMember.first_name + ' ' + this.editingTeamMember.last_name : ''
          })
      return ''
    }
  },
  methods: {
    ...mapActions("resources/countries", ["getCountry"]),
    showNewTeamMember(teamMember) {
      this.newTeamMember = {
        first_name: null,
        last_name: null,
        email: null,
        roles: [{ name: 'CountryTeam' }],
        countries: [{ name: this.getCountryLang(country).name, id: this.country.id, code: this.country.code }],
      }
      this.showNewTeamMemberModal = true
    },
    showEditTeamMember(teamMember) {
      this.editingTeamMember = teamMember
      this.showEditTeamMemberModal = true
    },
    ifTeamMemberHasRole(teamMember, role) {
      if (teamMember.roles) {
        return teamMember.roles.findIndex(r => r.name == role) > -1
      }
    }
  }
}

</script>
