<template>
  <div>
    <!-- <patient-breadcrumbs></patient-breadcrumbs> -->
    <card head :title="$t('admin.participants.overview.title', { code: participant.code })" class="shadow mb-4"
      v-if="loadedParticipant">
      <card class="mb-2">
        <row>
          <column>
            <div class="mb-2">
              <b>Name:</b> {{ participant.user.first_name }} {{ participant.user.last_name }}
            </div>
            <div class="mb-2">
              <b>Email:</b> {{ participant.user.email }}
            </div>
            <div class="mb-2">
              <badge class="p-2">
                <fa :icon="participant.participant_type == 1 ? 'user-md' : 'user'" class="me-2" />
                {{ participant.participantType }}
              </badge>
            </div>
          </column>
          <column :sizes="{ md: 4 }">
            <card title="Assigned Researchers:" head :body="false" class="m-n2">
              <template #header>
                <div class="card-header d-flex">
                  <div class="card-title mb-0">Assigned Researchers:</div>
                  <btn outline size="sm" class="ms-auto me-n2 my-n1 p-1"
                    @click.native="assignResearcherFormShown = !assignResearcherFormShown">
                    <fa icon="plus" />
                  </btn>
                </div>
              </template>
              <list-group flush>
                <list-group-item v-for="ra in participant.researchersAssigned" :key="ra.id" class="d-flex">
                  <img :src="ra.user.photo_url" :title="ra.full_name" :key="ra.id"
                    class="rounded-circle profile-photo m-0 me-2" />
                  <div class="flex-grow-1 me-auto">
                    {{ ra.full_name }}
                  </div>
                  <div class="ms-auto">{{ $d(new Date(ra.pivot.created_at), 'datetime', 'en-GB') }}</div>
                </list-group-item>
              </list-group>
              <collapse :show="assignResearcherFormShown">
                <div class="card-body">
                  <form-group>
                    <input-group>
                      <dropdown :items="researchersFiltered" value-name="id" label-name="full_name" multiple
                        v-model="researchersAssigned" @input="changedAssignedResearcher" btn-outline class="flex-grow-1"
                        btn-class="w-100 rounded-0">
                        <template #list-item-prepend="{ item }">
                          <img :src="item.user.photo_url" class="rounded-circle profile-photo me-1">
                        </template>
                      </dropdown>
                      <btn outline slot="append" class="input-group-btn" @click.native="saveAssignedResearchers">Save
                      </btn>
                    </input-group>
                  </form-group>
                </div>
              </collapse>
            </card>
          </column>
        </row>
      </card>
      <nav-bar tabs :links="navTabs" :current="currentTab" @change="changeTab" class="mx-n3">
        <template #nav-item="{ link }">
          <div :class="['nav-item nav-link', { active: currentTab == link.name }]" @click="changeTab(link.name)"> {{
            link.name }}</div>
        </template>
      </nav-bar>
      <div class="tab content mt-4">
        <template v-if="currentTab == 'Enrolment'">
          <card head :body="false" foot :title="$t('questionnaires.eligibility.title')" class="mb-2"
            v-if="loadedQuestionnaires">
            <template #header-right>
              <button type="button" class="btn btn-link" @click="showEligibility = !showEligibility">
                <fa icon="chevron-right"
                  :class="[{ 'fa-rotate-270': showEligibility, 'fa-rotate-90': !showEligibility }]"
                  style="transition:all .31s ease;" />
              </button>
            </template>
            <collapse :show="showEligibility">
              <div class="card-body">
                <template v-for="item in eligibilityDataitems">
                  <form-group :di="item" :key="item.name" v-slot="{ di }" hide-label class="mb-3">
                    <row>
                      <column :sizes="{ md: 9 }" class="align-self-center">
                        <label class="control-label" v-html="di.label"></label>
                        <i18n :path="'questionnaires.eligibility.dataitems.' + di.name + '.info'" tag="div"
                          class="mt-1 form-text text-muted">
                          <template #mail>
                            <a :href="'mailto:' + $t('participant.home.vasup_email')">{{
                              $t('participant.home.vasup_email') }}</a>
                          </template>
                        </i18n>
                      </column>
                      <column :sizes="{ md: 3 }">
                        <btn-group outline justified v-model="participant.eligibility[di.name]" readonly :di="di"
                          :class="['btn-group-toggle']" :name="di.name" class="mb-3">
                          <!-- <template #btn-prepend>
                        <fa icon="check" class="me-2" />
                      </template> -->
                        </btn-group>
                      </column>
                    </row>
                  </form-group>
                </template>
              </div>
            </collapse>
            <template #footer-left>
              {{ $t('questionnaires.questionnaire.updated_at', {
                datetime:
                  dateUpdated(participant.eligibility)
              }) }}
            </template>
          </card>
          <card head foot :body="false" :title="$t('questionnaires.consent.title')" class="mb-2">
            <template #header-right>
              <button type="button" class="btn btn-link" @click="showConsent = !showConsent">
                <fa icon="chevron-right" :class="[{ 'fa-rotate-270': showConsent, 'fa-rotate-90': !showConsent }]"
                  style="transition:all .31s ease;" />
              </button>
            </template>
            <collapse :show="showConsent">
              <list-group flush>
                <template v-for="item in consentDataitems">
                  <list-group-item :key="item.name">
                    <form-group :di="item" v-slot="{ di }" hide-label>
                      <row>
                        <column :sizes="{ md: 10 }" class="align-self-center">
                          <i18n :path="'questionnaires.consent.dataitems.' + di.name + '.label'" tag="label" for="tos"
                            :class="['text-justify', {}]" style="text-align: justify; text-justify: inter-word;">
                            <template #information_sheet>
                              <a :href="country.pis_path" target="_blank"
                                v-t="'questionnaires.consent.dataitems.' + di.name + '.linkLabel'" />
                            </template>

                          </i18n>
                        </column>
                        <column :sizes="{ md: 2 }"
                          v-if="participant.country_id == 1 && di.lookup.name == 'AgreeDisagree'">
                          <row class="d-flex align-items-center">
                            <column>
                              <div class="d-flex align-items-center">
                                <div class="m-auto">
                                  <button type="button" class="btn p-1">
                                    <fa icon="check" size="2x"
                                      :class="[{ 'text-success': participant.consent.consentForms[0][di.name] == 1 }]">
                                    </fa>
                                  </button>
                                  <button type="button" class="btn p-1">
                                    <fa icon="times" size="2x"
                                      :class="['m-2', { 'text-danger': participant.consent.consentForms[0][di.name] == 2 }]">
                                    </fa>
                                  </button>
                                </div>
                              </div>
                            </column>
                          </row>
                        </column>
                        <column v-else-if="di.lookup.name == 'QuoteYN'" :sizes="{ md: 12 }">
                          <btn-group outline v-model="participant.consent.consentForms[0][di.name]" readonly :di="di"
                            :class="['btn-group-toggle']" :name="di.name" class="my-3">
                            <template #btn-prepend>
                              <fa icon="check" class="me-2" />
                            </template>
                          </btn-group>
                        </column>
                        <column v-else-if="participant.country_id == 2 && di.lookup.name == 'AUTNAOAUT'"
                          :sizes="{ md: 12 }">
                          <btn-group justified outline v-model="participant.consent.consentFormPts[0][di.name]" readonly
                            :di="di" :class="['btn-group-toggle']" :name="di.name" class="my-3">
                            <template #btn-prepend>
                              <fa icon="check" class="me-2" />
                            </template>
                          </btn-group>
                        </column>
                      </row>
                    </form-group>
                  </list-group-item>
                </template>
              </list-group>
            </collapse>
            <template #footer-left>
              {{ $t('questionnaires.questionnaire.updated_at', {
                datetime:
                  dateUpdated(participant.consent)
              }) }}
            </template>
          </card>
          <alert type="success">
            <i18n path="participant.home.consent_status">
              <template #mail>
                <a :href="'mailto:' + $t('participant.home.vasup_email')">{{ $t('participant.home.vasup_email') }}</a>
              </template>
            </i18n>
          </alert>
        </template>
        <template v-if="currentTab == 'Screening'">
          <scope :stage="stages[0]" v-slot="{ stage }">
            <component :key="stage.questionnaires[0].name" :is="stage.questionnaires[0].name" non-route
              :name="stage.questionnaires[0].name" :participant_id="participant.id" :collapsed="stageIsCollapsed(stage)"
              class="mb-2" :can-save="false">
              <template #header-right="{}">
                <button type="button" class="btn btn-link" @click="stage.collapsed = !stage.collapsed">
                  <fa icon="chevron-right"
                    :class="[{ 'fa-rotate-270': !stage.collapsed, 'fa-rotate-90': stage.collapsed }]"
                    style="transition:all .31s ease;" />
                </button>
              </template>
              <template #footer-start="{}">
                {{ $t('questionnaires.questionnaire.updated_at', {
                  datetime:
                    dateUpdated(stage.records[stage.questionnaires[0].name])
                }) }}
              </template>
            </component>
            <alert v-if="stageCompleted(stage)" :type="stageCompleted(stage) ? 'success' : 'warning'" class="my-4">
              <i18n :path="'participant.home.stage_status.' + stage.order + '.summary'">
                <template #stage>{{ $t('participant.home.stage_status.' + stage.order + '.title') }}</template>
                <template #status>{{ stageCompleted(stage) ? $t('participant.home.stage_status.' + stage.order +
                  '.completed') : $t('participant.home.stage_status.' + stage.order + '.incomplete') }}</template>

              </i18n>
            </alert>
            <template v-if="stage.order == 1 && stageCompleted(stage) && (isAdmin || isTeam)">
              <card head :title="$t('admin.participants.overview.screening.title')" class="mb-2">
                <form-group :di="{ label: $t('admin.participants.overview.screening.assign_btn'), name: 'screening' }"
                  v-slot="{ di }" hide-label>
                  <row v-if="participant.status == 1">
                    <column :sizes="{ md: 12 }">
                      <button type="button" class="btn btn-primary" @click="assignNextStage">
                        {{ di.label }}
                      </button>
                    </column>
                  </row>
                  <row v-else>
                    <column :sizes="{ md: 12 }">
                      <alert type="success" class="m-n2">
                        {{ $t('admin.participants.overview.screening.completed', {
                          date: $d(new Date(postScreeningStage.pivot.created_at), 'datetime', 'en-GB')
                        }) }}
                      </alert>
                    </column>
                  </row>
                </form-group>
              </card>
            </template>
          </scope>
        </template>
        <template v-if="currentTab == 'Pre-Interview Questions'">
          <scope :stage="stages[1]" v-slot="{ stage }">
            <alert type="success">
              {{ $t('participant.home.stage_status.2.instructions') }}
            </alert>
            <questionnaire-collection :participant-id="participant.id" :collection="stage" :key="stage.name">
              <template #default="d">
                <component :is="d.activeQuestionnaire" non-route :name="d.activeQuestionnaire"
                  :participant_id="participant.id" :can-save="false">

                </component>
                <!-- <questionnaire-loading v-else /> -->
              </template>
              <template #footer-left>
                {{ $t('questionnaires.questionnaire.updated_at', {
                  datetime:
                    dateUpdated(latestQuestionnaireRecord(stage))
                }) }}
              </template>
            </questionnaire-collection>
            <template v-if="stageCompleted(stage) && participant.participant_type == 2">
              <!-- message to watch the videos -->
              <alert type="success" class="mt-4">
                <i18n path="participant.home.watch_videos.text">
                  <template #link>
                    <a @click="currentTab = 'Videos'" class="alert-link">{{ $t('participant.home.watch_videos.link')
                      }}</a>
                  </template>
                </i18n>
              </alert>
            </template>
          </scope>
        </template>
        <template v-if="currentTab == 'Videos'">
          <alert type="success">
            <i18n tag="p" path="participant.home.videos.p1"></i18n>
            <i18n tag="p" path="participant.home.videos.p2"></i18n>
            <i18n tag="p" path="participant.home.videos.p3"></i18n>
          </alert>

          <template v-for="video in participant.videos">
            <card :title="video.title" class="mb-2" :body="false" :key="video.title">

              <div class="card-body row">
                <column>
                  <h5 class="display-6 mb-4">
                    {{ video.title }}
                  </h5>
                </column>
                <column>
                  <youtube :video-id="video.video_id" ref="youtube"></youtube>
                </column>


              </div>
              <div class="card-footer" v-if="participantVideoStatus(video)">
                <fa icon="check" />
                <span v-if="participantVideoStatus(video).pivot.playing || participantVideoStatus(video).pivot.ended">
                  <span
                    v-if="participantVideoStatus(video).pivot.playing && participantVideoStatus(video).pivot.ended">Played</span>
                  <span
                    v-else-if="participantVideoStatus(video).pivot.playing && !participantVideoStatus(video).pivot.ended">Playing</span>
                  <span v-else-if="participantVideoStatus(video).pivot.ended">Watched</span>
                </span>
              </div>
            </card>
          </template>

        </template>

      </div>


    </card>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import patientIssueMixin from '~/mixins/patient_issue'
import screening1_a from '~/pages/questionnaires/screening1_a.vue'
import screening1_b from '~/pages/questionnaires/screening1_b.vue'
import QuestionnaireCollection from '~/components/QuestionnaireCollection.vue'
import clinical_info from '~/pages/questionnaires/clinical_info.vue'
import steroid_toxicity from '../questionnaires/steroid_toxicity.vue';
import phq9 from '../questionnaires/phq9.vue';
import psychological_support from '../questionnaires/psychological_support.vue';
import omeract from '../questionnaires/omeract.vue';
import treatment_population from '~/pages/questionnaires/treatment_population.vue'
import monitoring_tools_tests from '~/pages/questionnaires/monitoring_tools_tests.vue'
import monitoring_signs_findings from '~/pages/questionnaires/monitoring_signs_findings.vue'
import relative_importance from '~/pages/questionnaires/relative_importance.vue'
import willingness_discomfort from '~/pages/questionnaires/willingness_discomfort.vue'
import mental_health_support from '~/pages/questionnaires/mental_health_support.vue'
import treatment_goals from '~/pages/questionnaires/treatment_goals.vue'
import questionnaireLoading from '~/components/QuestionnaireLoading.vue'

export default {
  name: "participant-overview",

  metaInfo() {
    return { title: this.$t('admin.participants.participant_overview.title') }
  },
  components: {
    screening1_a,
    screening1_b,
    QuestionnaireCollection,
    clinical_info,
    steroid_toxicity,
    phq9,
    psychological_support,
    omeract,
    treatment_population,
    monitoring_tools_tests,
    monitoring_signs_findings,
    relative_importance,
    willingness_discomfort,
    mental_health_support,
    treatment_goals
  },
  scrollToTop: false,
  middleware: ['auth', 'role:Researcher,CentralTeam,CountryTeam,Admin'],
  mixins: [patientIssueMixin],
  data() {
    return {
      showConsent: false,
      showEligibility: false,
      loadedQuestionnaires: false,
      currentTab: 'Enrolment',
      stages: [],
      assignResearcherFormShown: false,
      researchersAssigned: [],
      params: {
        participant_id: this.$route.params.participant_id,
        include: 'user,eligibility,consent,stages',
      },
      qParams: {
        participant_id: this.$route.params.participant_id,
        'filter[name]': 'eligibility,consent',
        include: 'dataitems',
        append: 'lookups,validation',
      },
    }
  },
  mounted() {
    this.loadOverview()

  },
  computed: {
    ...mapState('auth', ['researchers']),
    ...mapState("resources/participant", ["participant", "loadedParticipant"]),
    ...mapState("resources/questionnaires", ["questionnaireData", "questionnaireLoaded"]),
    ...mapGetters('auth', ['isTeam', 'isAdmin']),
    researchersFiltered() {
      var type = this.participant.participantType
      if (this.researchers) {
        return this.researchers.filter((r) => {
          return r.research_arm.find((ra) => {
            return ra.name == type || ra.name == 'Both'
          })
        })
      }
      return []
    },
    country() {
      var pis_path = this.participant.participant_type == 1 ? '/assets/documents/UK/VAS-UP1a Information sheet - Clinicians V1.pdf' : '/assets/documents/UK/VAS-UP1b Information sheet - Patients V1.pdf'
      return { pis_path }
    },
    location() {
      return window.config.country
    },
    consentSectionName() {
      return this.participant.country_id == 1 ? 'consentForms' : 'consentFormPts'
    },
    navTabs() {
      var stages = this.participant.stages.map((stage, n) => {
        return { name: stage.name, key: stage.name }
      })
      var videoPage = this.stageCompleted(this.stages[1]) && this.participant.participant_type == 2 ? [{ name: 'Videos', key: 'Videos' }] : []
      return [
        { name: this.$t('admin.participants.overview.enrolment'), key: 'enrolment' },
        ...stages,
        ...videoPage
      ]
    },
    activeStage() {
      if (this.participant && this.participant.stages) {
        return this.participant.stages.find((stage) => stage.order == this.participant.status)
      }
      return null
    },
    activeStageCompleted() {
      if (this.activeStage)
        return this.activeStage.questionnaires.every((q) => {
          if (this.activeStage.records[q.name])
            return this.activeStage.records[q.name].valid == '1'
        })
      return false
    },
    postScreeningStage() {
      return this.participant.stages.find((stage) => stage.order == 2)
    },
    routePrefix() { return this.isTeam ? 'admin.' : '' },
    screeningQuestionnaire() {
      return (this.participant.participant_type == 1) ? 'screening1_a' : 'screening1_b'
    },
    screeningQuestionnaireName() {
      return (this.participant.participant_type == 1) ? 'screening1_a' : 'screening1_b'
    },
    eligibilityQ() {
      if (this.questionnaireData.eligibility) {
        return this.questionnaireData.eligibility
      }
      return null

    },
    eligibilityDataitems() {
      if (this.eligibilityQ && this.loadedParticipant) {
        var group = this.participant.participant_type == 1 ? 'clinician' : 'patient'
        var allDis = this.questionnaireData.eligibility.dataitems
        var dis = {}
        for (var key in allDis) {
          if (allDis[key].json && allDis[key].json.group == group) {
            dis[key] = allDis[key]
          }
        }
        return dis
      }
      return null
    },
    consentQ() {
      if (this.loadedQuestionnaires && this.questionnaireData)
        return this.questionnaireData.consent
      return null
    },
    consentSectionDataitem() {

      return this.consentQ.dataitems[this.consentSectionName]
    },
    consentDataitems() {
      if (this.consentQ) {
        return this.consentSectionDataitem.children
      }
      return null
    },
  },
  methods: {
    ...mapActions("auth", ['getResearchers']),
    ...mapActions("resources/participant", ["getParticipantOverview", "updateParticipantStage", "recordVideoStats", "assignResearchers"]),
    ...mapActions("resources/questionnaires", ["getAllQuestionnaires"]),

    loadOverview() {
      var params = this.params
      this.getParticipantOverview({ params })
        .then(() => {
          this.getAllQuestionnaires({
            params: this.qParams,
          }).then(() => {
            this.loadedQuestionnaires = true
            this.setupStages()
            this.getResearchers({})
              .then(() => {
                if (this.participant && this.participant.researchersAssigned && this.participant.researchersAssigned.length)
                  this.researchersAssigned = this.participant.researchersAssigned.map((ra) => {
                    return ra.id
                  })

              })

          })

        });
    },
    changeTab(name) {
      console.log('changeTab', name)
      this.currentTab = name
    },
    stageCompleted(stage) {
      if (stage) {
        return stage.questionnaires.every((q) => {
          if (stage.records[q.name])
            return stage.records[q.name].valid == '1'
        })
      }
      return false
    },
    assignNextStage() {
      this.updateParticipantStage({ participant_id: this.participant.id, params: { stage: this.activeStage.order + 1 } })
        .then(() => {
          this.loadOverview()
        })
    },

    setupStages() {
      if (this.participant.stages) {
        this.participant.stages.forEach((stage, n) => {
          this.$set(this.stages, n, { ...stage, collapsed: this.activeStage != stage || this.activeStageCompleted })
        })
        if (this.activeStage) {
          this.currentTab = this.activeStage.name

        }
      }
    },
    getQuestionnaireCollection(name) {
      return this.collections[name]
    },
    stageIsCollapsed(stage) {
      return stage.collapsed
    },
    participantOverviewClass(issue) {
      return [
        {
          'issue-valid': issue.valid == 'valid',
          'issue-invalid': issue.valid == 'started'
        }
      ]
    },
    listGroupItemClass(pq) {

      return [
        'list-group-item link-group-item-action',
        {
          'questionnaire-valid': pq.valid == 1,
          'questionnaire-invalid': pq.valid == 0
        }
      ]
    },
    participantVideoStatus(video) {
      if (this.participant.videoStats) {
        return this.participant.videoStats.find((v) => v.id == video.id)
      }
      else return {}
    },
    dateUpdated(record) {
      return this.$d(new Date(record.updated_at), 'datetime', 'en-GB')
    },
    latestQuestionnaireRecord(collection) {
      var latestRecord = collection
      collection.questionnaires.forEach((q) => {
        if (collection.records[q.name] && collection.records[q.name].updated_at > latestRecord.updated_at) {
          latestRecord = collection.records[q.name]
        }
      })
      return latestRecord
    },
    changedAssignedResearcher(val) {
    },
    saveAssignedResearchers() {
      this.assignResearchers({ participant_id: this.participant.id, params: { researchersAssigned: this.researchersAssigned } })
    }

  },
  watch: {
    'participant.stages'(newVal) {
      if (newVal)
        this.setupStages()
    },
  }
}

</script>
