export default {
  computed: {

  },
  methods: {
    addEmptyRecordToSection(sectionName) {
      console.log('addEmptyRecordToSection',sectionName) 
      var defaults = { ...this.defaultTemplate[sectionName] }
      this.records[sectionName].push(defaults)
      this.watchNewSection(sectionName, this.records[sectionName].length - 1)
    },
    watchNewSection(section, index) {
      var keys = Object.keys(this.records[section][index])
      keys.forEach((name) => {
        var watcher = this.$watch('records.' + section + '.' + index + '.' + name, function(newVal, oldVal) {
          if(!this.routeChanging) this.validate_dataitem([name], index)
        })
        if(this.$children[0]&&this.$children[0].unwatches) this.$children[0].unwatches.push(watcher)
      })
    },
    deleteSection(section, index) {
      this.records[section].splice(index, 1)
    },
  },

}
