<template>
    <card :title="$t('admin.exports.title')" head foot :body="false">
        <data-table :data="dataExports" :columns="columns" class="table-responsive">
            <template #cell-download="{ rowData }">
                <div class="p-2">
                    <btn v-if="!rowData.scheduled" outline @click.native="downloadExport(rowData.id)">
                        <fa icon="download" class="mr-1"></fa>
                        {{ $t('admin.exports.columns.download') }}
                    </btn>
                    <btn :disabled="scheduledExportGenerated(rowData.id)" v-else-if="rowData.scheduled" outline
                        @click.native="scheduleExport(rowData.id)">
                        <fa icon="clock" class="mr-1"></fa>
                        {{ $t('admin.exports.columns.scheduled') }}

                    </btn>
                </div>
            </template>
        </data-table>
    </card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    name: "exports-dashboard",
    data() {
        return {

        }
    },
    computed: {
        ...mapState('resources/exports', ['dataExports', 'generatedExports']),
        columns() {
            return [
                { name: 'name', label: this.$t('admin.exports.columns.name') },
                { name: 'class_name', label: this.$t('admin.exports.columns.class_name') },
                { name: 'description', label: this.$t('admin.exports.columns.description') },
                { name: 'scheduled', label: this.$t('admin.exports.columns.scheduled'), width: 'min-content' },
                { name: 'download', label: this.$t('admin.exports.columns.download'), width: 'max-content' },
            ]
        },
    },
    methods: {
        ...mapActions('resources/exports', ['getExports']),
        downloadExport(exportId) {
            this.$store.dispatch('resources/exports/downloadExport', { export_id: exportId })
        },
        scheduleExport(exportId) {
            this.$store.dispatch('resources/exports/scheduleExport', { export_id: exportId })
        },
        scheduledExportGenerated(exportId) {
            var exportTask = this.dataExports.find(exportTask => exportTask.id == exportId)
            if (exportTask) {
                return exportTask.scheduled && this.generatedExports.find(e => e.export_id == exportId && e.user_id == this.$store.state.auth.user.id && e.status == 'scheduled') ||
                    exportTask.generated_exports.find(e => e.user_id == this.$store.state.auth.user.id && e.status == 'scheduled') != undefined
            }
        }
    },
    mounted() {
        this.getExports()
    }
}
</script>

<style lang="scss" scoped></style>