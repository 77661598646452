<template>
  <div>
    <nav v-if="inSequence"
      :class="['mb-2 navbar navbar-expand-lg rounded', $root.theme == 'light' ? 'navbar-light bg-light' : 'navbar-dark bg-dark']">
      <div class="d-flex w-100 justify-content-evenly">
        <template v-for="(item, key) in activeSequence">
          <btn outline is-router-link :to="{ path: item.route.path }" class="step" :key="key"
            v-t="'questionnaires.register.sequence.' + key + '.title'" active-class="active"
            :disabled="item.isLink == false || getIndex(key) > progressIndex"></btn>
        </template>
      </div>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getSequence } from '~/router/sequences'

export default {
  name: "RegistrationWrapper",
  computed: {
    ...mapGetters('auth', ['inSequence', 'user']),
    activeSequence() {
      if (this.inSequence) {
        return getSequence(this.inSequence)
      }
      return {}
    },
    progressIndex() {
      if (this.user.patient) {
        return this.getIndex(this.user.patient.status)
      }
      return 0
    }
  },
  methods: {
    getIndex(key) {
      return Object.keys(this.activeSequence).findIndex((k) => k == key)
    }
  }
}
</script>
<style lang="scss">
.navbar {
  .step {
    padding: 1rem;
    line-height: 0;
  }
}
</style>