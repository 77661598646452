export const sequences = {
    registration: {
      register: { isLink: false, route: { path: '/register', params: {} } },
    }
  }
  
  export function getSequence(name) {
    return sequences[name]
  }
  
  export function getNextKeyInSequence(sequence, current) {
    var keys = Object.keys(sequences[sequence])
    if (keys.includes(current)) {
      var index = keys.findIndex((k) => k==current)
      if (keys.length >= index + 1) {
          return keys[index + 1]
      }
    }
  }
  