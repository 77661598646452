<template>
    <questionnaire v-if="questionnaireLoaded" :title="$t(lp + '.title')" :body="false" :can-save="canSave">
        <div class="card-body">
            <p class="lead">
                {{ $t('questionnaires.omeract.intro') }}
            </p>
        </div>
        <list-group flush="">
            <template v-for="di in OmeractDis">
                <list-group-item :key="di.name">
                    <form-group :di="di" :error="errors[di.name]">
                        <slider :di="di" v-model="records[di.name]" :min="0" :max="10"
                            :labels="{ min: $t(lp + '.Slider1_min'), max: $t(lp + '.Slider1_max') }"></slider>
                    </form-group>
                </list-group-item>
            </template>
        </list-group>
    </questionnaire>
</template>

<script>
import questionnaireMixin from '~/mixins/questionnaire'
import { mapGetters } from 'vuex'
export default {
    name: 'omeract',
    mixins: [questionnaireMixin],
    computed: {
        ...mapGetters('auth', ['isParticipant']),
        OmeractDis() {
            if (this.dataitems)
                return this.dataitems.filter(di => di.name.startsWith('OME_'))
        }
    }
}
</script>

<style lang="scss" scoped></style>