export default {
    methods: {
      i18nDate(dateStr,format='numeric',locale='en-GB'){
        return this.$d(new Date(dateStr),format,locale)
      },
      isDate(date) {
        var dateSplit = date.split('-');
        if (dateSplit[2] == '00') {
          dateSplit[2] = '01';
          date = dateSplit.join('-');
        }
        return moment(date).isValid();
      },
      formatDateForStorage: function(date) {
        if (this.noMaskInDate(date) && date && date.length == 10 && date[2] == '/') {
          var newDate = date.split('/')
          newDate[0] = newDate[0].replace('--', '00')
          newDate[1] = newDate[1].replace('--', '00')
          newDate = newDate.reverse().join('-')
          return newDate;
        }
        if (date == "") return null;
        return date;
      },
      formatDateForDisplay: function(date) {
        if (date == "0000-00-00") {
          return null;
        } else if (date && date.length == 10 && date[4] == '-' && this.noMaskInDate(date)) {
          var newDate = date.split('-').reverse();
          newDate[0] = newDate[0].replace('00', '--');
          newDate[1] = newDate[1].replace('00', '--');
          newDate = newDate.join('/');
          return newDate;
        }
        return date;
      },
      noMaskInDate: function(date) {
        for (var i = 0; i < date.length; i++) {
          if (date[i] == "d" || date[i] == "m" || date[i] == "y") {
            return false;
          }
        }
        return true;
      },
      validIncomplete(date) {
        var hasYear = date.match("y") == null;
        var hasMonth = date.match("m") == null;
        var hasDay = date.match("d") == null;
        if (hasYear) {
          var newDate = date.split('/');
          if (!hasMonth) newDate[1] = "--";
          if (!hasDay) newDate[0] = "--";
          date = newDate.join('/');
        }
        return date;
      },
      timeFromNow(date) {
        var now = moment();
        var date = moment(date);
        return date.from(now);
      }
    }
  }
  