<template>
  <questionnaire v-if="questionnaireIsLoaded" :title="$t('questionnaires.monitoring_signs_findings.title')"
    :collapsed="collapsed" :can-save="isParticipant">
    <h5 v-html="$t(lp + '.STPhysicalFindings')" class="mt-1 mb-4"></h5>
    <template v-for="di in groupedDataitems('STPF')">
      <form-group :di="di" :error="errors[di.name]" class="mb-4 ms-5" :key="di.name">
        <btn-group outline justified :di="di" v-model="records[di.name]"></btn-group>
      </form-group>
    </template>
    <h5 v-html="$t(lp + '.STBurdenOfToxicity')" class="mt-5 mb-4"></h5>
    <template v-for="di in groupedDataitems('STBT')">
      <form-group :di="di" :error="errors[di.name]" class="mb-4 ms-5" :key="di.name">
        <btn-group outline justified :di="di" v-model="records[di.name]"></btn-group>
      </form-group>
    </template>
    </form-group>
  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
import { mapGetters } from 'vuex'
export default {
  name: 'monitoring_signs_findings',
  mixins: [questionnaireMixin],

  computed: {
    ...mapGetters('auth', ['isParticipant']),

  },
  mounted() {

  },
  data() {

  },
  methods: {
    groupedDataitems(groupname) {
      if (this.dataitems) {
        var result = {}
        var keys = Object.keys(this.dataitems).forEach((k) => {
          var di = this.dataitems[k]
          if (di.json && di.json.group && di.json.group == groupname) result[di.name] = di
        })
        return result
      }
    },
  }
}

</script>
