<template>
  <questionnaire v-if="questionnaireLoaded" :title="$t(lp + '.title')" :can-save="canSave">
    <h5 v-t="lp + '.intro'" class="mb-4"></h5>
    <template v-for="i in 9">
      <form-group :di="dataitems['PHQ_9_' + i]" :error="errors['PHQ_9_' + i]" class="mb-4" v-slot="{ di }">
        <btn-group outline justified :di="di" v-model="records['PHQ_9_' + i]"></btn-group>
      </form-group>
    </template>
    <hr>
    <form-group :di="dataitems.PHQ_9_DifficultyScale" :error="errors.PHQ_9_DifficultyScale" class="mb-4"
      v-slot="{ di }">
      <btn-group outline justified :di="di" v-model="records.PHQ_9_DifficultyScale"></btn-group>
    </form-group>

  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
export default {
  name: 'phq9',
  mixins: [questionnaireMixin],
  computed: {

  }
}

</script>
