<template>
  <card>
    <fa :icon="['fas', 'spinner']" pulse size="3x" class="text-primary" />
  </card>
</template>

<script>
// https://github.com/nuxt/nuxt.js/blob/master/lib/app/components/nuxt-loading.vue
export default {
  data: () => ({

  }),

  methods: {

  }
}
</script>

