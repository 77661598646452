<template>
  <questionnaire v-if="questionnaireLoaded" :title="$t('questionnaires.mental_health_support.title')"
    :collapsed="collapsed" :can-save="isParticipant">
    <template v-for="(di, diname, n) in groupedDataitems('PD_MHS')">
      <form-group :di="di" :error="errors[di.name]" :key="di.name" label-class="fs-6">
        <slider v-model="records[di.name]"
          :labels="{ min: $t(lp + '.Slider' + (n + 1) + '_min'), max: $t(lp + '.Slider' + (n + 1) + '_max') }">
        </slider>
        <hr class="mb-4">
      </form-group>

    </template>
    <div class="form-label" v-t="lp + '.RelevantServices'"></div>
    <form-group class="mb-5 ">
      <list-group outline vertical justified>
        <template v-for="di in groupedDataitems('PD_Relevance')">
          <list-group-item is-link v-model="records[di.name]" :key="di.name + 'cb'">
            <div v-html="di.label"></div>
          </list-group-item>
          <form-group v-if="di.name == 'PD_SelfHelpOther' && records[di.name]" :di="dataitems[di.name + 'Specify']"
            :key="di.name + 'SpecifyOther'" :error="errors[di.name + 'Specify']" cols="3|9" class="my-3"
            @click.stop.prevent>
            <textbox v-model="records[di.name + 'Specify']"></textbox>
          </form-group>
        </template>
      </list-group>
    </form-group>
  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
import { mapGetters } from 'vuex'

export default {
  name: 'mental_health_support',
  mixins: [questionnaireMixin],

  computed: {
    ...mapGetters('auth', ['isParticipant']),

  },
  mounted() {

  },
  data() {

  },
  methods: {
    groupedDataitems(groupname) {
      if (this.dataitems) {
        var result = {}
        var keys = Object.keys(this.dataitems).forEach((k) => {
          var di = this.dataitems[k]
          if (di.json && di.json.group && di.json.group == groupname) result[di.name] = di
        })
        return result
      }
    },
  }
}

</script>
