import axios from 'axios'
import Vue from 'vue'
import store from '~/store'
// state
export const state = {
  participants: [],
  participant: {},
  participant_issue: {},
  pagination: {},
  loadingParticipants: false,
  loadedParticipants: false,
  loadingParticipant: false,
  loadedParticipant: false
}

// getters
export const getters = {}

// mutations
export const mutations = {
  GET_PARTICIPANTS(state, resp) {
    state.participants = resp.data.data
    state.pagination = resp.data.meta
    state.loadingParticipants = false
    state.loadedParticipants = true
  },
  GET_PARTICIPANT_OVERVIEW(state, resp) {
    state.participant = resp.data.participant
    state.loadingParticipant = false
    state.loadedParticipant = true
  },
  UPDATE_PARTICIPANT(state, data) {
    state.participant = data.participant
  },
  GET_PARTICIPANT_STAGES(state, resp) {
    state.participant.stages = resp.stages
  },
  SAVE_PARTICIPANT_ISSUE_ENTRY_DATE() {
    state.participant_issue
  },
  ASSIGN_RESEARCHER(state, resp) {
    console.log(resp)
    state.participant.researchersAssigned = [];
    state.participant.researchersAssigned = resp.participant.researchersAssigned;

    Vue.set(state.participant, 'researchersAssigned', resp.participant.researchersAssigned)
  }
}

// actions
export const actions = {
  getParticipants({ commit }, request) {
    state.loadingParticipants = true
    state.loadedParticipants = false
    axios.get('participants', request)
      .then((resp) => {
        commit('GET_PARTICIPANTS', resp)
      })
  },
  getParticipantOverview({ commit }, request) {
    state.loadingParticipant = true
    state.loadedParticipant = false
    axios.get('participants/' + request.params.participant_id, request)
      .then((resp) => {
        commit('GET_PARTICIPANT_OVERVIEW', resp)
      })
  },
  getParticipant({ commit }, request) {
    state.loadingParticipant = true
    state.loadedParticipant = false
    axios.get('participant/my', request)
      .then((resp) => {
        commit('GET_PARTICIPANT_OVERVIEW', resp)
      })
  },
  updateParticipant({ commit }, participant) {
    commit('UPDATE_PARTICIPANT', { participant })
  },
  getParticipantIssues({ commit }, request) {
    axios.get('participants/' + request.params.participant_id + '/issue', request)
      .then((resp) => {
        commit('GET_PARTICIPANT_ISSUES', resp.data)
      })
  },
  assignParticipantIssue({ commit }, request) {
    axios.post('participant/' + request.params.participant_id + '/issue', request)
      .then((resp) => {
        commit('GET_PARTICIPANT_ISSUES', resp.data)
      })
  },
  getParticipantIssue({ commit }, request) {
    var isTeam = store.getters['auth/isTeam'];
    var url = "";
    if (isTeam) {
      url = 'participants/' + request.params.participant_id + '/issue/' + request.params.issue;
    } else {
      url = 'participant/issue/';
    }
    axios.get(url, { params: request.params })
      .then((resp) => {
        commit('GET_PARTICIPANT_ISSUE', resp.data)
      })
  },
  updateParticipantStage({ commit, dispatch }, request) {
    console.log('updateParticipantStage', request)
    axios.patch('participant/' + request.participant_id + '/stage', request.params)

  },
  recordVideoStats({ commit }, request) {
    axios.post('participant/video-stats', request.params)
  },
  assignResearchers({ commit }, request) {
    axios.post('participant/' + request.participant_id + '/assign-researcher', request.params)
      .then((resp) => {
        commit('ASSIGN_RESEARCHER', resp.data)
      })

  }
}

export const namespaced = true;
