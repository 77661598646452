<template>
  <row>
    <column :sizes="{md:3}">
      <card head :body="false" :title="$t('settings.title')" class="settings-card mb-2">
        <list-group flush class="rounded-bottom">
          <list-group-item v-for="tab in tabs" :key="tab.route" is-router-link :to="{ name: tab.route }" :router-link-active="tab.route == $route.name" class="d-flex">
            <div>
              <fa :icon="tab.icon" fixed-width />
              <span>{{ tab.name }}</span>
            </div>
          </list-group-item>
        </list-group>
      </card>
    </column>
    <column :sizes="{md:9}">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </column>
  </row>
</template>
<script>
export default {
  middleware: 'auth',

  computed: {
    tabs() {
      return [{
          icon: 'user',
          name: this.$t('settings.account'),
          route: 'settings.account'
        },
        {
          icon: 'lock',
          name: this.$t('settings.password'),
          route: 'settings.password'
        }
      ]
    }
  }
}

</script>
