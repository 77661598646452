<template>
  <questionnaire v-if="questionnaireLoaded" :title="$t('questionnaires.relative_importance.title')" :collapsed="collapsed"
    :can-save="isParticipant">
    <h5 v-html="$t(lp + '.ImportanceScale')" class="mb-4"></h5>
    <template v-for="di in groupedDataitems('STMP')">
      <form-group :di="di" :error="errors[di.name]" :key="di.name" label-class="fs-5" class="ms-5">
        <slider v-model="records[di.name]" :labels="{ min: $t(lp + '.Slider1_min'), max: $t(lp + '.Slider1_max') }">
        </slider>
        <hr class="mb-4">
      </form-group>
    </template>
    <form-group :di="dataitems.STA_Importance" :error="errors.STA_Importance" class="mb-5" v-slot="{ di }">
      <row class="draggable-list-container">
        <column>
          <draggable group="people" @start="drag = true" @end="drag = false" v-model="unsorted" tag="ol"
            class="draggable-list list-group h-100">
            <list-group-item v-for="(val, index) in unsorted" :key="val" class="d-flex">{{ getItem(val,
              dataitems.STA_Importance.lookup.items).label
            }}
            </list-group-item>
          </draggable>
        </column>
        <column>
          <div>
            <div class="fs-5">{{ $t(lp + '.Slider1_max') }}</div>
            <draggable v-model="records.STA_Importance" group="people" tag="ol" v-bind="dragOptions"
              class="draggable-list list-group h-100">
              <list-group-item v-for="(val, index) in records.STA_Importance" :key="val" class="d-flex">
                <badge class="me-3">{{ index + 1 }}</badge>{{ getItem(val,
                  dataitems.STA_Importance.lookup.items).label
                }}
              </list-group-item>
            </draggable>
            <div class="fs-5">{{ $t(lp + '.Slider1_min') }}</div>
          </div>

        </column>
      </row>

    </form-group>
  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
export default {
  name: 'relative_importance',
  mixins: [questionnaireMixin],
  components: {
    draggable
  },
  computed: {
    ...mapGetters('auth', ['isParticipant']),

    dragOptions() {
      return {
        animation: 0,
        group: "people",
        disabled: false,
        ghostClass: "ghost"
      };
    },
  },
  mounted() {
    this.setupSortable()
  },
  data() {
    return {
      drag: false,
      unsorted: []
    }
  },
  methods: {
    setupSortable() {
      console.log('setupSortable', this.dataitems.STA_Importance)
      if (this.dataitems && this.dataitems.STA_Importance) {
        var clone = [...this.dataitems.STA_Importance.lookup.items]
        var existing = this.records.STA_Importance
        this.unsorted = clone.filter((i) => {
          return !existing.includes(i.value)
        }).map((i) => i.value)
        console.log(this.unsorted)
      }
    },
    groupedDataitems(groupname) {
      if (this.dataitems) {
        var result = {}
        var keys = Object.keys(this.dataitems).forEach((k) => {
          var di = this.dataitems[k]
          if (di.json && di.json.group && di.json.group == groupname) result[di.name] = di
        })
        return result
      }
    },
    getItem(val, items) {
      var found = items.find((i) => i.value == val)
      return items.find((i) => {
        return i.value == val.toString()
      })
    }
  },
  watch: {
    dataitems() {
      this.setupSortable()
    }
  }
}

</script>
