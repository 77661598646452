<template>
  <div>
    <!-- <patient-breadcrumbs></patient-breadcrumbs> -->
    <row>
      <column :sizes="{md: 3}" v-if="showIssueNav">
        <patient-issue-nav></patient-issue-nav>
      </column>
      <column :sizes="{md:showIssueNav ? 9:12}">
        <transition name="page" mode="out-in">
          <router-view v-if="questionnaireLoaded" />
          <card v-else >
          <div class="d-flex">
            <fa icon="cog" size="2x" spin class="m-auto"/>
          </div>
        </card>
        </transition>
      </column>
    </row>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: "Questionnaire-wrapper",
  middleware: ['auth'],
  computed: {
    ...mapState('resources/questionnaires', ['routeChanging', 'questionnaireLoaded', 'questionnaireSaving']),
    showIssueNav(){
      return this.$route.meta && typeof this.$route.meta.type == 'string' && this.$route.meta.type.toLowerCase() == "scheduled";
    }
  }
}

</script>
