<template>
  <div>
    <card head :title="$t('admin.participants.title')" class="mb-4" :body="false" foot>
      <data-table :data="participants" :columns="participantColumns" hide-index class="participants-datatable">
        <template #header-column="{ col }">
          <div v-if="col.sortable" @click="setSorting(col.name)"
            :class="['dt-column dt-column-header dt-column-' + col.name, sortingClass(col)]">
            <div class="p-2" v-text="col.label" />
          </div>
          <div v-else :class="['dt-column dt-column-header dt-column-' + col.name + '']">
            <div class="p-2" v-text="col.label" />
          </div>
        </template>
        <template #cell-code="{ rowData }">
          <scope :code="getCode(rowData.code)" class="p-2" v-slot="{ code }" style="font-size:.8rem">
            <span class="small">{{ code[0] }}</span>-<span>{{ code[1] }}</span>-<span style="font-size:.9rem">{{ code[2]
              }}</span>
          </scope>
        </template>
        <template #cell-participant_type="{ rowData }">
          <div class="p-1 d-flex align-items-center"
            :style="{ color: rowData.participant_type == 1 ? 'var(--bs-primary-text-emphasis)' : 'var(--bs-secondary-text-emphasis)' }">
            <fa :icon="rowData.participant_type == 1 ? 'user-md' : 'user'" class="mx-1" />
            {{ rowData.participantType }}
          </div>
        </template>
        <template #cell-name="{ rowData }">
          <div class="p-2" v-text="rowData.user ? rowData.user.first_name + ' ' + rowData.user.last_name : ''" />
        </template>
        <template #cell-email="{ rowData }">
          <div class="p-2">
            {{ rowData.user ? rowData.user.email : '' }}
          </div>
        </template>

        <template #cell-researcher="{ rowData }">
          <div class="m-auto">
            <template v-for="ra in rowData.researchersAssigned">
              <img :src="ra.user.photo_url" :title="ra.user.first_name + ' ' + ra.user.last_name" :key="ra.id"
                class="rounded-circle profile-photo" />
              <small>
                <badge size="sm" :key="'badge' + ra.id" class="ms-n2">{{ initials(ra.user) }}</badge>
              </small>
            </template>
          </div>
        </template>
        <template #cell-eligibility="{ rowData }">
          <div class="m-auto">
            <btn size="sm" outline is-router-link :to="eligibilityRoute(rowData)" class="m-auto p-1">
              <fa :icon="rowData.eligible ? 'check' : 'times'" />
            </btn>
          </div>
        </template>
        <template #cell-consent="{ rowData }">
          <div class="d-flex p-2 align-items-center">
            <span class="me-2" v-if="rowData.consentDate && rowData.isConsented">
              {{ $d(new Date(rowData.consentDate), 'numeric', 'en-GB') }}
            </span>
            <div class="m-auto position-relative">
              <small :key="rowData.id + '-RegIncompleteNotificationBadge'">
                <badge v-if="rowData.registrationRemindersSent" pill class="notification-badge  ms-n2">
                  <fa icon="envelope" class="me-1" />
                  {{ rowData.registrationRemindersSent }}
                </badge>
              </small>
              <btn size="sm" outline :theme="rowData.isConsented ? 'primary' : 'danger'" is-router-link
                :to="consentRoute(rowData)" class="m-auto p-1">
                <fa :icon="rowData.isConsented ? 'check' : 'times'" fixed-width />
              </btn>
            </div>

          </div>
        </template>
        <template #cell-screening="{ rowData }">
          <div class="d-flex m-auto p-1 align-items-center">
            <btn size="sm" outline is-router-link :to="screeningRoute(rowData)" class="m-auto p-1">
              <fa icon="check" v-if="rowData.screening" />
            </btn>
          </div>
        </template>
        <template #cell-pre_interview="{ rowData }">
          <div class="d-flex m-auto p-1 align-items-center">
            <btn size="sm" outline is-router-link
              :to="{ name: 'admin.participant_overview', params: { participant_id: rowData.id } }"
              class="m-auto p-0 border-0">
              <donut-progress v-if="rowData.stages[1] && rowData.stages[1]" :id="rowData.id + 'donut'"
                :label="percentage_complete(rowData.stages[1])" :chart-data="chartData(rowData.stages[1])" :width="36"
                :height="36"
                :background-colour="percentage_complete(rowData.stages[1]) == 100 ? 'var(--bs-success)' : 'var(--bs-gray-200)'" />
            </btn>
          </div>
        </template>

        <template #cell-demographics1_b="{ rowData }">
          <div class="d-flex p-1 align-items-center">
            <btn outline is-router-link :to="{ name: 'admin.demographics1_b', params: { participant_id: rowData.id } }"
              class="m-auto">
              <fa icon="check" />
            </btn>
          </div>
        </template>
        <template #cell-clinical_info="{ rowData }">
          <div class="d-flex p-1 align-items-center">
            <btn outline is-router-link :to="{ name: 'admin.clinical_info', params: { participant_id: rowData.id } }"
              class="m-auto">
              <fa icon="check" />
            </btn>
          </div>
        </template>
        <template #cell-view="{ rowData }">
          <div class="w-100 p-1">
            <btn outline block size="sm" :key="'participant_overview-btn-' + rowData.id" is-router-link
              :to="{ name: 'admin.participant_overview', params: { participant_id: rowData.id } }" class="m-auto p-1">
              <fa icon="play" />
            </btn>
          </div>
        </template>
      </data-table>
      <template #footer-inner>
        <div class="d-flex">
          <ul class="pagination m-auto">
            <li v-for="(link, n) in pagination.links" :key="'pagination' + n"
              :class="['page-item', { active: link.active, disabled: !link.url }]">
              <a @click="updatePagination(link, pagination)"
                :class="['page-link', { 'bg-dark text-white': $root.theme == 'dark' && !link.active }]"
                v-html="link.label" />
            </li>
          </ul>
          <dropdown v-model="pagination.per_page" :items="paginationPerPageList" class="m-auto">
            <template #btn-inner="{ chosenLabel }">{{ chosenLabel }} Per Page</template>
          </dropdown>
        </div>
      </template>
    </card>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import paginationMixin from '~/mixins/pagination'
import DonutProgress from "../../components/donut-progress.vue";
export default {
  name: "participants-datatable",
  components: {
    DonutProgress
  },
  metaInfo() {
    return { title: this.$t('admin.participants.title') }
  },
  middleware: ['auth', 'role:Researcher,CentralTeam,CountryTeam,Admin'],
  mixins: [paginationMixin],
  apiMethod: 'getParticipants',
  data() {
    return {
      params: {
        include: 'user,stages',
        sort: 'id',
        append: 'screening'
      }
    }
  },
  mounted() {
    var params = this.params
    this.makeRequest({}, this.pagination);
  },
  methods: {
    ...mapActions("resources/participant", ["getParticipants"]),
    participantType(participant) {
      return participant.participantType.toLowerCase()
    },
    eligibilityRoute(participant) {
      return { name: 'admin.' + this.participantType(participant) + '.eligibility', params: { participant_id: participant.id } }
    },
    consentRoute(participant) {
      return { name: 'admin.' + this.participantType(participant) + '.consent', params: { participant_id: participant.id } }
    },

    screeningRoute(participant) {
      var participant_type_alpha = participant.participant_type == 1 ? 'a' : 'b'
      return { name: 'admin.' + this.participantType(participant) + '.screening1_' + participant_type_alpha, params: { participant_id: participant.id } }
    },
    percentage_complete(stage) {
      var total = stage.questionnaires.length;
      var valid = Object.values(stage.records).filter((r) => r.valid == 1).length;
      return Math.round((valid / total) * 100);
    },
    chartData(stage) {
      var validQs = Object.values(stage.records).filter((r) => r.valid == 1);
      var invalidQs = Object.values(stage.records).filter((r) => r.valid == 0);
      var total = stage.questionnaires.length;
      return [
        { val: validQs.length },
        { val: invalidQs.length },
        { val: total - validQs.length - invalidQs.length }
      ]
    },
    initials(user) {
      return user.first_name[0] + user.last_name[0]
    },
    getCode(code) {
      return [
        code.substr(0, 5),
        code.substr(6, 2),
        code.substr(9, 4),
      ]

    }
  },
  computed: {
    ...mapState("resources/participant", ["participants", "pagination"]),
    participantColumns() {
      return [
        { name: 'id', label: 'ID', Type: Number, sortable: true, width: 'min-content' },
        { name: 'code', label: 'Code', Type: Number, sortable: true, width: 'max-content' },
        { name: 'participant_type', label: 'Type', Type: String, sortable: true, width: 'max-content' },
        { name: 'name', label: 'Name', Type: String, width: 'max-content' },
        { name: 'email', label: 'Email', Type: String, width: '1fr' },
        { name: 'researcher', label: 'Researcher', Type: String, width: 'max-content' },
        { name: 'eligibility', label: 'Eligibility', Type: String, width: 'min-content' },
        { name: 'consent', label: 'Consent', Type: Number, width: 'max-content' },
        { name: 'screening', label: 'Screening', Type: Number, width: 'max-content' },
        { name: 'pre_interview', label: 'Pre Interview', Type: Number, width: 'max-content' },
        { name: 'view', label: 'View', Type: null, width: 'min-content' },
      ]
    },
  }
}

</script>
<style>
.participants-datatable .dt-column {
  display: flex;
  align-items: center;
}
</style>