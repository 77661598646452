<template>
  <questionnaire v-if="questionnaireLoaded" :title="$t(lp + '.title')" :can-save="canSave">
    <h4 v-t="lp + '.intro'" class="mb-4"></h4>
    <h5 v-t="lp + '.intro2'" class="my-4"></h5>
    <hr>

    <form-group :di="dataitems.PS_1" :error="errors.PS_1" class="mb-4" v-slot="{ di }">
      <btn-group outline justified :di="di" v-model="records.PS_1"></btn-group>
    </form-group>
    <hr>
    <collapse :show="records.PS_1 == 1">
      <form-group :di="dataitems.PS_2" :error="errors.PS_2" class="mb-4" v-slot="{ di }">
        <btn-group outline justified :di="di" v-model="records.PS_2"></btn-group>
      </form-group>
      <hr>
    </collapse>
    <form-group :di="dataitems.PS_3" :error="errors.PS_3" class="mb-4" v-slot="{ di }">
      <btn-group outline justified :di="di" v-model="records.PS_3"></btn-group>
    </form-group>
    <hr>
    <form-group :di="dataitems.PS_4" :error="errors.PS_4" class="mb-4" v-slot="{ di }">
      <btn-group outline justified :di="di" v-model="records.PS_4"></btn-group>
    </form-group>
    <hr>
    <form-group :di="dataitems.PS_5" :error="errors.PS_5" class="mb-4" v-slot="{ di }">
      <btn-group outline justified :di="di" v-model="records.PS_5"></btn-group>
    </form-group>
    <hr>
    <form-group :di="dataitems.PS_6" :error="errors.PS_6" class="mb-4" v-slot="{ di }">
      <btn-group outline justified :di="di" v-model="records.PS_6"></btn-group>

    </form-group>
    <hr>
    <h5 v-t="lp + '.PS_7'" class="my-4"></h5>
    <list-group justified class="ms-5">

      <template v-for="i in 10">
        <list-group-item :di="dataitems['PS_7_' + i]" :error="errors['PS_7_' + i]" v-model="records['PS_7_' + i]"
          :key="dataitems['PS_7_' + i].name" @click.native.stop="specialCaseClick('PS_7_' + i)">
          <span>{{ dataitems['PS_7_' + i].label }}</span>
          <collapse v-if="i == 9" :show="records['PS_7_' + i] == 1">
            <form-group :di="dataitems.PS_7_9Txt" :error="errors.PS_7_9Txt" v-slot="{ di }" cols="4|8" class="mb-4">
              <textbox v-model="records[di.name]" :di="di"></textbox>
            </form-group>
          </collapse>
        </list-group-item>
      </template>
    </list-group>

  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
export default {
  name: 'psychological_support',
  mixins: [questionnaireMixin],
  computed: {

  },
  methods: {
    specialCaseClick(fieldName) {
      if (fieldName == 'PS_7_10') {
        // clear all others
        for (var i = 1; i < 10; i++) {
          this.records['PS_7_' + i] = null
        }
      } else {
        this.records.PS_7_10 = null
      }
    }
  }
}

</script>
