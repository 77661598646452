<template>
    <card :body="false" head :title="collection.title || collection.name" foot>
        <template #header-right>
            <donut-progress v-if="collection.records" :label="percentage_complete(collection.records)"
                :chart-data="chartData(collection.records)" />
        </template>
        <div class="row g-0">
            <div v-if="collection.questionnaires.length > 0" class="col-md-3">
                <list-group flush class="border-end questionnaire-collection-nav ">
                    <router-link v-for="questionnaire in collection.questionnaires" :key="questionnaire.id"
                        :to="questionnaireRoute(questionnaire)" active-class="questionnaire-active"
                        :true-value="questionnaire.name" v-model="activeQuestionnaire"
                        :class="['questionnaire-link', listGroupItemClass(questionnaire, collection.records)]">
                        <span class="questionnaire-title" v-t="'questionnaires.' + questionnaire.name + '.title'">
                        </span>
                    </router-link>
                </list-group>
            </div>
            <div class="col-md-9 p-2">
                <transition name="fade">
                    <slot :activeQuestionnaire="activeQuestionnaire">
                        {{ activeQuestionnaire }}
                    </slot>
                </transition>
            </div>
        </div>
        <template #footer-left>
            <slot name="footer-left"></slot>
        </template>
    </card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DonutProgress from "./donut-progress.vue";
export default {
    props: {
        collection: {
            type: Object,
            required: true
        },
        participantId: {
            type: Number,
            required: true
        }
    },
    mounted() {
        if (this.$route.params.questionnaire_name) {
            this.activeQuestionnaire = this.$route.params.questionnaire_name;
        }
    },
    data() {
        return {
            activeQuestionnaire: this.$route.params.questionnaire_name || "",
        };
    },
    computed: {
        ...mapGetters("auth", ["isTeam", "isParticipant", "isResearcher"]),
        // ...mapGetters('resources/questionnaires', ['questionnaireLoaded']),
    },
    methods: {
        questionnaireRoute(questionnaire) {
            var routeName = this.isTeam || this.isResearcher ? "admin.participant_overview.questionnaire" : "home.questionnaire";
            return { name: routeName, params: { participant_id: this.participantId, questionnaire_name: questionnaire.name } };
        },
        listGroupItemClass(questionnaire, records) {
            return [
                'list-group-item link-group-item-action',
                {
                    'questionnaire-valid': records[questionnaire.name] && records[questionnaire.name].valid == 1,
                    'questionnaire-invalid': records[questionnaire.name] && records[questionnaire.name].valid == 0
                }
            ];
        },
        percentage_complete(records) {
            var total = this.collection.questionnaires.length;
            var valid = Object.values(records).filter((r) => r.valid == 1).length;
            return Math.round((valid / total) * 100);
        },
        chartData(records) {
            var validQs = Object.values(records).filter((r) => r.valid == 1);
            var invalidQs = Object.values(records).filter((r) => r.valid == 0);
            var total = this.collection.questionnaires.length;

            return [
                { val: validQs.length },
                { val: invalidQs.length },
                { val: total - validQs.length - invalidQs.length }
            ]
        }
    },
    watch: {
        $route(to, from) {
            if (to.params.questionnaire_name)
                this.activeQuestionnaire = to.params.questionnaire_name;
            else
                this.activeQuestionnaire = "";
        }
    },
    components: { DonutProgress }
}
</script>

<style lang="scss" scoped></style>