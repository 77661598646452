<template>
  <questionnaire v-if="questionnaireIsLoaded" :title="$t('questionnaires.willingness_discomfort.title')"
    :can-save="isParticipant">

    <h5 v-html="$t('questionnaires.willingness_discomfort.WillingnessScale')" class="mb-4"></h5>
    <template v-for="di in groupedDataitems('STA')">
      <form-group :di="di" :error="errors[di.name]" :key="di.name" label-class="fs-6">
        <slider v-model="records[di.name]" :labels="{ min: $t(lp + '.Slider2_min'), max: $t(lp + '.Slider2_max') }">
        </slider>
        <hr class="mb-4">
      </form-group>
    </template>

    <template v-for="di in groupedDataitems('PD_Disc')">
      <form-group :di="di" :error="errors[di.name]" :key="di.name" label-class="fs-6">
        <slider v-model="records[di.name]" :labels="{ min: $t(lp + '.Slider3_min'), max: $t(lp + '.Slider3_max') }">
        </slider>
        <hr class="mb-4">
      </form-group>
    </template>

  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
import { mapGetters } from 'vuex'

export default {
  name: 'willingness_discomfort',

  mixins: [questionnaireMixin],

  computed: {
    ...mapGetters('auth', ['isParticipant']),

  },
  mounted() {

  },
  data() {

  },
  methods: {
    groupedDataitems(groupname) {
      if (this.dataitems) {
        var result = {}
        var keys = Object.keys(this.dataitems).forEach((k) => {
          var di = this.dataitems[k]
          if (di.json && di.json.group && di.json.group == groupname) result[di.name] = di
        })
        return result
      }
    },
  }
}

</script>
