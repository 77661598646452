<template>
  <questionnaire :title="$t(lp+'.title')" v-bind="$attrs" v-if="questionnaireLoaded">
    <card head :title="$t(lp+'.inclusion_criteria')" class="mb-3" :body="false">
      <list-group flush>
        <list-group-item v-for="item in inclusion_criteria" :key="item.name">
          <form-group :di="item" :error="errors[item.name]" :cols="[{sm:10},{sm:2}]">
            <template #label="{di}">
              <span v-html="di.label"></span>
            </template>
            <template #default="{di}">
              <btn-group outline :di="item" v-model="records[item.name]" :vertical="$mq | mq({ sm: true, md: true, lg: false })" justified>
                <template #btn="{btn}">
                  <btn  v-model="records[item.name]" :true-value="btn.value" :false-value="null" outline>
                    <fa :icon="btn.value==1 ? 'check':'times'" class="me-2"/>
                    {{btn.label}}
                  </btn>
                </template>
              </btn-group>
            </template>
          </form-group>
        </list-group-item>
      </list-group>
    </card>
    <card head :title="$t(lp+'.exclusion_criteria')" :body="false">
      <list-group flush>
        <list-group-item v-for="item in exclusion_criteria" :key="item.name">
          <form-group :di="item" v-slot="{di}" :error="errors[item.name]" :cols="[{sm:10},{sm:2}]">
            <btn-group outline :di="item" v-model="records[item.name]" :vertical="$mq | mq({ sm: true, md: true, lg: false })" justified>
              <template #btn="{btn}">
                <btn v-model="records[item.name]" :true-value="btn.value"  :false-value="null" outline>
                  <fa :icon="btn.value==2 ? 'check':'times'" class="me-2"/>
                  {{btn.label}}
                </btn>
              </template>
            </btn-group>
          </form-group>
        </list-group-item>
      </list-group>
    </card>
  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
export default {
  mixins: [questionnaireMixin],
  computed: {
    inclusion_criteria() {
      var group = []
      if (this.questionnaireLoaded&&this.dataitems) {
        Object.keys(this.dataitems).forEach((diName) => {
          var di = this.dataitems[diName]
          if (di.json && di.json.group) {
            if (di.json.group == 'inclusion') group.push(di)
          }
        })
      }
      return group
    },
    exclusion_criteria() {
      var group = []
      if (this.questionnaireLoaded&&this.dataitems) {
        Object.keys(this.dataitems).forEach((diName) => {
          var di = this.dataitems[diName]
          if (di.json && di.json.group) {
            if (di.json.group == 'exclusion') group.push(di)
          }
        })
      }
      return group
    }
  }
}

</script>
