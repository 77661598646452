<template>
  <div class="homepage pb-4">

    <nav-bar tabs :links="navTabs" :current="currentTab" @change="changeTab" class="mx-n3">
      <template #nav-item="{ link, linkKey }">
        <div :class="['nav-item nav-link', { active: currentTab == link.name }]" @click="changeTab(link.name)"> {{
          link.name }}</div>
      </template>
    </nav-bar>
    <div class="tab content mt-4">
      <template v-if="currentTab == 'Enrolment'">


        <card head :body="false" foot :title="$t('questionnaires.eligibility.title')" class="mb-2"
          v-if="loadedQuestionnaires">
          <template #header-right>
            <button type="button" class="btn btn-link" @click="showEligibility = !showEligibility">
              <fa icon="chevron-right" :class="[{ 'fa-rotate-270': showEligibility, 'fa-rotate-90': !showEligibility }]"
                style="transition:all .31s ease;" />
            </button>
          </template>
          <collapse :show="showEligibility">
            <div class="card-body">
              <template v-for="item in eligibilityDataitems">
                <form-group :di="item" :key="item.name" v-slot="{ di }" hide-label>
                  <row>
                    <column :sizes="{ md: 9 }" class="align-self-center">
                      <label class="control-label" v-html="di.label"></label>
                      <i18n :path="'questionnaires.eligibility.dataitems.' + di.name + '.info'" tag="div"
                        class="mt-2 form-text text-muted">
                        <template #mail>
                          <a :href="'mailto:' + $t('participant.home.vasup_email')">{{
                            $t('participant.home.vasup_email') }}</a>
                        </template>
                      </i18n>
                    </column>
                    <column :sizes="{ md: 3 }" class="align-self-center">
                      <btn-group outline justified v-model="eligibilityQ.records[di.name]" readonly :di="di"
                        :class="['btn-group-toggle']" :name="di.name" class="my-3">
                        <!-- <template #btn-prepend>
                        <fa icon="check" class="me-2" />
                      </template> -->
                      </btn-group>
                    </column>
                  </row>
                </form-group>
              </template>
            </div>
          </collapse>
        </card>
        <card head foot :body="false" :title="$t('questionnaires.consent.title')" class="mb-2">
          <template #header-right>
            <button type="button" class="btn btn-link" @click="showConsent = !showConsent">
              <fa icon="chevron-right" :class="[{ 'fa-rotate-270': showConsent, 'fa-rotate-90': !showConsent }]"
                style="transition:all .31s ease;" />
            </button>
          </template>
          <collapse :show="showConsent">
            <list-group flush>
              <template v-for="item in consentDataitems">
                <list-group-item :key="item.name">
                  <form-group :di="item" v-slot="{ di }" hide-label>
                    <row>
                      <column :sizes="{ md: 10 }" class="align-self-center">
                        <i18n :path="'questionnaires.consent.dataitems.' + di.name + '.label'" tag="label" for="tos"
                          :class="['text-justify', {}]" style="text-align: justify; text-justify: inter-word;">
                          <template #information_sheet>
                            <a :href="country.pis_path" target="_blank"
                              v-t="'questionnaires.consent.dataitems.' + di.name + '.linkLabel'" />
                          </template>

                        </i18n>
                      </column>
                      <column :sizes="{ md: 2 }"
                        v-if="participant.country_id == 1 && di.lookup.name == 'AgreeDisagree'">
                        <row class="d-flex align-items-center">
                          <column>
                            <div class="d-flex align-items-center">
                              <div class="m-auto">
                                <button type="button" class="btn p-1">
                                  <fa icon="check" size="2x"
                                    :class="[{ 'text-success': consentQ.records.consentForms[0][di.name] == 1 }]"></fa>
                                </button>
                                <button type="button" class="btn p-1">
                                  <fa icon="times" size="2x"
                                    :class="['m-2', { 'text-danger': consentQ.records.consentForms[0][di.name] == 2 }]">
                                  </fa>
                                </button>
                              </div>
                            </div>
                          </column>
                        </row>
                      </column>
                      <column v-else-if="di.lookup.name == 'QuoteYN'" :sizes="{ md: 12 }">
                        <btn-group outline v-model="consentQ.records.consentForms[0][di.name]" readonly :di="di"
                          :class="['btn-group-toggle']" :name="di.name" class="my-3">
                          <template #btn-prepend>
                            <fa icon="check" class="me-2" />
                          </template>
                        </btn-group>
                      </column>
                      <column v-else-if="participant.country_id == 2 && di.lookup.name == 'AUTNAOAUT'"
                        :sizes="{ md: 12 }">

                        <btn-group justified outline v-model="consentQ.records.consentFormPts[0][di.name]" :di="di"
                          :class="['btn-group-toggle ms-4']" :name="di.name" class="my-3">
                          <template #btn-prepend>
                            <fa icon="check" class="me-2" />
                          </template>
                        </btn-group>
                      </column>
                    </row>
                  </form-group>
                </list-group-item>
              </template>
            </list-group>
          </collapse>
        </card>
      </template>
      <template v-if="currentTab == 'Screening'">
        <scope :stage="stages[0]" v-slot="{ stage }">
          <component :key="stage.questionnaires[0].name" :is="stage.questionnaires[0].name" non-route
            :name="stage.questionnaires[0].name" :participant_id="participant.id" :collapsed="stageIsCollapsed(stage)"
            class="mb-2" :can-save="stage.id == activeStage.id">
            <template #header-right="{}">
              <button type="button" class="btn btn-link" @click="stage.collapsed = !stage.collapsed">
                <fa icon="chevron-right"
                  :class="[{ 'fa-rotate-270': !stage.collapsed, 'fa-rotate-90': stage.collapsed }]"
                  style="transition:all .31s ease;" />
              </button>
            </template>
          </component>

          <alert v-if="stageCompleted(stage)" :type="stageCompleted(stage) ? 'success' : 'warning'" class="my-4">
            <i18n :path="'participant.home.stage_status.' + stage.order + '.summary'">
              <template #stage>{{ $t('participant.home.stage_status.' + stage.order + '.title') }}</template>
              <template #status>{{ stageCompleted(stage) ? $t('participant.home.stage_status.' + stage.order +
                '.completed') : $t('participant.home.stage_status.' + stage.order + '.incomplete') }}</template>

            </i18n>
          </alert>
        </scope>
      </template>
      <template v-if="currentTab == 'Pre-Interview Questions'">
        <scope :stage="stages[1]" v-slot="{ stage }">
          <alert type="success">
            {{ $t('participant.home.stage_status.2.instructions') }}
          </alert>
          <questionnaire-collection :participant-id="participant.id" :collection="stage" :key="stage.name">
            <template #default="d">
              <component :is="d.activeQuestionnaire" non-route :name="d.activeQuestionnaire"
                :participant_id="participant.id">
              </component>
              <!-- <questionnaire-loading v-else /> -->
            </template>
          </questionnaire-collection>
          <template v-if="stageCompleted(stage) && participant.participant_type == 2">
            <!-- message to watch the videos -->
            <alert type="success" class="mt-4">
              <i18n path="participant.home.watch_videos.text">
                <template #link>
                  <a @click="currentTab = 'Videos'" class="alert-link">{{ $t('participant.home.watch_videos.link')
                    }}</a>
                </template>
              </i18n>
            </alert>
          </template>
        </scope>
      </template>
      <template v-if="currentTab == 'Videos'">
        <alert type="success">
          <i18n tag="p" path="participant.home.videos.p1"></i18n>
          <i18n tag="p" path="participant.home.videos.p2"></i18n>
          <i18n tag="p" path="participant.home.videos.p3"></i18n>
        </alert>

        <template v-for="video in participant.videos">
          <card :title="video.title" class="mb-2" :body="false">
            <div class="card-body row">
              <column>
                <h5 class="display-6 mb-4">
                  {{ video.title }}
                </h5>
              </column>
              <column>
                <youtube :video-id="video.video_id" ref="youtube" @playing="videoPlaying(video)"
                  @ended="videoEnded(video)"></youtube>
              </column>
            </div>
            <div class="card-footer" v-if="participantVideoStatus(video)">
              <fa icon="check" />
              <span v-if="participantVideoStatus(video).pivot.playing || participantVideoStatus(video).pivot.ended">
                <span
                  v-if="participantVideoStatus(video).pivot.playing && participantVideoStatus(video).pivot.ended">Played</span>
                <span
                  v-else-if="participantVideoStatus(video).pivot.playing && !participantVideoStatus(video).pivot.ended">Playing</span>
                <span v-else-if="participantVideoStatus(video).pivot.ended">Watched</span>
              </span>
            </div>
          </card>
        </template>

      </template>
    </div>

  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import screening1_a from '~/pages/questionnaires/screening1_a.vue'
import screening1_b from '~/pages/questionnaires/screening1_b.vue'
import vasculitis_related from '~/pages/questionnaires/vasculitis_related.vue'
import QuestionnaireCollection from '~/components/QuestionnaireCollection.vue'
import clinical_info from '~/pages/questionnaires/clinical_info.vue'
import steroid_toxicity from '~/pages/questionnaires/steroid_toxicity.vue';
import phq9 from '~/pages/questionnaires/phq9.vue';
import psychological_support from '~/pages/questionnaires/psychological_support.vue';
import omeract from '~/pages/questionnaires/omeract.vue';
import treatment_population from '~/pages/questionnaires/treatment_population.vue'
import monitoring_tools_tests from '~/pages/questionnaires/monitoring_tools_tests.vue'
import monitoring_signs_findings from '~/pages/questionnaires/monitoring_signs_findings.vue'
import relative_importance from '~/pages/questionnaires/relative_importance.vue'
import willingness_discomfort from '~/pages/questionnaires/willingness_discomfort.vue'
import mental_health_support from '~/pages/questionnaires/mental_health_support.vue'
import treatment_goals from '~/pages/questionnaires/treatment_goals.vue'
import questionnaireLoading from '~/components/QuestionnaireLoading.vue'

export default {
  middleware: 'auth',
  components: {
    questionnaireLoading,
    screening1_a,
    screening1_b,
    vasculitis_related,
    QuestionnaireCollection,
    clinical_info,
    steroid_toxicity,
    phq9,
    psychological_support,
    omeract,
    treatment_population,
    monitoring_tools_tests,
    monitoring_signs_findings,
    relative_importance,
    willingness_discomfort,
    mental_health_support,
    treatment_goals

  },
  mounted() {
    this.getParticipant({
      params: { include: 'user,eligibility,consent,stages' }
    })
      .then(() => {
        this.getQuestionnaires({
          prefix: 'public/',
          params: {
            participant_id: this.$route.params.participant_id,
            'filter[name]': 'eligibility,consent',
            include: 'dataitems',
            append: 'lookups,validation',
            records: true
          }
        }).then(() => {
          this.loadedQuestionnaires = true
          this.setupStages()
        })
      })
  },
  data() {
    return {
      showEligibility: false,
      showConsent: false,
      showScreening: false,
      currentTab: 'Enrolment',
      stages: [],
      loadingQuestionnaires: false,
      loadedQuestionnaires: false,
      focussedQuestionnaire: null
    }
  },
  metaInfo() {
    return { title: this.$t('participant.home.title') }
  },
  computed: {
    ...mapState('resources/questionnaires', ['questionnaireData', 'questionnairesLoaded', 'questionnaireLoaded']),
    ...mapState('resources/participant', ['participant']),
    ...mapState('auth', ['user']),
    ...mapGetters("auth", ["isTeam", "isParticipant"]),
    country() {
      var pis_path = this.participant.country_id == 1 ? '/assets/documents/UK/VAS-UP1a Information sheet - Clinicians V1.pdf' : '/assets/documents/PT/VAS-UP1a - ConsentimentoInformadoClinician_VAS-UP JoanaMartinho.pdf'
      return { pis_path }
    },
    activeStage() {
      if (this.participant && this.participant.stages) {
        return this.participant.stages.find((stage) => stage.order == this.participant.status)
      }
      return null
    },
    activeStageCompleted() {
      if (this.activeStage)
        return this.activeStage.questionnaires.every((q) => {
          if (this.activeStage.records[q.name])
            return this.activeStage.records[q.name].valid == '1'
        })
      return false
    },
    navTabs() {
      if (this.participant && this.participant.stages) {
        var stages = this.participant.stages.map((stage, n) => {
          return { name: stage.name, key: stage.name }
        })
        var videoPage = this.stageCompleted(this.stages[1]) && this.participant.participant_type == 2 ? [{ name: 'Videos', key: 'Videos' }] : []

        return [
          { name: this.$t('admin.participants.overview.enrolment'), key: 'enrolment' },
          ...stages,
          ...videoPage
        ]
      }
      return []
    },
    eligibilityQ() {
      if (this.questionnaireData.eligibility) {
        return this.questionnaireData.eligibility
      }
      return null

    },
    eligibilityDataitems() {
      if (this.eligibilityQ && this.loadedQuestionnaires) {
        var group = this.participant.participant_type == 1 ? 'clinician' : 'patient'
        var allDis = this.questionnaireData.eligibility.dataitems
        var dis = {}
        for (var key in allDis) {
          if (allDis[key].json && allDis[key].json.group == group) {
            dis[key] = allDis[key]
          }
        }
        return dis
      }
      return null
    },
    consentQ() {
      if (this.loadedQuestionnaires && this.questionnaireData)
        return this.questionnaireData.consent
      return null
    },
    consentSectionDataitem() {

      return this.participant.country_id == 1 ? this.consentQ.dataitems.consentForms : this.consentQ.dataitems.consentFormPts
    },
    consentDataitems() {
      if (this.consentQ) {
        return this.consentSectionDataitem.children
      }
      return null
    }
  },
  methods: {
    ...mapActions('resources/questionnaires', ['getQuestionnaires']),
    ...mapActions('resources/participant', ['getParticipant', 'recordVideoStats']),
    setupStages() {
      if (this.participant.stages) {
        this.participant.stages.forEach((stage, n) => {
          this.$set(this.stages, n, { ...stage, collapsed: this.activeStage != stage || this.activeStageCompleted })
        })
        if (this.activeStage) {
          this.currentTab = this.activeStage.name
          if (this.stages[1] && this.stageCompleted(this.activeStage) && this.activeStage.id == this.stages[1].id && this.participant.participant_type == 2) {
            this.currentTab = 'Videos'
          }
        }
      }
    },
    changeTab(name) {
      this.currentTab = name
    },
    stageIsCollapsed(stage) {
      return stage.collapsed
    },
    stageCompleted(stage) {
      if (stage) {
        return stage.questionnaires.every((q) => {
          if (stage.records[q.name])
            return stage.records[q.name].valid == '1'
        })
      }
      return false
    },
    questionnaireCardClass(questionnaire) {
      var progressState = (questionnaire.patient_questionnaire) ? 'questionnaire-card-' + questionnaire.patient_questionnaire.progressState : ''
      return ['questionnaire-card', progressState]
    },
    videoPlaying(video) {
      this.recordVideoStats({ participant_id: this.participant.id, params: { video_id: video.id, playing: true } })
    },
    videoEnded(video) {
      this.recordVideoStats({ participant_id: this.participant.id, params: { video_id: video.id, ended: true } })

    },
    participantVideoStatus(video) {
      if (this.participant.videoStats) {
        return this.participant.videoStats.find((v) => v.id == video.id)
      }
      else return {}
    }
  },
  watch: {


    'participant.stages'(newVal) {
      if (newVal)
        this.setupStages()
    },
    questionnaireData(newVal) {
      // console.log('questionnaireData changed', newVal)
    }
  }
}

</script>
