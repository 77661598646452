<template>
  <questionnaire v-if="questionnaire" :title="$t(lp + '.title')" :can-save="canSave">
    <form-group :di="dataitems.ExperiencedSideEffects" :error="errors.ExperiencedSideEffects" cols="9|3" class="mb-3"
      v-slot="{ di }">
      <btn-group outline justified :di="di" v-model="records.ExperiencedSideEffects"></btn-group>
    </form-group>
    <form-group :di="dataitems.RecogniseSideEffects" :error="errors.RecogniseSideEffects" cols="9|3" class="mb-3"
      v-slot="{ di }">
      <btn-group outline justified :di="di" v-model="records.RecogniseSideEffects"></btn-group>
    </form-group>
    <form-group :di="dataitems.InformedToxicity" :error="errors.InformedToxicity" cols="9|3" class="mb-3"
      v-slot="{ di }">
      <btn-group outline justified :di="di" v-model="records.InformedToxicity"></btn-group>
    </form-group>
    <div class="form-label">
      {{ $t(lp + '.GCRelatedComorb') }}
    </div>
    <list-group>
      <template v-for="di in GCRelatedComorb">
        <list-group-item>
          <checkbox v-model="records[di.name]" :true-value="di.value" @change="updateGCRelatedComorbs(di.name)">
            {{ di.label }}
          </checkbox>
        </list-group-item>
      </template>
      <list-group-item class="border-top">
        <checkbox v-model="records.GCRelatedComorbNone" :true-value="dataitems.GCRelatedComorbNone.value"
          @change="updateGCRelatedComorbs('GCRelatedComorbNone')">
          {{ dataitems.GCRelatedComorbNone.label }}
        </checkbox>
      </list-group-item>
    </list-group>
  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
import { mapGetters } from 'vuex'
export default {
  name: 'steroid_toxicity',
  mixins: [questionnaireMixin],
  computed: {
    GCRelatedComorb() {
      if (this.dataitems) {
        var GCRelatedComorb = {}
        Object.keys(this.dataitems).forEach((diKey) => {
          var di = this.dataitems[diKey];
          if (diKey != 'GCRelatedComorbNone' && di.json && di.json.group && di.json.group == 'GCRelatedComorb') GCRelatedComorb[di.name] = di
        });
        return GCRelatedComorb;
      }
      return []
    },
  },
  methods: {
    updateGCRelatedComorbs(diName) {
      if (diName == 'GCRelatedComorbNone' && this.records.GCRelatedComorbNone) {
        for (var diKey in this.GCRelatedComorb) {
          this.records[diKey] = null
        }
      } else if (this.records[diName]) {
        this.records.GCRelatedComorbNone = null
      }
    }
  }

}

</script>
