import { mapActions, mapGetters, mapState } from 'vuex'
import store from '~/store'
import dateMixin from '~/mixins/dates'
import langMixin from '~/mixins/lang'
import questionnaireGettersMixin from '~/mixins/questionnaire_getters'
import validationMixin from '~/mixins/validation'
// import visitMixin from '~/mixins/visit'
const questionnaireQueryParams = { append: 'lookups,validation', include: 'dataitems', records: true }
const participantQueryParams = { append: 'code,issues', include: 'user' }
export default {
  mixins: [dateMixin, langMixin, questionnaireGettersMixin, validationMixin],
  provide() {
    return {
      parent_name: this.name,
      parent_participant_id: this.participant_id,
      parent_issue: this.issue,
    }
  },
  props: {
    name: String,
    participant_id: [String, Number],
    issue: String,
    nonRoute: Boolean,
    collapsed: Boolean,
    canSave: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    }
  },
  beforeRouteEnter(to, from, next) {
    var name = to.meta && to.meta.name ? to.meta.name : to.name
    var req = {
      name,
      params: { ...to.params, ...questionnaireQueryParams }
    }
    var participantLoaded = store.state['resources/participant'].participant
    if (to.params.participant_id && (Object.keys(participantLoaded).length === 0 || participantLoaded.id != to.params.participant_id)) {
      var params = { participant_id: to.params.participant_id, ...participantQueryParams }
      store.dispatch('resources/participant/getParticipantOverview', { params })
    }
    if (to.meta.prefix) req.prefix = to.meta.prefix
    if (to.meta.isPublic) req.prefix = 'public/'
    console.log('beforeRouteEnter Questionnaire mixin', req, this)
    store.dispatch('resources/questionnaires/getQuestionnaireData', req).then((res) => {
      next()
    })
    // store.state['resources/questionnaires'].routeChanging = false
  },
  beforeRouteLeave(to, from, next) {
    // console.log('beforeRouteLeave Questionnaire mixin', from.name, to.name)
    next(vm => vm.update({ routeChanging: true, questionnaireLoaded: false }))
  },
  created() {
    if (this.nonRoute) {
      // this.update({ questionnaireLoaded: false })
      this.getQuestionnaire()
    }
  },
  mounted() {
    if (this.nonRoute) {
      // this.update({ questionnaireLoaded: false })
    }
  },
  computed: {
    ...mapGetters('auth', ['isParticipant']),
    isSubmitted() {
      return false;
    }
  },
  methods: {

    ...mapActions('resources/questionnaires', ['getQuestionnaireData', 'update']),
    getQuestionnaire() {
      var params = { participant_id: this.participant_id, issue: this.issue, ...questionnaireQueryParams }
      var req = { name: this.name, params }

      if (this.$route.meta.isPublic) req.prefix = 'public/'
      // console.log('getQuestionnaire', req)
      this.getQuestionnaireData(req)

    },
    clearRecords(records, fields) {
      if (typeof fields == 'object') fields = Object.keys(fields)
      for (var i = fields.length - 1; i >= 0; i--) {
        records[fields[i]] = null
      }
    }
  },
  watch: {
    questionnaireLoaded() {

    }
  }
}
