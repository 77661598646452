<template>
  <questionnaire v-if="questionnaireIsLoaded" :title="$t(lp + '.title')" :collapsed="collapsed" :body="false"
    :can-save="canSave">
    <template #header-right>
      <slot name="header-right"></slot>
    </template>
    <div class="card-body">
      <alert type="info" v-text="$t(lp + '.instructions')" />
      <form-group :di="dataitems.Specialty" :error="errors.Specialty" v-slot="{ di }" cols="4|8" class="mb-4">
        <btn-group v-model="records[di.name]" vertical justified outline :di="di"></btn-group>
      </form-group>
      <form-group v-if="records.Specialty == 9" :di="dataitems.SpecialtyTxt" :error="errors.SpecialtyTxt"
        v-slot="{ di }" cols="4|8" class="mb-4">
        <textbox :di="dataitems.SpecialtyTxt" v-model="records.SpecialtyTxt" />
      </form-group>
      <div class="form-label mb-4">{{ $t(lp + '.ClinExp') }}</div>
      <form-group :di="dataitems.ClinExpRes" :error="errors.ClinExpRes" v-slot="{ di }" cols="4|8" class="mb-4">
        <btn-group v-model="records[di.name]" justified outline :di="di"></btn-group>
      </form-group>
      <form-group :di="dataitems.ClinExpSpec" :error="errors.ClinExpSpec" v-slot="{ di }" cols="4|8" class="mb-4">
        <btn-group outline justified :di="dataitems.ClinExpSpec" v-model="records.ClinExpSpec" />
      </form-group>
      <form-group :di="dataitems.VascExp" :error="errors.VascExp" v-slot="{ di }" cols="8|4" class="mb-4">
        <number :di="dataitems.VascExp" v-model="records.VascExp" />
      </form-group>
    </div>


  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
import { mapGetters } from 'vuex'
export default {
  name: 'screening1_a',

  mixins: [questionnaireMixin],

  computed: {
    ...mapGetters('auth', ['isParticipant']),
  },
  watch: {
    dataitems: {
      handler(val) {
        console.log('screening1_a.vue dataitems', val)
      }
    }
  }
}

</script>
