import Vue from "vue";
import { library, config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from "@fortawesome/vue-fontawesome";
import iconJson from "./icons.json";

import("../../../node_modules/@fortawesome/free-solid-svg-icons").then(
  (solidIconImport) => {
    importIcons(iconJson.solid, solidIconImport);
  }
);
const brandsIconImport = import(
  "../../../node_modules/@fortawesome/free-brands-svg-icons"
).then((brandsIconImport) => {
  importIcons(iconJson.brands, brandsIconImport);
});
const icons = {};

export function importIcons(iconArr, iconImport) {
  for (const fileName in iconImport) {
    var mod = iconImport[fileName];
    if (iconArr.includes(fileName)) icons[fileName] = mod;
  }
  library.add(icons);
}
config.autoAddCss = false;


Vue.component("fa", FontAwesomeIcon);
Vue.component('fa-layers', FontAwesomeLayers)
Vue.component('fa-layers-text', FontAwesomeLayersText)