import store from '~/store'
import Cookies from 'js-cookie'

export default async (to, from, next) => {
  if (to.query.token) { // signed route t login code
    store.dispatch('auth/saveToken', {
      token: to.query.token,
      remember: false
    })
    var params = { ...to.query }
    delete params.token;
    next({ name: to.name, params })
  } else {
    if (!store.getters['auth/check']) {
      Cookies.set('intended_url', to.path)
      if (to.query.id && to.query.token) {
        next({ name: 'token_login', params: { ...to.query } })
      } else {
        next({ name: 'login' })
      }
    } else {
      next()
    }
  }

}
