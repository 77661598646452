<template>
  <questionnaire v-if="questionnaireLoaded" :title="$t(lp + '.title')">
    <form-group :di="dataitems.DoB" :error="errors.DoB" cols="4|8" class="mb-3">
      <datepicker v-model="records.DoB"></datepicker>
    </form-group>
    <form-group :di="dataitems.Gender" :error="errors.Gender" cols="4|8" class="mb-3" v-slot="{ di }">
      <btn-group outline justified :di="di" v-model="records.Gender"></btn-group>
    </form-group>
    <form-group :di="dataitems.EducationLevel" :error="errors.EducationLevel" cols="4|8" class="mb-3" v-slot="{ di }">
      <btn-group outline justified vertical :di="di" v-model="records.EducationLevel"></btn-group>
    </form-group>
    <form-group :di="dataitems.FamilyStatus" :error="errors.FamilyStatus" cols="4|8" class="mb-3" v-slot="{ di }">
      <btn-group outline justified vertical :di="di" v-model="records.FamilyStatus"></btn-group>
    </form-group>
    <form-group :di="dataitems.PrimarySpecialty" :error="errors.PrimarySpecialty" cols="4|8" class="mb-3"
      v-slot="{ di }">
      <btn-group outline justified vertical :di="di" v-model="records.PrimarySpecialty"></btn-group>

      <form-group v-if="records.PrimarySpecialty == 3" :di="dataitems.PrimarySpecialtyOther"
        :error="errors.PrimarySpecialtyOther" cols="4|8" class="my-3" v-slot="{ di }">
        <textbox v-model="records.PrimarySpecialtyOther"></textbox>
      </form-group>

    </form-group>
    <label v-t="lp + '.number_of_years'" class="mb-3"></label>
    <row>
      <column>
        <form-group :di="dataitems.YearsClinicalResident" :error="errors.YearsClinicalResident" cols="6|6" class="my-3"
          v-slot="{ di }">
          <number v-model="records.YearsClinicalResident"></number>
        </form-group>

      </column>
      <column>

        <form-group :di="dataitems.YearsClinicalSpecialist" :error="errors.YearsClinicalSpecialist" cols="6|6"
          class="my-3" v-slot="{ di }">
          <number v-model="records.YearsClinicalSpecialist"></number>
        </form-group>
      </column>
    </row>

  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
export default {
  name: 'demographics',
  mixins: [questionnaireMixin],

}

</script>
