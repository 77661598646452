<template>
  <questionnaire v-if="questionnaireIsLoaded" :title="$t('questionnaires.treatment_population.title')"
    :collapsed="collapsed" :can-save="isParticipant">
    {{ $t('questionnaires.treatment_population.PPM') }}
    <template v-for="di in dataitems">
      <form-group :key="di.name" :di="di" :error="errors[di.name]" class="my-3" v-slot="{ di }"
        :cols="[{ sm: 6, md: 12 }, { sm: 6, md: 12 }]">
        <btn-group :di="di" v-model="records[di.name]" outline justified></btn-group>
      </form-group>
    </template>

  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
import { mapGetters } from 'vuex'

export default {
  name: 'treatment_population',
  mixins: [questionnaireMixin],

  computed: {
    ...mapGetters('auth', ['isParticipant']),

  },
  mounted() {

  },
  data() {

  },
  methods: {
    groupedDataitems(groupname) {
      if (this.dataitems) {
        var result = {}
        var keys = Object.keys(this.dataitems).forEach((k) => {
          var di = this.dataitems[k]
          if (di.json && di.json.group && di.json.group == groupname) result[di.name] = di
        })
        return result
      }
    },
  }
}

</script>
