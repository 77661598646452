<template>
  <div id="app">
    <loading ref="loading" />
    <transition name="page" mode="out-in">
      <component :is="layout" v-if="layout" />
    </transition>
  </div>
</template>

<script>
import Loading from './Loading.vue'
import DarkModeMixin from '@showjoe/ffui/src/mixins/dark-mode.vue'
import { resolveImport } from '../helpers/module-importer.js'
import { mapGetters } from 'vuex'
import { makeNavigation } from '~/router/navigation.js'

// Load layout components dynamically.
const layoutsImport = import.meta.glob('../layouts/**/*.vue')
const layouts = resolveImport(layoutsImport, '../layouts/', /(^.\/)|(\.vue$)/g)
export default {
  el: '#app',

  mixins: [DarkModeMixin],
  components: {
    Loading
  },
  mixins: [DarkModeMixin],
  data: () => ({
    layout: null,
    defaultLayout: 'default',
    offCanvasMenuShow: false,
    devMode: false,
    hmr: import.meta.hot
  }),

  metaInfo() {
    const { appName } = window.config
    return {
      title: appName,
      titleTemplate: `%s · ${appName}`,
    }
  },

  mounted() {
    this.$loading = this.$refs.loading
    this.devMode = window.config.env == 'local'
  },
  computed: {
    ...mapGetters('auth', ['user', 'isAdmin', 'isTeam', 'isParticipant', 'isResearcher']),
    config: () => window.config,
    navigation() {
      return makeNavigation(this.$root)
    },
    homepage() {
      if (!this.user) return 'welcome'
      if (this.isAdmin || this.isTeam || this.isResearcher) return 'admin.participants'
      if (this.isParticipant) return 'home'
      return 'home'
    }
  },
  methods: {
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
    setLayout(layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout
      }

      this.layout = layouts[layout]
    },
    async logout() {
      await this.$store.dispatch('auth/logout')
      // Redirect to login.
      this.$router.push({ name: 'login' })
    },
  }
}
</script>
