<template>
  <questionnaire v-if="questionnaireIsLoaded" :title="$t('questionnaires.monitoring_tools_tests.title')"
    :collapsed="collapsed" :can-save="isParticipant">

    <h5 v-html="$t('questionnaires.monitoring_tools_tests.HighGluco')"></h5>
    <h5 v-html="$t('questionnaires.monitoring_tools_tests.SteroidToxicityMethods')" class="my-4"></h5>
    <form-group class="mb-5  ms-5">
      <list-group outline vertical justified>
        <template v-for="di in groupedDataitems('STTools')">
          <list-group-item is-link v-model="records[di.name]" :key="di.name">
            {{ di.label }}
          </list-group-item>
          <form-group v-if="di.name == 'STT_Other' && records[di.name]" :di="dataitems[di.name + 'Specify']"
            :key="di.name" :error="errors[di.name + 'Specify']" cols="3|9" class="my-3">
            <textbox v-model="records[di.name + 'Specify']"></textbox>
          </form-group>
        </template>
      </list-group>
    </form-group>
    <!-- <ul class="mb-5 ms-5">
      <li v-t="'questionnaires.monitoring_tools_tests.STMethods_1'"></li>
      <li v-t="'questionnaires.monitoring_tools_tests.STMethods_2'"></li>
      <li v-t="'questionnaires.monitoring_tools_tests.STMethods_3'"></li>
    </ul> -->
    <h5 v-html="$t('questionnaires.monitoring_tools_tests.STLabTests')" class="mt-5 mb-3"></h5>
    <template v-for="di in groupedDataitems('STLT')">
      <form-group :di="di" :error="errors[di.name]" class="mb-4  ms-5" :key="di.name">
        <btn-group outline justified :di="di" v-model="records[di.name]"></btn-group>
      </form-group>
    </template>
    <h5 v-html="$t('questionnaires.monitoring_tools_tests.STVitalSigns')" class="mt-5 mb-3"></h5>
    <template v-for="di in groupedDataitems('STVS')">
      <form-group :di="di" :error="errors[di.name]" class="mb-4  ms-5" :key="di.name">
        <btn-group outline justified :di="di" v-model="records[di.name]"></btn-group>
      </form-group>
    </template>

  </questionnaire>
</template>
<script>
import questionnaireMixin from '~/mixins/questionnaire'
import { mapGetters } from 'vuex'

export default {
  name: 'monitoring_tools_tests',
  mixins: [questionnaireMixin],

  computed: {
    ...mapGetters('auth', ['isParticipant']),

  },
  mounted() {

  },
  data() {

  },
  methods: {
    groupedDataitems(groupname) {
      if (this.dataitems) {
        var result = {}
        var keys = Object.keys(this.dataitems).forEach((k) => {
          var di = this.dataitems[k]
          if (di.json && di.json.group && di.json.group == groupname) result[di.name] = di
        })
        return result
      }
    },
  }
}

</script>
