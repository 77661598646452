<template>
  <div>
    <card head foot :title="$t('admin.countries.title')" class="mb-4" :body="false">
      <data-table :data="countries" :columns="countryColumns" hide-index class="countries-datatable">
        <template #header-column="{ col }">
          <div v-if="col.sortable" @click="setSorting(col.name)"
            :class="['dt-column dt-column-header dt-column-' + col.name, sortingClass(col)]">
            <template v-if="col.type == Date">
              <div class="p-2" v-text="$d(new Date(rowData[col.name]), 'numeric', 'en-GB')" />
            </template>
            <div v-else class="p-2" v-text="col.label" />
          </div>
          <div v-else :class="['dt-column dt-column-header dt-column-' + col.name + '']">
            <div class="p-2" v-text="col.label" />
          </div>
        </template>
        <template #cell-name="{ rowData }">

          <div class="d-flex p-1">
            <img :src="'https://flagcdn.com/' + rowData.code.toLowerCase() + '.svg'" class="flag-img rounded"
              :alt="rowData.code.toLowerCase()" style="height:2rem">
            <div class="flex-grow-1 ms-3 p-1 " v-text="getCountryLang(rowData).name"></div>
          </div>
        </template>
        <template #cell-study_start_date="{ rowData }">
          <div class="p-2" v-text="$d(new Date(rowData.study_start_date), 'numeric', 'en-GB')" />
        </template>
        <template #cell-recruited="{ rowData }">
          <div class="p-2" v-text="rowData.recruited" />
        </template>
        <template #cell-team="{ rowData }">
          <div class="p-1">
            <template v-for="team_member in rowData.team">
              <img :src="team_member.photo_url" :alt="team_member.first_name + ' ' + team_member.last_name"
                :key="team_member.photo_url" class="rounded-circle profile-photo me-1">
            </template>
          </div>
        </template>
        <template #cell-view="{ rowData }">
          <div class="p-1">
            <btn block size="sm" is-router-link :to="{ name: 'admin.country', params: { country_id: rowData.id } }">
              <fa icon="play" />
            </btn>
          </div>
        </template>
      </data-table>
      <template #footer-inner>
        <div class="d-flex">
          <ul class="pagination m-auto">
            <li v-for="link in pagination.links" :class="['page-item', { active: link.active, disabled: !link.url }]">
              <a @click="updatePagination(link, pagination)" class="page-link" v-html="link.label" />
            </li>
          </ul>
          <dropdown v-model="pagination.per_page" :items="paginationPerPageList" class="m-auto">
            <template #btn-inner="{ chosenLabel }">
              <span v-t="{ path: 'settings.per_page', args: { count: chosenLabel } }"></span>
            </template>
          </dropdown>
        </div>
      </template>
    </card>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import paginationMixin from '~/mixins/pagination'
import countryMixin from '~/mixins/country';

export default {
  name: "countries",
  metaInfo() {
    return { title: this.$t('admin.countries.title') }
  },
  middleware: ['auth', 'role:Admin'],
  mixins: [paginationMixin, countryMixin],
  apiMethod: 'getCountries',
  data() {
    return {
      params: {
        sort: 'id',
        include: 'team'
      }
    }
  },
  mounted() {
    var params = this.params
    this.getCountries({ params });
  },
  methods: {
    ...mapActions("resources/countries", ["getCountries"]),
  },
  computed: {
    ...mapState("resources/countries", ["countries", "pagination"]),
    countryColumns() {
      return [
        { name: 'id', label: 'ID', Type: Number, sortable: true, width: 'max-content' },
        { name: 'name', label: this.$t('admin.countries.columns.name'), Type: String, sortable: true, width: 'minmax(140px, 3fr)' },
        { name: 'code', label: this.$t('admin.countries.columns.code'), Type: String, sortable: true, width: 'max-content' },
        { name: 'study_start_date', label: this.$t('admin.countries.columns.study_start_date'), Type: Date, sortable: true, width: 'max-content' },
        { name: 'recruited', label: this.$t('admin.countries.columns.recruited'), Type: Number, width: 'max-content' },
        { name: 'recruitment_target_count', label: this.$t('admin.countries.columns.recruitment_target_count'), Type: Number, width: 'max-content' },
        { name: 'team', label: this.$t('admin.countries.columns.team'), Type: String, width: 'max-content' },
        { name: 'view', label: this.$t('admin.countries.columns.view'), Type: null, width: '60px' },
      ]
    }
  }
}

</script>
